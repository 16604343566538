import React from 'react';
import TrendyPickIMg01 from "../../images/TrendingImg01.png"
import TrendyPickIMg02 from "../../images/Group 3910.png";
import TrendyPickIMg03 from "../../images/Group 3911.png";
import TrendyPickIMg04 from "../../images/Group 3912.png";
import TrendyPickIMg05 from "../../images/Group 3913.png";
import TrendyPickIMg06 from "../../images/Group 3916.png";
import { Link, useLocation, useNavigate } from "react-router-dom";

import TrendyPicksImgRight from "../../images/Lidkar_Daali_Dec279055_1.png"
const TrendyPicks = () => {
    const navigate = useNavigate();
  const handleProducts = (id) => {
  
    
    navigate('/shop/products', { state: {id: id } });
    window.scrollTo(0, 0);
  };

  const handleAllProducts = () => {
    navigate(`/shop/products`);
    window.scrollTo(0, 0);
  };
  const handleClutchesAndWallets=()=>{
 navigate('/shop/products',{state:{clutches:'clutch'}})
  }
   const handleSubCategorieProducts = (id) => {
  
    navigate('/shop/products', { state: { subCategoryId: id } });
    window.scrollTo(0, 0);
  };

    const handleSpecialPrice=()=>{
      const id=1
      navigate('/shop/products', { state: {specialPriceId: 1 } });
      window.scrollTo(0, 0);
    }
  
  //   const handleSubCategory = (id)=>{
  //   navigate(`/shop/products?subCategoryId=${id}`);

  // }
  // const handleSubSubCategory = (id)=>{
  //   navigate(`/shop/products?subSubCategoryId=${id}`);

  // }
  return (
    <div className='max-w-[1920px]  mx-auto sm:px-[5rem] py-2 mt-0 bg-[#FFF5EC]'>
      <div className="flex flex-col-reverse lg:grid lg:grid-cols-[70%_30%] pt-0 pb-[18px">
        <div className="flex items-center flex-wrap gap-5 justify-center sm:justify-between lg:justify-start">

          <div className="w-[20.1rem] sm:w-[14rem] lg:w-[15.9rem] h-[22rem] sm:h-[18rem] mt-9 relative overflow-hidden flex justify-center items-center">
            <img
              src={TrendyPickIMg02}
              className="w-full h-full transition-transform custum-transition-duration ease-out transform hover:scale-110"
            />

            <p className="absolute text-[22px] top-[55%] left-[3%] text-white tracking-wider whitespace-nowrap text-wrap font-orator">
              CLUTCHES & WALLETS
            </p>
            <button  onClick={()=>handleClutchesAndWallets()} className="w-[150px] absolute border-white border-2 h-[33px] uppercase top-[85%] font-helvetica left-[3%] text-white  overflow-hidden group transition-colors duration-300 ease-out">
              <span className="relative z-10 group-hover:text-black font-helvetica  text-sm transition-colors duration-600">
                View Products
              </span>
              <span className="absolute inset-0 bg-white transform scale-x-0   group-hover:scale-x-100 group-hover:bg-white transition-all duration-1000 ease-out origin-left"></span>
            </button>

          </div>
          <div className='w-[20.1rem] sm:w-[14rem] lg:w-[15.9rem] h-[22rem] sm:h-[18rem] mt-9 relative overflow-hidden'>
            <img src={TrendyPickIMg03} className="w-full h-full transition-transform custum-transition-duration ease-out transform hover:scale-110" />
            <p className='absolute text-[22px] top-[55%] left-[3%] text-white tracking-widest whitespace-nowrap font-orator text-wrap'>UNIQUE COLLECTIONS</p>
            <button onClick={()=>handleProducts(4)} className="w-[150px] absolute border-white border-2 h-[33px] uppercase top-[85%] font-helvetica left-[3%] text-white  overflow-hidden group transition-colors duration-300 ease-out">
              <span className="relative z-10 group-hover:text-black font-helvetica  text-sm transition-colors duration-600">
                View Products
              </span>
              <span className="absolute inset-0 bg-white transform scale-x-0   group-hover:scale-x-100 group-hover:bg-white transition-all duration-1000 ease-out origin-left"></span>
            </button>      </div>
          <div className='w-[20.1rem] sm:w-[14rem] lg:w-[15.9rem] h-[22rem] sm:h-[18rem] mt-9 relative overflow-hidden'>
            <img src={TrendyPickIMg04} className="w-full h-full transition-transform custum-transition-duration ease-out transform hover:scale-110" />
            <p className='absolute text-[22px] top-[55%] left-[3%] text-white tracking-widest whitespace-nowrap font-orator text-wrap w-[200px]'>LAPTOP & OFFICE BAGS</p>
            <button onClick={()=>handleSubCategorieProducts(7)} className="w-[150px] absolute border-white border-2 h-[33px] uppercase top-[85%] font-helvetica left-[3%] text-white  overflow-hidden group transition-colors duration-300 ease-out">
              <span className="relative z-10 group-hover:text-black font-helvetica  text-sm transition-colors duration-600">
                View Products
              </span>
              <span className="absolute inset-0 bg-white transform scale-x-0   group-hover:scale-x-100 group-hover:bg-white transition-all duration-1000 ease-out origin-left"></span>
            </button>      </div>
          <div className='w-[20.1rem] sm:w-[14rem] lg:w-[15.9rem] h-[22rem] sm:h-[18rem] mt-9 relative overflow-hidden'>
            <img src={TrendyPickIMg05} className="w-full h-full transition-transform custum-transition-duration ease-out transform hover:scale-110" />
            <p className='absolute text-[22px] top-[55%] left-[3%] text-white tracking-widest whitespace-nowrap font-orator text-wrap w-[220px]'>HOME DECOR ITEMS</p>
            <button disabled onClick={()=>handleSubCategorieProducts(14)} className="w-[150px] absolute border-white border-2 h-[33px] uppercase top-[85%] font-helvetica left-[3%] text-white  overflow-hidden group transition-colors duration-300 ease-out">
              <span className="relative z-10 group-hover:text-black font-helvetica  text-sm transition-colors duration-600">
                View Products
              </span>
              <span className="absolute inset-0 bg-white transform scale-x-0   group-hover:scale-x-100 group-hover:bg-white transition-all duration-1000 ease-out origin-left"></span>
            </button>     </div>
          <div className='w-[20.1rem] sm:w-[14rem] lg:w-[15.9rem] h-[22rem] sm:h-[18rem] mt-9 relative overflow-hidden'>
            <img src={TrendyPickIMg06} className="w-full h-full transition-transform custum-transition-duration ease-out transform hover:scale-110" />
            <p className='absolute text-[22px] top-[55%] text-wrap left-[3%] text-white tracking-widest whitespace-nowrap font-orator w-[120px]'>SPECIAL PRICES</p>
            <button onClick={()=>handleSpecialPrice()} className="w-[150px] absolute border-white border-2 h-[33px] uppercase top-[85%] font-helvetica left-[3%] text-white  overflow-hidden group transition-colors duration-300 ease-out">
              <span className="relative z-10 group-hover:text-black font-helvetica  text-sm transition-colors duration-600">
                View Products
              </span>
              <span className="absolute inset-0 bg-white transform scale-x-0   group-hover:scale-x-100 group-hover:bg-white transition-all duration-1000 ease-out origin-left"></span>
            </button>      </div>
          <div className='w-[20.1rem] sm:w-[14rem] lg:w-[15.9rem] h-[22rem] sm:h-[18rem] mt-9 relative overflow-hidden'>
            <img src={TrendyPickIMg01} className="w-full h-full transition-transform custum-transition-duration ease-out transform hover:scale-110" />
            <p className='absolute text-[22px] top-[55%] text-wrap left-[3%] text-white tracking-widest whitespace-nowrap font-orator w-[220px]'>TRAVEL BAG & ACCESSORIES</p>
            <button onClick={()=>handleSubCategorieProducts(8)} className="w-[150px] absolute border-white border-2 h-[33px] uppercase top-[85%] font-helvetica left-[3%] text-white  overflow-hidden group transition-colors duration-300 ease-out">
              <span className="relative z-10 group-hover:text-black font-helvetica  text-sm transition-colors duration-600">
                View Products
              </span>
              <span className="absolute inset-0 bg-white transform scale-x-0   group-hover:scale-x-100 group-hover:bg-white transition-all duration-1000 ease-out origin-left"></span>
            </button></div>
        </div>
        <div className='mt-5 p-3'>
        <h1 className="font-orator text-[#6C3516] items-start text-4xl  leading-60 flex justify-start">
    <p className="font-orator text-[40px] mb-1 ">T</p>
    <p className="font-orator space-x-0 tracking-tighter text-[33px] mr-2">RENDY PICKS</p>
  </h1>
          <p className='w-[131px] h-[33px] text-[18px] font-helvetica border-2 flex justify-center items-center' onClick={handleAllProducts}>Explore All</p>
          <div className="w-[95%] h-[91%] ml-[21px] sm:ml-[30px] ">
            <img src={TrendyPicksImgRight} className='w-full h-[90%] object-fit' />
          </div>
        </div>
      </div>
    </div>


  );
}

export default TrendyPicks;

import React from 'react';

const TermsAndConditionPage = () => {
  return (
    <div className='max-w-[1920px]  mx-auto px-[1rem] sm:px-[5rem] mt-4'>
    
     
  
      <section className="mb-8">
        <h2 className="text-2xl font-helvetica mb-2">Conditions of Sale</h2>
       
        <p className="mb-2  text-[14px]  text-justify"> Please read these conditions carefully before using the LIDKAR website. By using the Lidkar.com website, you signify your agreement to be bound by these conditions.
In addition, when you use any current or future Lidkar.com service (eg. Wish List) (“Lidkar Service”), you will also be subject to the terms, guidelines and conditions applicable to that LIDKAR. (“Terms”). If these Conditions of Use are inconsistent with such Terms, the Terms will control.
These “Conditions of Use” constitute an electronic record within the meaning of the applicable law. This electronic record is generated by a computer system and does not require any physical or digital signatures.
Conditions Relating to Your Use of Lidkar.com
Notice and Procedure for Making Claims of Infringement
Notice and Procedure for Notifying Lidkar.com of Objectionable Content</p>
       </section>
  
      <section className="mb-8">
        <h2 className="text-2xl font-helvetica mb-2">Conditions Relating to Your Use of Lidkar.com</h2>
   
        <ul className="list-disc pl-5 text-justify">
          <li className="mb-2  text-[14px] "><p className='font-helvetica  text-[18px]'>Your Account:</p>If you use the website, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer to prevent unauthorized access to your account. You agree to accept responsibility for all activities that occur under your account or password. You should take all necessary steps to ensure that the password is kept confidential and secure and should inform us immediately if you have any reason to believe that your password has become known to anyone else, or if the password is being, or is likely to be, used in an unauthorized manner. Please ensure that the details you provide us with are correct and complete and inform us immediately of any changes to the information that you provided when registering. You can access and update much of the information you provided us with in the My Account area of the website. You agree and acknowledge that you will use your account on the website to purchase products only for your personal use and not for business purposes. Should you wish to order products for business purposes, please create a business account on Lidkar.com?. Lidkar.com reserves the right to refuse access to the website, terminate accounts, remove or edit content at any time without notice to you. </li>
          <li className="mb-2  text-[14px] "><p className='font-helvetica  text-[18px]' >Privacy:</p>Please review our Privacy Notice, which also governs your visit to Lidkar.com, to understand our practices. The personal information / data provided to us by you during the course of usage of Lidkar.com will be treated as strictly confidential and in accordance with the Privacy Notice and applicable laws and regulations. If you object to your information being transferred or used, please do not use the website.</li>
          <li className="mb-2  text-[14px] "><p className='font-helvetica  text-[18px]'> E-Platform for Communication:</p> You agree, understand and acknowledge that the website is an online platform that enables you to purchase products listed on the website at the price indicated therein at any time from any location. You further agree and acknowledge that Lidkar is only a facilitator and is not and cannot be a party to or control in any manner any transactions on the website. Accordingly, the contract of sale of products on the website shall be a strictly bipartite contract between you and the sellers on Lidkar.com</li>
          <li className="mb-2  text-[14px] "><p className='font-helvetica  text-[18px]'>Access to Lidkar.com</p> We will do our utmost to ensure that availability of the website will be uninterrupted and that transmissions will be error-free. However, due to the nature of the Internet, this cannot be guaranteed. Also, your access to the website may also be occasionally suspended or restricted to allow for repairs, maintenance, or the introduction of new facilities or services at any time without prior notice. We will attempt to limit the frequency and duration of any such suspension or restriction.</li>
          <li className="mb-2  text-[14px] "><p className='font-helvetica  text-[18px]'>License for website access:</p>
Subject to your compliance with these Conditions of Use and payment of applicable fees, if any, Lidkar grants you a limited license to access and make personal use of this website, but not to download (other than page caching) or modify it, its affiliates, as may be applicable. This license does not include any resale or commercial use of this website or its contents; any collection and use of any product listings, descriptions, or prices; any derivative use of this website or its contents; any downloading or copying of account information for the benefit of another seller; or any use of data mining, robots, or similar data gathering and extraction tools.
This website or any portion of this website (including but not limited to any copyrighted material, trademarks, or other proprietary information) may not be reproduced, duplicated, copied, sold, resold, visited, distributed or otherwise exploited for any commercial purpose without express written consent of Lidkar Seller Services Private Limited and / or its affiliates, as may be applicable.
You may not frame or use framing techniques to enclose any trademark, logo, or other proprietary information (including images, text, page layout, or form) of Lidkar.com and its affiliates without express written consent. You may not use any meta tags or any other “hidden text” utilising Lidkar Limited’s or its affiliates’ names or trademarks without the express written consent of Lidkar Limited and / or its affiliates, as applicable. Any unauthorised use terminates the permission or license granted by Lidkar Limited and / or its affiliates, as applicable.
You are granted a limited, revocable, and non-exclusive right to create a hyperlink to the Welcome page of LIDKAR as long as the link does not portray LIDKAR, Lidkar.com , their affiliates, or their products or services in a false, misleading, derogatory, or otherwise offensive matter. You may not use any Lidkar logo or other proprietary graphic or trademark as part of the link without express written consent of Lidkar Seller Private Limited and / or its affiliates, as may be applicable.</li>
          <li className="mb-2  text-[14px] "><p className='font-helvetica  text-[18px]'> Your Conduct:</p> You must not use the website in any way that causes, or is likely to cause, the website or access to it to be interrupted, damaged or impaired in any way You understand that you, and not Lidkar.com are responsible for all electronic communications and content sent from your computer to us and you must use the website for lawful purposes only. You must not use the website for any of the following:
for fraudulent purposes, or in connection with a criminal offense or other unlawful activity
to send, use or reuse any material that does not belong to you; or is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, insulting or harassing, blasphemous, defamatory, libelous, obscene, pornographic, or menacing; ethnically objectionable, disparaging or in breach of copyright, trademark, patent, confidentiality, privacy or any other proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, defence, security or sovereignty of India or friendly relations with foreign States or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting other nation; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource, political campaigning, commercial solicitation, chain letters, mass mailings or any “spam; or is patently false and untrue.
to cause annoyance, inconvenience or needless anxiety.</li>
<li className="mb-2  text-[14px] "><p className='font-helvetica  text-[18px]'> Reviews, comments, communications and other content:</p>Users of this website may post reviews, comments and other content; send communications; and submit suggestions, ideas, comments, questions, or other information, as long as the content is not illegal, obscene, abusive, threatening, defamatory, invasive of privacy, infringing of intellectual property rights, or otherwise injurious to third parties, or objectionable and does not consist of or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings, or any form of “spam.” In the event a user uses a false e-mail address, impersonates any person or entity, or otherwise misleads as to the origin of any content. Lidkar.com reserves the right (but not the obligation) to remove, refuse, delete or edit any content that in the sole judgement of Lidkar.com violates these Conditions of use and, or terminate your permission to access or use this website.
If you do post content or submit material, and unless we indicate otherwise, you
1. (a) grant Lidkar Seller Services Private Limited and its affiliates a non-exclusive, royalty-free, irrevocable, perpetual and fully sub licensable rights to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media; and
2. (b) Lidkar Seller Services Private Limited and its affiliates and sublicensees the right to use the name that you submit in connection with such content, if they choose.
You agree that the rights you grant above are irrevocable during the entire period of protection of your intellectual property rights associated with such content and material. You agree to waive your right to be identified as the author of such content and your right to object to derogatory treatment of such content. You agree to perform all further acts necessary to perfect any of the above rights granted by you to Lidkar, including the execution of deeds and documents, at its request.

You represent and warrant that you own or otherwise control all of the rights to the content that you post or that you otherwise provide on or through the website; that, as at the date that the content or material is submitted to Lidkar.com: (i) the content and material is accurate; (ii) use of the content and material you supply does not breach any applicable Lidkar.com policies or guidelines and will not cause injury to any person or entity (including that the content or material is not defamatory); (iii) the content is lawful. You agree to indemnify Lidkar and its affiliates for all claims brought by a third party against it or its affiliates arising out of or in connection with a breach of any of these warranties.
</li>
<li className="mb-2  text-[14px] "><p className='font-helvetica  text-[18px]'> Claims against Objectionable Content:</p> You can refer to the product detail page on Lidkar.com for checking any product details provided by the seller regarding the following:

<li className='text-[14px]'>the total price in single figure of any good or service, along with the breakup price for the good or service, showing all the compulsory and voluntary charges such as delivery charges, postage and handling charges, conveyance charges and the applicable tax, as applicable. These details are available on the invoice issued to you. To understand how to generate an invoice you can refer here.</li>
<li className='text-[14px]' >mandatory notices and information provided by applicable laws, and the expiry date of the good being offered for sale, where applicable; . </li>
<li className='text-[14px]' >goods and services offered for sale by the seller including country of origin which are necessary for enabling the consumer to make an informed decision at the pre-purchase stage. </li>
<li className='text-[14px]' > importer and the guarantees related to the authenticity or genuineness of the imported products; and.</li>
<li className='text-[14px] text-justify' >guarantees or warranties applicable to such goods or services. .Further, all contractual information required to be disclosed by law is incorporated as part of the LIDKAR Services Business Solutions Agreement (BSA) executed by each seller on Lidkar,com , prior to listing its products. A copy of the BSA is available.
Because Lidkar.com lists millions of products for sale offered by sellers on the website and hosts many thousands of comments, it is not possible for us to be aware of the contents of each product listed for sale, or each comment or review that is displayed. Accordingly, Lidkar.com operates on a “notice and takedown” basis. If you believe that any content on the website is illegal, offensive (including but not limited to material that is sexually explicit content or which promotes racism, bigotry, hatred or physical harm), deceptive, misleading, abusive, indecent, insulting or harassing, blasphemous, defamatory, libelous, obscene, pornographic, paedophilic, invasive of another’s privacy or menacing; ethnically objectionable, disparaging; or in breach of a third party’s confidential, proprietary information or right; or is otherwise injurious to third parties; or relates to or promotes money laundering or gambling; or is harmful to minors in any way; or impersonates another person; or threatens the unity, integrity, defence, security or sovereignty of India or friendly relations with foreign States, or public order, or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or in insulting other nation; or objectionable or otherwise unlawful in any manner whatsoever; or which consists of or contains software viruses or any other computer code, file or program designed to interrupt, destroy or limit the functionality of any computer resource; or is patently false and untrue (” Objectionable Content “),. Once this procedure has been followed, Lidkar.com will make all reasonable endeavours to remove such Objectionable Content complained about within a reasonable time. </li>
</li>

<li className="mb-2  text-[14px] "><p className='font-helvetica  text-[18px]'> Copyright, authors' rights and database rights:</p>All content included on the website, such as text, graphics, logos, button icons, images, audio clips, digital downloads, data compilations, and software, is the property of Lidkar Seller Services Private Limited, its affiliates or its content suppliers and is protected by India and international copyright, authors’ rights and database right laws. The compilation of all content on this website is the exclusive property of Lidkar Seller Services Private Limited and its affiliates and is protected by laws of India and international copyright and database right laws. All software used on this website is the property of Lidkar , its affiliates or its software suppliers and is protected by India and international copyright and author’ rights laws.
You may not systematically extract/ or re-utilise parts of the contents of the website without Lidkar.com and / or its affiliate’s (as may be applicable) express written consent. In particular, you may not utilise any data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) for re-utilisation of any substantial parts of this website, without Lidkar and / or its affiliate’s (as may be applicable) express written consent. You may also not create and/ or publish your own database that features substantial (eg: prices and product listings) parts of this website without Lidkar and / or its affiliate’s (as may be applicable) express written consent.
</li>
<li className="mb-2  text-[14px] text-justify "><p className='font-helvetica  text-[18px]'>Intellectual Property Claims:</p>Lidkar and its affiliates respect the intellectual property of others. If you believe that your intellectual property rights have been used in a way that gives rise to concerns of infringement.
</li>
<li className="mb-2  text-[14px] text-justify "><p className='font-helvetica  text-[18px]'>Children:</p>Use of LIDKAR.com is available only to persons who can form a legally binding contract under the Indian Contract Act, 1872. If you are a minor i.e. under the age of 18 years, you may use LIDKAR.com only with the involvement of a parent or guardian.
</li>
<li className="mb-2  text-[14px] text-justify "><p className='font-helvetica  text-[18px]'>Communications:</p>When you visit Lidkar.com, you are communicating with us electronically. You will be required to provide a valid phone number while placing an order with us. We may communicate with you by e-mail, SMS, phone call or by posting notices on the website or by any other mode of communication. For contractual purposes, you consent to receive communications (including transactional, promotional and/or commercial messages), from us with respect to your use of the website and/or your order placed on the website.
</li>
<li className="mb-2  text-[14px]  text-justify"><p className='font-helvetica  text-[18px]'>Alteration of Service or Amendments to the Conditions:</p>We reserve the right to make changes to our website, policies, and these Conditions of Use at any time. You will be subject to the policies and Conditions of Use in force at the time that you use the website or that you order goods from us, unless any change to those policies or these conditions is required to be made by law or government authority (in which case it will apply to orders previously placed by you). If any of these conditions is deemed invalid, void, or for any reason unenforceable, that condition will be deemed severable and will not affect the validity and enforceability of any remaining condition.
</li>
<li className="mb-2  text-[14px] text-justify "><p className='font-helvetica  text-[18px]'>Waiver:</p>If you breach these conditions and we take no action, we will still be entitled to use our rights and remedies in any other situation where you breach these conditions.
</li>
<li className="mb-2  text-[14px] text-justify"><p className='font-helvetica  text-[18px]'>Governing law and Jurisdiction:</p>These conditions are governed by and construed in accordance with the laws of India. You agree, as we do, to submit to the exclusive jurisdiction of the courts at Bengaluru.
</li>
<li className="mb-2  text-[14px] text-justify "><p className='font-helvetica  text-[18px]'>Customer Complaint Details:</p>
The details pertaining to each customer complaint lodged (including the status of the complaint and tracking details) are provided in the return e-mails which are sent back to the customers.
</li>
<li>
<span className="font-semibold">Important Note:</span> Goods once sold will not be taken back or exchanged

  </li>

        </ul>
      </section>
  
   
  

  </div>
  
  );
}

export default TermsAndConditionPage;

// export const BASE_URL = "/lidkar"
// export const BASE_URL = "https://lidkartest.sunplussoftware.com:6200/lidkar"
// export const BASE_URL = "http://localhost:8080"
export const BASE_URL = "https://beta.lidkar.com/lidkar"


export const endpoints = {
  SIGNUP_API: BASE_URL + "/api/user/createUser",
  LOGIN_API: BASE_URL + "/login",
  RESETPASSTOKEN_API: BASE_URL + "/auth/reset-password-token",
  RESETPASSWORD_API: BASE_URL + "/auth/reset-password", 
  GET_USERDETAILS_BY_VERIFY_TOKEN: BASE_URL + "/getUserDetailsByVerifyToken",
  VERIFY_USER: BASE_URL + "/verifyUser",
  FORGOT_PASSWORD: BASE_URL + "/forgotPassword",
  FORGOT_PASSWORD_VERIFY: "/verifyForgotPassword",
  CHANGE_PASSWORD: BASE_URL + "/resetPassword",
  CREATE_CONTACT_US: BASE_URL + "/api/contactUs/createContactUs",
  EDIT_PROFILE_DETAILS: BASE_URL + "/api/user/updateProfile",
  GET_USER_BY_ID: BASE_URL + "/api/user/getUserById",
}

export const categoryEndPoints = {
  GET_ALL_CATEGORIES: BASE_URL + "/api/category/getAllCategories",
  GET_CATEGORY_BY_ID:BASE_URL + '/api/category/getCategoryById',
  GET_BY_CATEGORY :BASE_URL + '/api/category/getByCategories',
  GET_ALL_SUBCATEGORIES :BASE_URL +'/api/subCategory/getAllSubCategories'
}

export const productsEndPoints = {
  SEARCH_BY_PRODUCT_NAME: BASE_URL + "/api/products/searchByProductName",
  GET_ALL_PRODUCTS: BASE_URL + "/api/products/getAllProducts",
  GET_PRODUCT_BY_PRODUCTNAME: BASE_URL + "/api/products/findByProductName",
  GET_PRODUCT_BY_PRODUCTID: BASE_URL + "/api/products/getProductById",
  GET_PRODUCT_BY_CATEGORYID: BASE_URL + "/api/products/getCategoryById",
  GET_PRODUCT_BY_MULTIPLE_CATEGORYID: BASE_URL + "/api/products/getProductsByMultipleCategories",
  GET_ALLPRODUCTBY_SUBCATEGORIES :BASE_URL + "/api/subCategory/getAllProductsBySubCategory?",
  GET_ALLPRODUCTS_BY_CATEGORY: BASE_URL + "/api/category/getAllProductsByCategory?",
  GET_PRODUCT_BY_SUB_CATEGORYID: BASE_URL + "/api/products/getProductsSubCategoryId",
  GET_PRODUCT_BY_SUB_SUB_CATEGORYID : BASE_URL + "/api/subSubCategory/getAllProductsBySubSubCategory?",
  GETMENSPRODUCTS:                       BASE_URL +"/api/products/getAllProductsForMen",
  GETWOMENSPRODUCTS : BASE_URL+ "/api/products/getAllProductsForWomen",
  GETUNISEXPRODUCTS: BASE_URL + "/api/products/getAllProductsForUnisex",
  GET_ALL_FEATUREDPRODUCT :BASE_URL + "/api/products/getAllFeaturedProducts",
  GET_ALL_CLUTCHES_WALLETS:BASE_URL + "/api/products/getClutchesAndWallets",
  GET_ALL_CHAPPALS:BASE_URL + '/api/products/getChappalsForGentsAndLadies',
}

export const colorEndPoints = {
  GET_ALL_COLORS: BASE_URL + "/api/color/getAllColors"
}

export const cartItemEndPoints = {
  ADD_ITEM_TO_CART: BASE_URL + "/api/cart/addToCart",
  INCREAMENTCARTITEM: BASE_URL + "/api/cart/incrementCartItem",
  DECREMENT_CART_ITEM: BASE_URL + "/api/cart/decrementCartItem",
  GET_ALL_CART_ITEMS: BASE_URL + "/api/cart/getCartItemsByUserAndStatus",
  GET_ALL_CART_ITEMS_WITH_PRODUCTS: BASE_URL + "/api/cart/getCombinedCartItemsByUserAndStatus",
  REMOVE_ITEM_FROM_CART: BASE_URL + "/api/cart/removeItemFromCart",
  UPDATE_CART_ITEM_BY_SIZE : BASE_URL + "/api/cart/updateCartItemBySize",
  UPDATE_CART_ITEM_BY_COLOR : BASE_URL + "/api/cart/updateCartItemByColor"
}

export const wishlistEndPoints = {
  ADD_ITEM_TO_WISHLIST: BASE_URL + "/api/wishlist/addProductToWishlist",
  GET_ALL_WISHLIST_ITEMS: BASE_URL + "/api/wishlist/getWishlistWithUser",
  REMOVE_SINGLE_WISHLIST: BASE_URL + "/api/wishlist/removeFromWishlist"
}

export const createAddressEndPoints = {
  ADD_ADDRESS: BASE_URL + "/api/address/createAddress",
  GET_ALL_ADDRESS: BASE_URL + "/api/address/getAllAddressesByUserId",
  DELETE_ADDRESS: BASE_URL + "/api/address/deleteAddress",
  GET_ADDRESS_BY_ID: BASE_URL + "/api/address/getAddressById",
  UPDATE_ADDRESS_BY_ID: BASE_URL + "/api/address/updateAddress",
}

export const orderEndPoints = {
  GET_ALL_ORDERS: BASE_URL + "/api/order/getAllOrders",
  GET_ORDER_DETAILS_BY_ID : BASE_URL + "/api/order/getOrderDetailsById",
  GET_ALL_ORDERS_Placed : BASE_URL + "/api/order/getAllPlacedOrders",

}
export const featuredProductEndPoints={
  GET_FEATURED_PRODUCT:BASE_URL +  "/api/products/getAllFeaturedProducts"
}
export const specialPriceEndPoints={
  GET_SPECIAL_PRICE:BASE_URL + "/api/products/getAllSpecialPriceProducts"
}


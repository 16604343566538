import LoadingPage from "../../pages/LoadingPage";
import { getAllWishlistsByUser } from "../../services/operations/wishlistApi";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import WhistListEmptyPage from "../../pages/WhistListEmptyPage";
import { Card } from "../ui/card";
import { Button } from "../ui/button";
import { Separator } from "../ui/separator";
import { MdCurrencyRupee } from "react-icons/md";
import { IoIosClose } from "react-icons/io";
import { removeSingleWishList } from "../../services/operations/wishlistApi";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { IoBag } from "react-icons/io5";
import { getAllProducts } from "../../services/operations/productApi";
import { Link } from "react-router-dom";
import {
  addItemToCart,
  decrementCartItem,
  getCartItemsByUserAndStatus,
  getCartItemsByUserAndStatusAndProducts,
  incrementCartItem,
} from "../../services/operations/cartItemApi.js";
import toast from "react-hot-toast";
import {
  setWishlisData,
  setWishlistCount,
} from "../../redux/slices/wishlistSlice";
import { setCartCount, setCartData } from "../../redux/slices/cartSlice";

const WhistList = ({ wishlistData, wishlistLoading }) => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [productsData, setProductsData] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [sizeId, setSizeId] = useState(null);
  const [colorId, setColorId] = useState(null);
  const [cartLoading, setCartLoading] = useState(false);
  const isDisabled = !sizeId || !colorId;
  const [loading, setLoading] = useState(false);

  

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getAllProducts();
        setProductsData(response);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCartLoading(true);
        const response = await getCartItemsByUserAndStatus(token);
        setCartItems(response);
        dispatch(setCartData(response));
        dispatch(setCartCount(response?.length));
      } catch (error) {
        console.error(error);
      } finally {
        setCartLoading(false);
      }
    };
    fetchData();
  }, [token]);

  const addToCart = async (productId, sizeId, colorId) => {
    if (!sizeId || !colorId) {
      toast.error("Please Select Size and color");
      return;
    }
    if (!sizeId) {
      toast.error("Please Select Size");
      return;
    }
    if (!colorId) {
      toast.error("Please Select Color");
      return;
    }
    try {
      setCartLoading(true);
      const response = await addItemToCart(productId, sizeId, colorId, token);
      const response1 = await getCartItemsByUserAndStatusAndProducts(token);
    
      dispatch(setCartData(response1));
      dispatch(setCartCount(response1?.length));
      setCartItems((prevItems) => {
        if (!Array.isArray(prevItems)) {
          return [response];
        }
        const existingItem = prevItems.find(
          (item) => item?.productId === productId
        );
        if (existingItem) {
          return prevItems.map((item) =>
            item?.productId === productId ? response : item
          );
        } else {
          return [...prevItems, response];
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  const handleRemoveSingleWishList = async (wishListId) => {
    try {
      await removeSingleWishList(token, wishListId);
      const response = await getAllWishlistsByUser(token);
      dispatch(setWishlisData(response));
      dispatch(setWishlistCount(response?.length));
    } catch (error) {
      console.error(error);
    }
  };

  const decreaseQuantity = async (cartItemId) => {
    try {
      setCartLoading(true);
      const response = await decrementCartItem(cartItemId, token);
      if (response.quantity === 1) {
        window.location.reload();
      } else {
        // Update the cart item normally
        setCartItems((prevItems) =>
          prevItems.map((item) =>
            item.cartItemId === cartItemId ? response : item
          )
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  const increaseQuantity = async (cartItemId) => {
    try {
      setCartLoading(true);
      const response = await incrementCartItem(cartItemId, token);
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.cartItemId === cartItemId ? response : item
        )
      );
    } catch (error) {
      console.error(error);
    } finally {
      setCartLoading(false);
    }
  };
  const colorMap = {
    "Dark Brown": "#5C4033",
    "Dark Blue": "#00008B",
    "Dark Green": "#006400",
    "White": "#FFFFFF",
    "Tan Brown": "#A52A2A",
    "Maroon": "#800000"
  };
  return (
    <div className="w-1920 px-[1rem] sm:px-[5rem] py-2">
      {wishlistLoading || cartLoading ? (
        <div className="flex h-full mt-40 pt-40 items-center justify-center">
          <LoadingPage />
        </div>
      ) : wishlistData && wishlistData?.length === 0 ? (
        <WhistListEmptyPage />
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 h-fit md:grid-cols-3 lg:grid-cols-4 gap-4">
          {wishlistData?.map((item, index) => {
            const product = item.products[0];
            const inCartItem = cartItems?.find(
              (cartItem) => cartItem?.productId === product?.productId
            );
            return (
              <Card key={index} className="w-full p-3 relative">
                <div className="border relative mx-auto rounded w-full min-h-[230px]">
                  <img
                    className="object-fit ml-2 p-3"
                    src={product.productImage[0]?.imagePath}
                    alt="Wishlist Image"
                    style={{ width: "-webkit-fill-available" }}
                  />
                  <AlertDialog>
                    <AlertDialogTrigger asChild>
                      <IoIosClose
                        fill="red"
                        className="absolute top-2 right-1 cursor-pointer"
                        fontSize={35}
                      />
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>
                          Are you absolutely sure To Remove from Wishlist?
                        </AlertDialogTitle>
                        <AlertDialogDescription>
                          This action cannot be undone. This will permanently
                          delete your account and remove your data from our
                          servers.
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>Cancel</AlertDialogCancel>
                        <AlertDialogAction
                          className="bg-red-500 hover:bg-red-800 text-white"
                          onClick={() =>
                            handleRemoveSingleWishList(item.wishListId)
                          }
                        >
                          Remove
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </div>
                <div className=" text-center min-h-[37px]  ">
                  <h1 className=" text-[#303030] text-[16px] min-h-[20px]  font-orator  mt-2">
                    {product.productName}
                  </h1>
                </div>
                <div className="flex mt-2 gap-6 justify-center">
                  <span className="flex  justify-center items-center font-bold font-basicommercial">
                    <MdCurrencyRupee /> {Math.round(product.sellingPrice)}
                  </span>
                  {product?.discount != 0 && (
                    <span className="flex gap-x-3 text-[#514F4F]">
                      MRP{" "}
                      <p className="line-through text-red-400">
                        {product.mrpPrice}
                      </p>
                    </span>
                  )}
                </div>
                <Separator className="mb-2 mt-2 w-full" />
                {!inCartItem && product.inStock && (


                  <div className="w-full flex justify-center">
                    <Dialog>
                      <DialogTrigger className=" bg-[#6C3516] hover:bg-[#4a250f]  text-white border border-1 font-semi-bold font-helvetica bg-none border-none outline-none px-2 py-2 rounded">
                        Add To Cart
                      </DialogTrigger>
                      {token ? (
                        <DialogContent>
                          <div className="flex w-full items-center">
                            <div className="w-[30%] bg-slate-200">
                              <img
                                src={product.productImage[0]?.imagePath}
                                alt="categoryImage"
                                className="w-[85%] h-[80%] object-fit mx-auto rounded-lg"
                              />
                            </div>
                            <div className="flex flex-col ml-10">
                              <p className="text-[#303030] font-orator mt-2 font-bold">
                                {product.productName}
                              </p>
                              <div className="flex gap-6 items-center mt-3 justify-center">
                                <p className="text-[#636363] font-basicommercial ">
                                  {product.sellingPrice}
                                </p>
                                <p className="flex space-x-2 font-helvetica">
                                  <span> MRP</span>
                                  <p className="line-through font-basicommercial">
                                    {product.mrpPrice}
                                  </p>
                                </p>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="flex gap-3 items-center justify-start">
                            <h1>Select Sizes</h1>
                            {product.sizes?.map((size, sizeIndex) => (
                              <div
                                onClick={() => setSizeId(size.sizeId)}
                                className={`w-10 h-10 flex rounded-full shadow-md cursor-pointer items-center justify-center ${sizeId === size.sizeId
                                  ? "border border-black"
                                  : ""
                                  }`}
                                key={sizeIndex}
                              >
                                <button>{size.sizeNumber}</button>
                              </div>
                            ))}
                          </div>
                          <hr />
                          <div className="flex gap-3 items-center justify-start">
                            <h1>Select Colors</h1>
                            {product.colors?.map((color, colorIndex) => (
                              <div
                                onClick={() => setColorId(color.colorId)}
                                className={`w-10 h-10 flex rounded-full cursor-pointer shadow-md items-center justify-center ${colorId === color.colorId
                                  ? "border-2 border-green-600"
                                  : ""
                                  }`}
                                  style={{ background: colorMap[color?.colorName] || color?.colorName }}
                                key={colorIndex}
                              ></div>
                            ))}
                          </div>
                          <DialogFooter>
                            <button
                            // disabled={isButtonDisabled}
                              className={`flex items-center justify-center px-2 py-2 rounded-md gap-x-2 ${isDisabled
                                ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                                : "bg-[#6C3516] hover:bg-[#4a250f]  text-white"
                                }`}
                              onClick={() =>
                                addToCart(product?.productId, sizeId, colorId)
                              }
                              disabled={!sizeId || !colorId}
                            >
                              <IoBag /> Add To Cart
                            </button>
                          </DialogFooter>
                        </DialogContent>
                      ) : (
                        <DialogContent className="sm:max-w-[425px]">
                          <DialogHeader>
                            <DialogTitle className="text-[#000000] font-Nunito bold text-[24px]">
                              Sign in/Sign up
                            </DialogTitle>
                            <DialogDescription className="text-[#EC0606] text-[20px] font-outFit">
                              Sign in/Signup Required to Add Items to Your Cart
                            </DialogDescription>
                            <DialogDescription className="text-[#050505] text-[15px] mb-4 font-outFit">
                              No worries! It only takes a minute to sign in and
                              unlock a world of convenient shopping.
                            </DialogDescription>
                          </DialogHeader>
                          <DialogFooter className="flex justify-start">
                            <Link
                              to="/login"
                              className="bg-[#56243B] py-3 text-white rounded-lg px-7"
                            >
                              Go to Sign in
                            </Link>
                          </DialogFooter>
                        </DialogContent>
                      )}
                    </Dialog>
                  </div>
                )}
              </Card>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default WhistList;

import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import LoadingPage from "../../pages/LoadingPage";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table.jsx";
import { getAllOrders } from "../../services/operations/orderApi.js";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../ui/pagination";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import SingleOrderModel from "../models/SingleOrderModel";
import { useLocation } from "react-router-dom";
import OrderEmptyPage from "./OrderEmptyPage";
import { FaCartShopping } from "react-icons/fa6";

const OrderTable = ({ search }) => {
  const { token } = useSelector((state) => state.auth);
  const [tableLoading, setTableLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [filteredOrderDetails, setFilteredOrderDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [isClicked, setIsClicked] = useState(false);
  const [visiblePopup, setVisiblePopup] = useState(null);
  const [visibility, setVisibility] = useState(false);
  const popupRef = useRef(null);

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        const response = await getAllOrders(token);
        setOrderDetails(response?.data || []);
        console.log(response.data,'is')
      
        setFilteredOrderDetails(response?.data || []);
      } catch (error) {
        console.error("Error fetching orders:", error);
      } finally {
        setTableLoading(false);
      }
    };
    fetchData();
  }, [token]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const paginatedData = filteredOrderDetails.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredOrderDetails.length / itemsPerPage);

  const handleIconClick = (orderNumber) => {
    setVisibility(true);
    setVisiblePopup((prev) => (prev === orderNumber ? null : orderNumber));
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setVisiblePopup(null);
      setVisibility(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    searchProducts(search);
  }, [search]);

  const searchProducts = (search) => {
    if (search?.length > 0) {
      const searchTextLower = search?.toLowerCase();
      const filteredOrders = orderDetails.filter((order) => {
        const {
          userName,
          orderNumber,
          totalPrice,
          orderStatus,
          paymentStatus,
        } = order;
        return (
          userName.toLowerCase().includes(searchTextLower) ||
          orderNumber.toString().includes(search) ||
          totalPrice.toString().includes(search) ||
          orderStatus.toLowerCase().includes(searchTextLower) ||
          paymentStatus.toLowerCase().includes(searchTextLower)
        );
      });
      setFilteredOrderDetails(filteredOrders);
    } else {
      setFilteredOrderDetails(orderDetails);
    }
  };
  const cancelOrder = ()=>{

  }

  return (
    <div className="overflow-x-auto sm:overflow-x-visible">
      {tableLoading ? (
        <div className="flex h-[300px] w-[801px] items-center justify-center mt-[150px]">
          <LoadingPage />
        </div>
      ) : (
        <Table
          className={`${
            pathname === "/accountDetails" ? "w-[750px]" : "w-[869px]"
          } mx-auto  h-fit mb-14 p-3`}
        >
          <div className="overflow-x-auto w-full max-h-[400px]">
          <TableHeader>
            <TableRow>
              <TableHead className=" font-helvetica">Sl No.</TableHead>
              <TableHead className="whitespace-nowrap font-helvetica">Order No</TableHead>
              <TableHead className='font-helvetica'>Name</TableHead>
              <TableHead className='font-helvetica'>Price</TableHead>
              <TableHead className="text-right whitespace-nowrap font-helvetica">
                Order Status
              </TableHead>
              <TableHead className="text-right whitespace-nowrap font-helvetica">
                Payment Status
              </TableHead>
              <TableHead
                className={`text-center font-helvetica ${
                  pathname === "/accountDetails" ? "mr-[53px]" : "mr-[78px]"
                } `}
              >
                Action
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {paginatedData?.map((invoice, index) => (
              <TableRow key={invoice?.orderNumber}>
                <TableCell className="font-basicommercial">
                  {index + 1}
                </TableCell>
                <TableCell className="font-basicommercial">
                  {invoice?.orderNumber}
                </TableCell>
               
                <TableCell className="font-basicommercial">{invoice?.userName}</TableCell>
                <TableCell className="font-basicommercial">{Math.floor(invoice?.totalPrice)}</TableCell>
                <TableCell className="text-right font-basicommercial ">
                  {invoice?.orderStatus}
                </TableCell>
                <TableCell className="text-right font-basicommercial">
                  {invoice?.paymentStatus}
                </TableCell>
                <TableCell className="flex justify-end items-center w-[200px]">
                <div className="flex  items-center justfiy-end w-full pl-6">
                    
                         
                      
                          <SingleOrderModel
                            orderId={invoice?.orderId}
                            visibility={visibility}
                            setVisibility={setVisibility}
                          />
                       
                          <div className="flex py-3 justify-start hover:text-blue-600 mb-1 gap-2 items-center py-1 cursor-pointer px-4 p-2">
                            {/*<RxCross2 />*/}
                            {/* <p className="whitespace-nowrap  ">Cancel Order</p> */}
                            <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <p className="text-black whitespace-nowrap px-6 py-3 text-[15px] gap-x-[30px] font-helvetica flex justify-start items-center mb-1 cursor-pointer">
                          Cancel Order
                            {/* <CiLogin fontSize={21} className="ml-2" /> */}
                          </p>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>
                              Are you absolutely sure?
                            </AlertDialogTitle>
                            <AlertDialogDescription>
                              This action will cancel the Order.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction
                              className="bg-white border whitespace-nowrap text-base border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                              onClick={cancelOrder}
                            >
                              Cancel Order
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                          </div>
                        
                 
                  
                      
                
                </div>
                 {/* <div className="relative">
                    <div className="z-10 mr-[81px]  cursor-pointer">
                      <BsThreeDotsVertical
                        onClick={() => handleIconClick(invoice?.orderNumber)}
                      />
                    </div>

                    {visiblePopup === invoice?.orderNumber && (
                      <div
                        ref={popupRef}
                        className="absolute top-[3px] z-50 right-[-76px] bg-white  border rounded shadow-lg overflow-x-hidden w-[160px]  items-center justify-center"
                      >
                        <div className="flex justify-start items-center hover:bg-custom-fff5ec gap-2 cursor-pointer px-2 p-2">
                          <SingleOrderModel
                            orderId={invoice?.orderId}
                            visibility={visibility}
                            setVisibility={setVisibility}
                          />
                        </div>
                        {visibility && (
                          <div className="flex hover:bg-custom-fff5ec py-3 justify-start gap-2 items-center py-1 cursor-pointer px-4 p-2">
                            <RxCross2 />
                            <p className="whitespace-nowrap">Cancel Order</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>*/}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          </div>
        </Table>
      )}
      <div className={`${orderDetails.length === 0 && "hidden"} mt-6 flex justify-center`}>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        >
          <PaginationContent>
            <PaginationPrevious
              className={`${
                currentPage === 1 ? "opacity-50 pointer-events-none" : ""
              } cursor-pointer`}
              disabled={currentPage === 1}
              onClick={() => {
                setCurrentPage(currentPage - 1);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            />
            {Array.from({ length: totalPages }, (_, i) => i + 1).map(
              (pageNumber) => (
                <PaginationItem key={pageNumber}>
                  <PaginationLink
                    isActive={pageNumber === currentPage}
                    onClick={() => {
                      setCurrentPage(pageNumber);
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    }}
                    className={`cursor-pointer ${
                      pageNumber === currentPage
                        ? "bg-[#FF9F00] text-white"
                        : "bg-gray-200 shadow-sm text-black"
                    } `}
                  >
                    {pageNumber}
                  </PaginationLink>
                </PaginationItem>
              )
            )}
            <PaginationNext
              className={`${
                currentPage === totalPages
                  ? "opacity-50 pointer-events-none"
                  : ""
              } cursor-pointer`}
              disabled={currentPage === totalPages}
              onClick={() => {
                setCurrentPage(currentPage + 1);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            />
          </PaginationContent>
        </Pagination>
      </div>
    </div>
  );
};

export default OrderTable;

import React from 'react';

const PaymentFail = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md text-center">
        <svg
          className="mx-auto mb-4 w-16 h-16 text-red-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 9v2m0 4h.01M6.341 4.293l1.414 1.414M17.657 17.657l1.414 1.414M4.293 6.341l1.414-1.414M17.657 6.343l1.414-1.414M9 12h2m4-2l2 2-2 2m-4 4l-2 2-2-2m8 0l2-2-2-2"
          />
        </svg>
        <h1 className="text-2xl font-bold text-gray-800">Payment Failed</h1>
        <p className="mt-2 text-gray-600">
          Unfortunately, your payment could not be processed. Please try again or contact support if the issue persists.
        </p>
        <div className="mt-4">
          <a
            href="/cart"
            className="inline-block px-6 py-2 text-white bg-red-600 rounded hover:bg-red-700 transition duration-300"
          >
            Retry Payment
          </a>
          <a
            href="/"
            className="inline-block ml-4 px-6 py-2 text-gray-700 bg-gray-300 rounded hover:bg-gray-400 transition duration-300"
          >
            Back to Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default PaymentFail;

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getAllAddress,
  removeAddress,
} from "../../services/operations/addressApi";
import { IoCloseSharp } from "react-icons/io5";
import { MdOutlineDelete } from "react-icons/md";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import toast from "react-hot-toast";
import { Button } from "../ui/button";
import AddressModel from "../models/AddressModel";
import noAddress from "../../images/no-saved-address.png";
import { Card } from "../ui/card";
import { getUserById } from "../../services/operations/authApi";

const AddressDetails = ({ userData,allAddressData,setAllAddressData,hideRemoveBtn,setHideRemoveBtn }) => {
 console.log(allAddressData,'oi')
 
  const [newUser, setNewUser] = useState([])
  const { token, user } = useSelector((state) => state.auth);
  const userId = user?.userId;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchData();
    fetchUser()
  }, [allAddressData])
  const fetchUser = async (userId) => {
    const response = await getUserById(userId)


  }

  const fetchData = async () => {
    try {
  
      setLoading(true);
      const response = await getAllAddress(userId, token);
      if (!response) {
        console.log('mkr')
       
        setAllAddressData(response);
       
      }
      setAllAddressData(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDeleteAddress = async (addressId) => {
    try {
    
      await removeAddress(addressId, token);
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="w-[330px] sm:w-[450px] h-[390px] justify-center xl:justify-between  mt-0 ">
      {allAddressData?.length === 0 ? (
        <div className="flex justify-center items-center flex-col lg:h-[300px]  ">
          <img
            src={noAddress}
            alt="noAddress"
            className="w-[10rem] h-[10rem]"
          />
          <p className="font-bold text-base font-prompt text-[#000000]">
            Add Your Address Now
          </p>
          <p className="text-base text-[#000000] font-prompt text-center">
            Add your home and office addresses and enjoy faster checkout
          </p>
          <button className="bg-[#FBD3A4] text-black px-3 py-2 rounded-lg mt-5">
            <AddressModel
              editTag={"Add New Address"}
              allAddressData={allAddressData}
              setAllAddressData={setAllAddressData}
              fetchData={fetchData}
            />
          </button>
        </div>
      ) : (
      <div className="w-full h-[450px] justify-between  overflow-y-scroll scrollbar mt-0">
        <div className="flex justify-start flex-wrap flex-col gap-5 items-start h-fit w-full mt-0">
          {/* <div className="flex gap-9 items-center bg-[#D9D9D9] px-2 py-2 rounded">
              <p className="font-prompt text-[14px] text-[#000000]">
                <AddressModel
                  editTag={"Add New Address"}
                  allAddressData={allAddressData}
                  setAllAddressData={setAllAddressData}
                />
              </p>
            </div> */}
           
               
          {allAddressData?.map((address, index) => (
            <div
              key={index}
              className="flex flex-wrap gap-9  justify-start items-center"
            >
              <Card className="border bg-[rgb(246,246,246)] border-[#D9D9D9] w-[300px] sm:w-[401px] h-fit py-1">
                <div className="flex">
                <div>
                <div className="flex justify-start px-5 items-center gap-6 py-2">
                 
                   <div className="font-Nunito font-bold text-[16px]">
                    {userData?.firstName + " " + userData?.lastName}
                  </div>
                

                  {/* <div className="text-[#03A685] border border-[#03A685] w-[75px] h-[25px] px-1 py-3 rounded-full flex justify-center items-center font-prompt">
                    Home
                  </div> */}
                </div>
                <div className="h-fit mb-2">
                  {(address?.street ||
                    address?.landmark ||
                    address?.city ||
                    address?.state) && (
                      <p className="text-[#6C6C6C] text-[15px] px-5 w-[270px]">
                        {`${address?.street}, ${address?.landmark}, ${address?.city}, ${address?.state}`}
                      </p>
                    )}
                </div>

                <p className="font-prompt text-[#6C6C6C] px-5 ">
                  Mobile
                  <span className="font-bold ml-2">{user?.mobileNumber}</span>
                </p>
                </div>
                <div className="flex pr-3 gap-2 justify-end flex-1 relative">
                 
                  <button className="flex absolute right-[12px]  w-fit h-fit px-1  py-1 mt-2 text-center  text-xs lg:text-sm">
                    <AddressModel 
                      editTag={"EditAddress"}
                      addressId={address?.addressId}
                      setAllAddressData={setAllAddressData}
                    />
                  </button>
                 
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        {hideRemoveBtn ? (
                          <></>
                        ) : (
                        
                           <MdOutlineDelete fontSize={19} className="absolute  top-[12px] right-[30px]  cursor-pointer "/>
                     
                        )}
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>
                            Are you absolutely sure?
                          </AlertDialogTitle>
                          <AlertDialogDescription>
                            This action cannot be undone. This will permanently
                            delete your Address and remove your data from our
                            servers.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction
                            onClick={() => {
                              handleDeleteAddress(address?.addressId);
                            }}
                            
                          >
                            Continue
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                 
                </div>
                </div>
                
              </Card>
            </div>
          ))}
        </div>
        </div>
      )}
    </div>
  );
};

export default AddressDetails;

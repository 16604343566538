import React from "react";

const PaymentSummary = ({gstPrice, sellingPrice, calculateTotalPrice}) => {
  return (
    <div className="flex flex-col justify-center h-fit">
      <div className="flex  flex-col justify-center">
        <div className="flex justify-between px-5 sm:px-9 py-2">
          <p className="font-helvetica text-[#090909] text-[16px]">Total Selling Price</p>
          <p className=" text-[#000000] font-basicommercial ">
            ₹{Math.round(sellingPrice)}
          </p>
        </div>
        <div className="flex justify-between px-5 sm:px-9 py-2">
          <p className="font-helvetica text-[#090909] text-[16px] ">Total Tax</p>
          <p className=" font-basicommercial text-[#000000]  ">
            ₹{Math.round(gstPrice)}
          </p>
        </div>
        {/* <div className="flex justify-between px-9 py-2">
          <p className="font-Nunito text-[#090909] text-[16px]">
            Coupon Discount
          </p>
          <p className="font-semi-bold text-[#FF0000] font-prompt ">
            Apply Coupon
          </p>
        </div> */}
        <hr color="grey" className="mt-3" />
        <div className="flex justify-between px-5 sm:px-9 py-2">
          <p className="font-helvetica text-[#090909] text-[16px]">
            Total amount:
          </p>
          <p className="font-bold font-basicommercial text-[19px] text-black ">
            ₹{Math.round(calculateTotalPrice())}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaymentSummary;

import{React, useEffect, useState} from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../components/ui/card";
import { MdVerified } from "react-icons/md";
import { IoIosStar } from "react-icons/io";
import { getAllCategories } from "../../services/operations/cartegoryApi";
import { FaAngleRight } from "react-icons/fa6";
import ReviewModel from "../models/ReviewModel";

const DescriptionTab = () => {
  const [categoriesData,setCategoriesData] = useState([]);
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllCategories();
        setCategoriesData(response?.slice(0, 5));
     
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);
 
      const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  return (
   
      <div className="p-1 w-full transition-all text-sm duration-1000 ease-in-out ">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-base font-bold font-helvetica ">Ratings & Reviews</h1>
        <button className="flex jusstify-center items-center"><ReviewModel/></button>
        </div>
         <p className="flex gap-1 items-center"> <MdVerified className="text-green-600"/> <span className="text-green-600 text-[14px] font-helvetica ">By Verified Buyers Only</span></p>
         <div className="flex gap-[100px] ">
         <div className="flex flex-col">
           <div className="flex flex-col gap-2 mt-3">
              <h1 className="flex items-center gap-1 font-bold ">0 <IoIosStar className="text-yellow-500" size={19} /></h1>
              <p className="text-base text-black ">0 Reviews</p>
           </div>
           <div className="flex flex-col gap-2 mt-3">
              <h1 className="flex items-center gap-1 font-bold">0%</h1>
              <p className="text-base text-black ">Recommended</p>
           </div>
           </div>
           <div className="flex flex-col">
             <div className="flex items-center">
      <span className="w-10 text-gray-800">{5}</span>
       <div className="relative w-[150px] h-2 bg-gray-200  overflow-hidden mr-3">
      <div
        className="h-full bg-yellow-300"
        style={{ width: `${0}%` }}
      ></div>
    </div>
      <span className="text-gray-700">{0}</span>
    </div>
              <div className="flex items-center">
      <span className="w-10 text-gray-800">{4}</span>
       <div className="relative w-[150px] h-2 bg-gray-200  overflow-hidden mr-3">
      <div
        className="h-full bg-yellow-300"
        style={{ width: `${0}%` }}
      ></div>
    </div>
      <span className="text-gray-700">{0}</span>
    </div>


      <div className="flex items-center">
      <span className="w-10 text-gray-800">{3}</span>
       <div className="relative w-[150px] h-2 bg-gray-200  overflow-hidden mr-3">
      <div
        className="h-full bg-yellow-300"
        style={{ width: `${0}%` }}
      ></div>
    </div>
      <span className="text-gray-700">{0}</span>
    </div>

  <div className="flex items-center">
      <span className="w-10 text-gray-800">{2}</span>
       <div className="relative w-[150px] h-2 bg-gray-200  overflow-hidden mr-3">
      <div
        className="h-full bg-yellow-300"
        style={{ width: `${0}%` }}
      ></div>
    </div>
      <span className="text-gray-700">{0}</span>
    </div>
  <div className="flex items-center">
      <span className="w-10 text-gray-800">{1}</span>
       <div className="relative w-[150px] h-2 bg-gray-200  overflow-hidden mr-3">
      <div
        className="h-full bg-yellow-300"
        style={{ width: `${0}%` }}
      ></div>
    </div>
      <span className="text-gray-700">{0}</span>
    </div>


           </div>
         </div>
         {/* <div className="mt-3">
           <h1 className="font-helvetica font-bold text-[19px] text-black">Customer Photos (81)</h1>
           <div className="flex gap-5 mt-3">
            {categoriesData.map((ele, index) => (
  <div key={index}>
    <img
      className="w-[3rem] h-[3rem] object-center"
      src={ele?.categoryImage[0]?.imagePath}
      alt={`Category ${index}`}
    />
  </div>
))}
           </div>
            <div className="mt-3">
           <h1 className="font-helvetica font-bold text-[19px] text-black">Customer Reviews (273)</h1>
           <div className="flex flex-col gap-5 mt-3">
           <div className="flex gap-3">
              <p className="w-[2.1rem] rounded-lg text-white h-[1.5rem] bg-green-700 flex justify-center gap-1 items-center" >4 <IoIosStar className="text-white" size={10} /></p>
              <p className="font-helvetica text-[14px]">1 Month Ago</p>
              </div>
              <div>
              <div className="max-w-xl mx-auto p-4 bg-white  rounded-lg">
      <h3 className="text-lg font-bold text-gray-800">Customer Review</h3>
      <p className="text-gray-700 mt-2">
        This product is absolutely fantastic! I've been using it for a few weeks now, and it has exceeded my expectations. The build quality is exceptional, and the features are exactly as described.{' '}
        <span className={`${isExpanded ? '' : 'hidden'}`}>
          The battery life is impressive, lasting several days on a single charge, even with heavy use. Customer support has been responsive and helpful whenever I've had questions. I would highly recommend this product to anyone looking for quality and reliability.
        </span>
      </p>
      <button
        onClick={toggleReadMore}
        className="mt-2 text-blue-600 hover:underline focus:outline-none"
      >
        {isExpanded ? 'View Less' : 'Read More'}
      </button>
    </div>
    </div>
           </div>
         </div>
      </div>
      <p className="text-[12px] mb-3 font-helvetica ">Nandhakumar Srinivasan</p>
      <p className="flex gap-2 text-[19px] items-center">View All 273 Reviews <FaAngleRight size={19} /></p> */}
      </div>
   
  );
};

export default DescriptionTab;

import { useSelector } from 'react-redux';
import { GET_Order_Placed } from '../../src/services/operations/orderApi';
import { FaRegEye } from "react-icons/fa";

import React,{useEffect, useState} from 'react'
import { DataGrid, gridColumnGroupsUnwrappedModelSelector } from '@mui/x-data-grid';
import SingleOrderModel from '../components/models/SingleOrderModel';
const Orders = () => {
    const [allOrders,setAllOrders] = useState([]);
    const { token } = useSelector((state) => state.auth);
  const [visibility, setVisibility] = useState(false);

    useEffect(() => {
        const fetchOrders = async () => {
          try {
            const getOrders = await GET_Order_Placed(token);
            const updatedOrders = getOrders.map((item) => ({ ...item, id: item.orderId }));
            setAllOrders(updatedOrders);
            console.log("updatedOrders",updatedOrders)
            console.log(getOrders, "fhhf");
          } catch (error) {
            console.log("error", error);
          }
        };
      
        fetchOrders();
      }, []);
      const handleView = (orderId) =>{
        return (
              <SingleOrderModel
     orderId={orderId}
     visibility={visibility}
     setVisibility={setVisibility}
   />
        )

      }
     const columns = [
        { 
            field: "serialNo", 
            headerName: "S.No", 
            width: 100,
            headerClassName: "custom-header",
            renderCell: (params) => {
                return params.api.getSortedRowIds().indexOf(params.id) + 1;
            }
            },
          {
            field: "orderNumber",
            headerName: "Order Number",
            flex: 1,
            headerClassName: "custom-header",
            cellClassName: "name-column--cell",
          },
          {
            field: "userName",
            headerName: "User Name",
            flex: 1,
            headerClassName: "custom-header",
            cellClassName: "name-column--cell",
          },
          {
            field: "emailId",
            headerName: "Email Id",
            flex: 1,
            headerClassName: "custom-header",
            cellClassName: "name-column--cell",
          },
          {
            field: "mobileNumber",
            headerName: "Mobile Number",
            flex: 1,
            headerClassName: "custom-header",
            cellClassName: "name-column--cell",
          },
          {
            field: "totalPrice",
            headerName: "Total Price",
            flex: 1,
            headerClassName: "custom-header",
            cellClassName: "name-column--cell",
          },
          {
            field: "paymentStatus",
            headerName: "Payment Status",
            flex: 1,
            headerClassName: "custom-header",
            cellClassName: "name-column--cell",
          },
          {
            field: "View",
            headerName: "View",
            width: 100,
            headerClassName: "custom-header",
            renderCell: (params) => (
              // <FaRegEye
              //   className="cursor-pointer"
              //   style={{ marginTop: "14px" }}
              //   onClick={() => handleView(params.row.id)}
              // />
              <SingleOrderModel
              orderId={params.row.id}
              visibility={visibility}
              isAdminOrder={"isAdminOrder"}
              setVisibility={setVisibility}
            />
            ),
          },
     ]
 
  return (
    <div className="max-w-[1920px] 1921px:mx-auto px-[1rem] py-2 lg:px-[5rem] lg:py-2">

<DataGrid    
        rows={allOrders} columns={columns} />
    </div>
  )
}

export default Orders;
import { getAllCategories } from "../../../services/operations/cartegoryApi";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "../../ui/skeleton";
import CategoryCard from "./CategoryCard";
import { getByCategories,getAllSubCategories } from "../../../services/operations/cartegoryApi";

const Categories = () => {
  const [loading, setLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getAllSubCategories();
        console.log('mohan',response)
        const filteredItems = response.filter(item => item.subCategoryImage.length > 0);
    
        setCategoriesData(filteredItems);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    fetchCategory()
    fetchSubCategory()
  }, []);
  const fetchCategory=async()=>{
    const response =await getAllCategories()

  }
  const fetchSubCategory=async()=>{
    const response=await getAllSubCategories()
    
  }

  const skeletonCount = 5;

  return (
    <div className="lg:flex lg:flex-col hidden lg:px-[5rem] xl:px-[5rem] w-full py-6 h-[420px]  max-w-[1920px] mx-auto overflow-x-auto">
  <h1 className="font-orator text-[#6C3516] items-center text-4xl 1441px:mt-3 leading-60 flex justify-center">
    <p className="font-orator text-[40px] mb-1 ">S</p>
    <p className="font-orator space-x-0 tracking-tighter text-[33px] mr-2 ">HOP BY CATEGORIES</p>
  </h1>
  <div className="flex gap-9 1441px:gap-x-[105px] mt-[21px] items-center justify-between">
    {loading || categoriesData?.length < 1
      ? Array.from({ length: skeletonCount }).map((_, index) => (
        <Skeleton
          key={index}
          className="w-[230px] h-[263px] 1441px:w-[248px] 1441px:h-[261px] 1441px:mt-3 rounded-sm bg-[#ebc4a3]"
        />
      ))
      : categoriesData?.map((item, index) => (
        <CategoryCard key={index} data={item} />
      ))}
  </div>
</div>

  );
};

export default Categories;

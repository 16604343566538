// import { useState, useEffect } from "react";
// import { Button } from "../../components/ui/button";
// import {
//   getAddressById,
//   editAddressById,
//   createAddress,
//   getAllAddress,
// } from "../../services/operations/addressApi";
// import {
//   Dialog,
//   DialogContent,
//   DialogHeader,
//   DialogTitle,
//   DialogTrigger,
// } from "../../components/ui/dialog";
// import { useSelector } from "react-redux";
// import toast from "react-hot-toast";

// const AddressModel = ({
//   allAddressData,
//   setAllAddressData,
//   editTag,
//   addressId,
//   disabled
// }) => {
//   const { token, user } = useSelector((state) => state.auth);
//   const [landMark, setLandMark] = useState("");
//   const [street, setStreet] = useState("");
//   const [city, setCity] = useState("");
//   const [state, setState] = useState("");
//   const [addressLoading, setAddressLoading] = useState(false);
//   const [open, setOpen] = useState(false);

//   const userId = user?.userId;

//   const addAddress = async (e) => {
//     e.preventDefault();
//     if (!landMark || !street || !city || !state) {
//       toast.error("All fields are required.");
//       return;
//     }
//     setAddressLoading(true);
//     try {
//       if (addressId) {
//         await editAddressById(landMark, street, city, state, addressId, token);
//         toast.success("Address Edited Successfully");
//       } else {
//         await createAddress(landMark, street, city, state, token, userId);
//         toast.success("Address Added Successfully");
//         const updatedAddressData = await getAllAddress(userId, token);
//         setAllAddressData(updatedAddressData);
//         resetForm();
//         setOpen(false);
//       }
//       const updatedAddressData = await getAllAddress(userId, token);
//       setAllAddressData(updatedAddressData);
//       setOpen(false);
//       resetForm();
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setAddressLoading(false);
//     }
//   };

//   const resetForm = () => {
//     setLandMark("");
//     setStreet("");
//     setCity("");
//     setState("");
//   };

//   useEffect(() => {
//     if (addressId && open) {
//       const fetchAddressData = async () => {
//         try {
//           const response = await getAddressById(addressId, token);
//           console.log(response,'addrr')
//           setLandMark(response.landmark);
//           setStreet(response.street);
//           setCity(response.city);
//           setState(response.state);
//         } catch (error) {
//           console.error("Failed to fetch address data:", error);
//         }
//       };
//       fetchAddressData();
//     } else if (!addressId) {
//       resetForm();
//     }
//   }, [addressId, open]);

//   return (
//     <Dialog disabled={disabled} className={""} open={open} onOpenChange={setOpen}>
//       <DialogTrigger asChild>
//         <Button
//           onClick={() => setOpen(true)}
//           className={` ${editTag === "Edit" ? `font-prompt w-[100px]  text-[18px] text-[#000000] rounded border border-[#4A4848]`:"bg-rgb(217 217 217) border-none hover:bg-transparent shadow-none"}`}
//           variant="outline"
//         >
//           {editTag}
//         </Button>
//       </DialogTrigger>
//       <DialogContent className="sm:max-w-[425px]">
//         <DialogHeader>
//           <DialogTitle className="text-[#000000] font-Nunito bold text-[24px]">
//             {addressId ? "Edit Address" : "Add Address"}
//           </DialogTitle>
//           <form onSubmit={addAddress} className="space-y-4">
//             <div className="flex justify-between gap-3">
//               <div className="flex flex-col flex-1">
//                 <label className="font-semibold text-base text-gray-700 mb-4">
//                   LandMark
//                 </label>
//                 <input
//                   type="text"
//                   value={landMark}
//                   onChange={(e) => setLandMark(e.target.value)}
//                   className="rounded-full bg-gray-200 p-2 placeholder-gray-400 pl-4"
//                   placeholder="Enter Land Mark"
//                 />
//               </div>
//             </div>
//             <div className="flex flex-col flex-1">
//               <label className="font-semibold text-base text-gray-700 mb-4">
//                 Street
//               </label>
//               <input
//                 type="text"
//                 value={street}
//                 onChange={(e) => setStreet(e.target.value)}
//                 className="rounded-full bg-gray-200 p-2 placeholder-gray-400 pl-4"
//                 placeholder="Street"
//               />
//             </div>
//             <div className="flex flex-col flex-1">
//               <label className="font-semibold text-base text-gray-700 mb-4">
//                 City
//               </label>
//               <input
//                 type="text"
//                 value={city}
//                 onChange={(e) => setCity(e.target.value)}
//                 className="rounded-full bg-gray-200 p-2 placeholder-gray-400 pl-4"
//                 placeholder="Enter City"
//               />
//             </div>
//             <div className="flex flex-col flex-1">
//               <label className="font-semibold text-base text-gray-700 mb-4">
//                 State
//               </label>
//               <input
//                 type="text"
//                 value={state}
//                 onChange={(e) => setState(e.target.value)}
//                 className="rounded-full bg-gray-200 p-2 placeholder-gray-400 pl-4"
//                 placeholder="Enter State"
//               />
//             </div>
//             <button
//               type="submit"
//               className={`py-3 text-white rounded-lg px-7 bg-[#56243B]`}

//               onClick={() => setOpen(false)}
//             >
//               {addressLoading ? "Submitting..." : "Submit"}
//             </button>
//           </form>
//         </DialogHeader>
//       </DialogContent>
//     </Dialog>
//   );
// };

// export default AddressModel;

import React, { useState, useEffect } from "react";
import { Button } from "../../components/ui/button";
import {
  getAddressById,
  editAddressById,
  createAddress,
  getAllAddress,
} from "../../services/operations/addressApi";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { FaPencil } from "react-icons/fa6";
import { useLocation } from "react-router-dom";

const AddressModel = ({
  allAddressData,
  setAllAddressData,
  editTag,
  addressId,
  disabled,
  fetchData,
  setHideRemoveBtn
}) => {
  const { token, user } = useSelector((state) => state.auth);
  const [landMark, setLandMark] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [addressLoading, setAddressLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errors, setErrors] = useState({});
  const [typingField, setTypingField] = useState(null);
  const [landmarkPlaceHolder, setLandMarkPlaceHolder] = useState("LandMark");
  const [streetPlaceHolder, setStreetPlaceHolder] = useState("Street");
  const [cityPlaceHolder, setCityPlaceHolder] = useState("City");
  const [statePlaceHolder, setStatePlaceHolder] = useState("State");
  const [pincodePlaceHolder, setPincodePlaceHolder] = useState("Pincode");
  const location = useLocation();

  const { pathname } = location;

  const userId = user?.userId;
  const handleChange = (setValue, value, field) => {
    setValue(value);
    setTypingField(field);
    setErrors({ [setValue]: "" });
  };

  
  const addAddress = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    setErrors(validationErrors);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setAddressLoading(true);
    try {
      if (addressId) {
        await editAddressById(
          landMark,
          street,
          city,
          pincode,
          state,
          addressId,
          token
        );
        toast.success("Address Updated Successfully");
        setOpen(false);
      } else {
       
        await createAddress(
          landMark,
          street,
          city,
          pincode,
          state,
          token,
          userId
        );
        // if(pathname !== "/cart") {
        //   window.location.reload();
        // }
      
        const updatedAddressData = await getAllAddress(userId, token);
        setAllAddressData(updatedAddressData);
        
        setOpen(false);
      }
      const updatedAddressData = await getAllAddress(userId, token);
      setAllAddressData(updatedAddressData);
      setOpen(false);
      resetForm();
    } catch (error) {
      console.error(error);
    } finally {
      setAddressLoading(false);
    }
  };

  const resetForm = () => {
    setLandMark("");
    setStreet("");
    setCity("");
    setState("");
    setPincode("")
    setErrors({});
  };

  const validateForm = () => {
    const errors = {};
    if (!landMark.trim()) {
      errors.landMark = "LandMark is required";
      return errors;
    }
    if (!street.trim()) {
      errors.street = "Street is required";
      return errors;
    }
    if (!city.trim()) {
      errors.city = "City is required";
      return errors;
    } else if (!/^[a-zA-Z\s]*$/.test(city.trim())) {
      errors.city = "City should not contain numbers";
      return errors;
    }
    if (!state.trim()) {
      errors.state = "State is required";
      return errors;
    } else if (!/^[a-zA-Z\s]*$/.test(state.trim())) {
      errors.state = "State should not contain numbers";
      return errors;
    }
    const pincodeRegex = /^[0-9]{6}$/;
    if (!pincode.trim()) {
      errors.pincode = "Pincode is required";
      return errors;
    } else if (!pincodeRegex.test(pincode)) {
      errors.pincode =
        "Pincode must be exactly 6 digits long and should contain only Numbers";
      return errors;
    }
    return errors;
  };

  useEffect(() => {
    if (addressId && open) {
      const fetchAddressData = async () => {
        try {
          const response = await getAddressById(addressId, token);
        
          setLandMark(response.landmark);
          setStreet(response.street);
          setCity(response.city);
          setPincode(response.pincode);
          setState(response.state);
        } catch (error) {
          console.error("Failed to fetch address data:", error);
        }
      };
      fetchAddressData();
    } else if (!addressId) {
      resetForm();
    }
  }, [addressId, open]);
  const handleBlur = (placeholderSetter, value, initialValue) => {
    if (!value) {
      placeholderSetter(initialValue);
    }
  };
  const handleFocus = (placeholderSetter) => {
    placeholderSetter("");
  };

  return (
    <Dialog disabled={disabled} open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <button
                

          onClick={() => setOpen(true)}
          className={` ${editTag === "Edit"
              ? ` ${pathname === "/cart"
                ? "border border-slate-400  hover:bg-slate-400 hover:text-white px-4"
                : ""
              } rounded whitespace-nowrap flex items-center mx-auto justify-center gap-x-2`
              : "bg-rgb(217 217 217) border-none hover:bg-transparent shadow-none sm:p-1"
            } `}
        > 
          {editTag === "EditAddress" ? (
  <div className="flex mr-9">
     <FaPencil fontSize={12} />
  </div>
) : (
  editTag
)}
         
        </button>
      </DialogTrigger>
      <DialogContent className="max-w-[350px] sm:max-w-[425px] max-h-[560px] ">
        <DialogHeader>
          <DialogTitle className="text-[#000000] font-helvetica  font-bold text-[24px]">
            {addressId ? "Edit Address" : "Add Address"}
          </DialogTitle>
          <form onSubmit={addAddress} className="space-y-2">

            <div className="flex flex-col flex-1 ">
              <label className="font-semibold text-base text-gray-700 mb-2">
                LandMark <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={landMark}
                maxLength="50"
                onChange={(e) =>
                  handleChange(setLandMark, e.target.value, "landMark")
                }
                className={`rounded-[7px] ${errors.landMark ? "border-red-500" : "border-gray-200"
                  }  border p-2 placeholder-gray-400 pl-4`}
                placeholder={landmarkPlaceHolder}
                onFocus={() => handleFocus(setLandMarkPlaceHolder)}
                onBlur={() =>
                  handleBlur(setLandMarkPlaceHolder, landMark, "LandMark")
                }
              />
              {errors.landMark && (
                <p className="text-red-500 text-xs">{errors.landMark}</p>
              )}
              {typingField === "landMark" && (
                <small className="text-gray-500 self-end">
                  ({landMark.length}/50)
                </small>
              )}

            </div>
            <div className="flex flex-col flex-1">
              <label className="font-semibold text-base text-gray-700 mb-2">
                Street <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={street}
                maxLength="30"
                onChange={(e) =>
                  handleChange(setStreet, e.target.value, "street")
                }
                className={`rounded-[7px] ${errors.street ? "border-red-500" : "border-gray-200"
                  }  border p-2 placeholder-gray-400 pl-4`}
                placeholder={streetPlaceHolder}
                onFocus={() => handleFocus(setStreetPlaceHolder)}
                onBlur={() => handleBlur(setStreetPlaceHolder, street, "Street")}
              />
              {errors.street && (
                <p className="text-red-500 text-xs">{errors.street}</p>
              )}
              {typingField === "street" && (
                <small className="text-gray-500 self-end">
                  ({street.length}/30)
                </small>
              )}
            </div>
            <div className="flex flex-col flex-1">
              <label className="font-semibold text-base text-gray-700 mb-2">
                City <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={city}
                maxLength="30"
                onChange={(e) => handleChange(setCity, e.target.value, "city")}
                className={`rounded-[7px] ${errors.city ? "border-red-500" : "border-gray-200"
                  }  border p-2 placeholder-gray-400 pl-4`}
                placeholder={cityPlaceHolder}
                onFocus={() => handleFocus(setCityPlaceHolder)}
                onBlur={() => handleBlur(setCityPlaceHolder, city, "City")}
              />
              {errors.city && <p className="text-red-500 text-xs">{errors.city}</p>}
              {typingField === "city" && (
                <small className="text-gray-500 self-end">
                  ({city.length}/30)
                </small>
              )}
            </div>
            <div className="flex flex-col flex-1">
              <label className="font-semibold text-base text-gray-700 mb-2">
                Pincode <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={pincode}
                maxLength={6}
                minLength={6}
                onKeyDown={(e) => {
                  const keyCode = e.keyCode || e.which;

                  if (
                    !(
                      (keyCode >= 48 && keyCode <= 57) ||
                      (keyCode >= 96 && keyCode <= 105) ||
                      keyCode === 8 ||
                      keyCode === 9 ||
                      keyCode === 37 ||
                      keyCode === 39 ||
                      keyCode === 46 ||
                      keyCode === 110 ||
                      keyCode === 190
                    )
                  ) {
                    e.preventDefault();
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      pincode: "Pin number must contain only digits",
                    }));
                  } else {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      pincode: "",
                    }));
                  }
                }}




                onChange={(e) =>
                  handleChange(setPincode, e.target.value, "pincode")
                }
                className={`rounded-[7px] ${errors.pincode ? "border-red-500" : "border-gray-200"
                  }  border p-2 placeholder-gray-400 pl-4`}
                placeholder={pincodePlaceHolder}
                onFocus={() => handleFocus(setPincodePlaceHolder)}
                onBlur={() => handleBlur(setPincodePlaceHolder, pincode, "Pincode")}
              />
              {errors.pincode && (
                <p className="text-red-500 text-xs">{errors.pincode}</p>
              )}
            </div>
            <div className="flex flex-col flex-1">
              <label className="font-semibold text-base text-gray-700 mb-2">
                State <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                value={state}
                maxLength="30"
                onChange={(e) =>
                  handleChange(setState, e.target.value, "state")
                }
                className={`rounded-[7px] ${errors.state ? "border-red-500" : "border-gray-200"
                  }  border p-2 placeholder-gray-400 pl-4`}
                placeholder={statePlaceHolder}
                onFocus={() => handleFocus(setStatePlaceHolder)}
                onBlur={() => handleBlur(setStatePlaceHolder, state, "State")}
              />
              {errors.state && (
                <p className="text-red-500 text-xs">{errors.state}</p>
              )}
              {typingField === "state" && (
                <small className="text-gray-500 self-end">
                  ({state.length}/30)
                </small>
              )}
            </div>
            <div className="text-center">
              <button
                type="submit"
                className={`py-3 text-white rounded-lg px-7 bg-[#56243B]`}
              >
                {addressLoading ? "Saving..." : "Save Address"}
              </button>
            </div>
          </form>
        </DialogHeader>
      </DialogContent>
    </Dialog>

  );
};

export default AddressModel;

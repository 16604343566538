import React from "react";
import AboutUsImg01 from "../../images/About-lid-01.png";
import AboutUsImg02 from "../../images/about2-01.png";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ReadMorePage = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
  
    const scrollToSection = (sectionId) => {
      const sectionElement = document.getElementById(sectionId);
      if (sectionElement) {
        sectionElement.scrollIntoView({ behavior: 'smooth' });
  
        
       
      }
    };
  
    if (hash === '#mission') {
      scrollToSection('mission');
    } else if (hash === '#history') {
      scrollToSection('history');
    }else  {
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [location]);
  
  

  return (
    <div>
    <div id="intro"></div>
    
    <div className="w-1920  py-2 sm:px-[5rem] sm:py-2  mt-5 "  >
      <div className="flex  px-[2rem] sm:px-[0rem]  ">
        <div className="flex flex-1 pr-7 pt-7 pb-7 hidden sm:flex">
          <img src={AboutUsImg01} className="w-full h-full" />
        </div>
        <div className="flex flex-1 flex-col mt-10">
          <h1 className="sm:text-[41px] text-black font-helvetica  mb-3 text-[26px] whitespace-nowrap sm:whitespace-wrap">
          The Corporation
          </h1>
          <p className="text-[14px] text-black   text-justify">
          Dr Babu Jagjivan Ram Leather Industries Development Corporation Ltd., was established by the Government of Karnataka in the year 1976 with the paramount objective of comprehensive development of leather industry in the State of Karnataka. 
          </p>
          <p className="text-[14px]  mt-6 text-justify ">
          The righteous objective is to uplift the downtrodden, enhance the socio-economic conditions of scheduled caste Leather Artisans, and aid all classes of people associated with leatherworks from tanners to artisans producing leather goods through an organization that could also be an instrument to showcase and channel the handcrafted products to the markets and connoisseurs of leather goods

          </p>
          <p className="text-[14px]  mt-6 text-justify ">
          The Corporation’s conscientious motive is to duly confer the credit for the creativity and deliver the deserved monetary value and accreditation for the toil that the leatherworkers undertake in the shadows, in poverty and in silence. And today with its incessant efforts paying dividends, the Corporation is world-renowned by its iconic flagship brand LIDKAR.

          </p>
        </div>
      </div>
    
      <div className="h-12" id="history" ></div>
     
      <section className="flex bg-slate-100 p-8  rounded-sm"  >
     
      <div className=""></div>
        <div className="flex flex-1 flex-col">
          <div className="mt-3 flex md:flex-row flex-col ">
            <div className="flex flex-1 flex-col h-fit">
          <h1 className="text-[26px] flex font-helvetica ">
            History of Leather Artisans/ Leather craft
          </h1>
          <p className="text-[14px]  text-black  text-justify">
          Leather Craft in the state of Karnataka takes pride in its riveting history. 
Genesis of one of the most soul-churning, watershed moment in history dates back to 12th century CE. when a poor ardent devotee couple of Lord Shiva, Sri. Haralayya and his wife Smt. Kalyanamma, hailing from the dalit community (today classified as a Scheduled Caste under modern India's system of affirmative action), and an untouchable class of the society called “Chamara or Chamar”, engaged in the trade of shoemaking and leatherworks for a living, resided in the “untouchables” settlement of Kalyana town, Karnataka.

          </p>
          <p className="text-[14px] mt-6 text-black  text-justify">
          In the spiritus mundi of this eon, chamars were a echelons of the society that was bogged down under millennia of cultural & religious boycott, social stigma, neglect, repression and oppression, and many a times, outright exploitation.



          </p>
          
          <p className="text-[14px] mt-6 text-black  text-justify">
         The couple were fervent disciples of Sri. Basavanna, a Hindu religious reformer, teacher, theologian, minister and administrator of the royal treasury of the Kalachuri-dynasty King Bijjala I, who then was vigorously trying to transform oppressive caste-driven society and bring about much-needed social reforms in the populace.





          </p>

          
         
          
            </div>
            <div className="flex flex-1 pr-6 pb-6 pl-6  hidden sm:flex">
          <img src={AboutUsImg02} className="w-full h-[400px]" />
        </div>
          </div>
          <div className="mt-3">
          <p className="text-[14px] text-black  text-justify">
          Sri. Basavanna, in order to eradicate untouchability and break the taboo around Chamaras, heartily visited “untouchables” settlement of Kalyana town and Sri. Haralayya’s house, performed pooja exuberantly and accepted the prasadam (oblation or offering). 
          </p>
            
          <p className="text-[14px] mt-6  text-black  text-justify">
         
         While on his way out, Sri Haralayya greeted Sri. Basavanna simply saying “Sharanu”. To the shock of everyone around, the pious Sri. Basavanna reciprocated the single salutation with ”Sharanu Sharanarthi”, signalling the acceptance of Sri. Haralayya as one of his own kith and kin. On hearing this, the gathered congregation including the entourage of Sri. Basavanna was pleasantly awestruck and Sri. Haralayya, the untouchable shoemaker was dumbstruck, felt profound gratitude on receiving such a courtesy bestowed upon by Sri. Basavanna, the treasurer of the King towards a poor, untouchable dalit Chamara. 
         
                   </p>
                   
                   <p className="text-[14px] mt-6 text-black  text-justify">
                  
                   Howbeit, Sri Haralayya conveyed to his wife that he felt excruciating agony as he had committed a terrible sin, since Sri. Basavanna had reciprocated his single greeting with a double salutation and he had failed to express his profound gratitude for this great act of acceptance and humility by Sri. Basavanna. 
         
                   </p>
            <p className="mt-6 text-justify  text-[14px]">
            Sri. Haralayya and his wife decided thereupon to contrive an extraordinaire offering for Sri. Basavanna. Out of love and to convey their infinite gratitude, they each cut a piece of skin from their thighs, tanned it and handcrafted a beautiful pair of chappals from it. So was the depth of their love, the purity of their devotion and heartfelt thankfulness.





            </p>
            <p className="mt-6 text-justify  text-[14px]">
            Sri. Haralayya then called upon Sri. Basavanna to present him the offering, the chappals made by the couple’s own skin; but Sri. Basavanna stupefied everyone around when he placed the chappals on his head in reverence and announced to the congregation gathered that “the shoes made from a Sharana’s skin, GOD only can wear it” and returned it back with humility
            </p>
            <p className="mt-6 text-justify  text-[14px]">
            These nearly 800-year-old chappals were kept almost intact in a specially-constructed small temple in Bijanalli. About two decades ago, experts from the Karnataka University, Dharwad and the University of Mysore visited Bijanalli, took a small sampling of the footwear for comprehensive investigation and scientific testing and later confirmed that they were indeed made of human skin. 
            </p>
            <p className="mt-6 text-justify  text-[14px]">
            Thus, the fabled legend of Smt. & Sri Haralayya in the folklore has left its true mark in the annals of history of Leather artisans, social justice & equality and culture in the state of Karnataka.
            </p>
            <p> </p>
          </div>
        </div>
      
       
      </section>
      <div className="h-12" id="mission"></div>
      
      <section className="flex bg-slate-100 mt-12 rounded-sm p-8" >
      
        <div className="flex flex-1 flex-col">
          <h1 className="text-[26px] sm:text-[26px] flex font-helvetica ">
            MISSION : Combating the Socio-Economic Adversity & Inequality
          </h1>
          <div className="mt-3">
            <p className="text-[14px] text-black  text-justify">
            The pivotal aim of the corporation is towards the upliftment of the downtrodden and betterment of the socio-economic conditions of scheduled caste Leather Artisans in the Karnataka State, a large chunk of the populace of which is still enduring harrowing adversity and misery within the confines of poverty, illiteracy, lack of bare minimum standard of living. 


            </p>
            <p className="mt-6 text-justify  text-[14px]">
            The Leather Artisans in the state are economically most backward and still engaged in making and selling traditional footwear. Unfortunately, they are still being exploited by private dealers and hence are not receiving the right price for their products or the acknowledgment for the sweat of their brow. Even today, many of the artisans still sit by the road, without proper shelter and are actively engaged in footwear repair for their livelihood and daily sustenance. The income what they earn is barely enough to survive and can be termed, hand to mouth.



            </p>
            <p className="mt-6 text-justify  text-[14px]">
            The Government of Karnataka, during the tenure of the then Hon’ble Chief Minister Sri. Devaraja Urs, bearing in mind the welfare of the SC Leather Artisans had earnestly established the Karnataka Leather Industries Development Corporation Ltd., in the year 1976 and started implementing various Developmental Schemes.


            </p>
            <p className="mt-6 text-justify  text-[14px]">
            The Nomenclature of the corporation was changed as Dr. Babu Jagjivan Ram Leather Industries Development Corporation Ltd., with effect from13th of May, 2010. The Corporation, since its inception was under the Administrative control Commerce and Industries department and was transferred under the purview of Social Welfare Department from 15th of December, 2011. Since then, the corporation has been a beneficiary of ample allocation of funds from the Social Welfare Department and the Corporation is implementing numerous schemes, some solely concentrating on the welfare of the artisan community.

            </p>
            <p className="mt-6 text-justify  text-[14px]">
            Apart from the Developmental Schemes, the Corporation is relentlessly engaged in a myriad of commercial activities to promote leather industry and support leather workers. The genuine leather products made by leather artisans and small scale industries are being marketed and sold under Lidkar’s Brand name, through a network of 15 Lidkar Leather Emporia spread across the breadth of the state and has developed a parallel revenue channel through attaining bulk orders from various Governmental Departments/ Corporations and Private Companies. The Corporation actively undertakes Exhibition cum sales events that are routinely organized in numerous places. Corporation leaves no stone unturned is its mission of extending marketing assistance for the products made by local artisans and the SSI units. 

            </p>
            {/* <p className=" text-[14px]">
              Apart from the Developmental Schemes, the Corporation is
              relentlessly engaged in a myriad of commercial activities to
              promote leather industry and support leather workers. The genuine
              leather products made by leather artisans and small scale
              industries are being marketed and sold under Lidkar’s Brand name,
              through a network of 15 Lidkar Leather Emporia spread across the
              breadth of the state and has developed a parallel revenue channel
              through attaining bulk orders from various Governmental
              Departments/ Corporations and Private Companies. The Corporation
              actively undertakes Exhibition cum sales events that are routinely
              organized in different places. Corporation leaves no stone
              unturned is its mission of extending marketing assistance for the
              products made by local artisans and SSI units.
            </p> */}
          </div>
        </div>
      </section>
    </div>
    </div>
  );
};

export default ReadMorePage;

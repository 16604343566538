import React, { useEffect, useState } from "react";
import { FaRegDotCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  createAddress,
  getAllAddress,
  removeAddress,
} from "../../services/operations/addressApi";
import AddressModel from "../models/AddressModel";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import toast from "react-hot-toast";
import { Button } from "../ui/button";
import PaymentSummary from "./PaymentSummary";
import { Card } from "../ui/card";

const AddressStep = ({
  calculateTotalPrice,
  setCurrentStep,
  handleCheckboxChange,
  addressId,
  isaddressSelected,
  gstPrice,
  sellingPrice
}) => {
 
  const { token, user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState([]);
  const userId = user?.userId;
  const [openEdit, setOpenEdit] = useState(false);
  const [allAddressData, setAllAddressData] = useState([]);
  const [editId, setEditId] = useState();
  const [isHiddenBtn, setIsHiddenBtn] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getAllAddress(userId, token);
        setAllAddressData(response);
       
        response.length === 5 ? setIsHiddenBtn(false) : setIsHiddenBtn(true);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [userId, token]);

  const handleDeleteAddress = async (addressId) => {
    try {
      const response = await getAllAddress(userId, token);
      if (response?.length === 1) {
        toast.error("Atleast one address is Required, cannot be deleted");
        return;
      }
      setAddressLoading(true);
      await removeAddress(addressId, token);
      const response1 = await getAllAddress(userId, token);
      setAllAddressData(response1);
      setAddressLoading(false);
    } catch (error) {
      console.log(error);
      setAddressLoading(false);
    }
  };
  

  const handleEdit = async (addressId) => {
    setOpenEdit(true);
    setEditId(addressId);
  };

  const handleToastPayment = () => {
    toast.error("At least one address is required");
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };
  const handleProceedToPayment= ()=>{
    window.scrollTo({ top: 0, behavior: "smooth" });

  setCurrentStep(3)
      
  }
  return (
    <div className="grid grid-cols-1 w-full xl:grid-cols-2 lg:grid-cols-1 lg:grid-cols-1  lg:gap-16 max-w-[1920px] mx-auto lg:px-[0rem] lg:py-3">
      <div className="flex flex-col flex-1 ">
        <div className="flex justify-center flex-col sm:flex-row sm:justify-between items-center gap-3 lg:gap-16 sm:mb-1 sm:p-2 mt-3 mb-3 lg:mt-0">
          <p className="font-orator text-[#303030] text-[15px] lg:text-[19px]  whitespace-nowrap">
            SELECT DELIVERY ADDRESS
          </p>
          {isHiddenBtn && (
            <div className="flex gap-9 items-center bg-[#D9D9D9] px-2 py-2 rounded">
              <p className="font-prompt text-[12px] lg:text-[14px] text-[#000000]">
                <AddressModel
                  editTag={"Add New Address"}
                  allAddressData={allAddressData}
                  setAllAddressData={setAllAddressData}
                />
              </p>
            </div>
          )}
        </div>

        <div className="lg:h-[401px] lg:w-[550px] p-2 overflow-y-scroll  scrollbar ">
          {allAddressData?.map((address, index) => (
            <div className="flex flex-1 gap-10 justify-between  flex-wrap mb-5">
              <Card className="shadow-none w-full lg:w-[540px] h-fit pb-4">
                <div className="flex justify-between items-center  px-3 pt-3">
                  

                  <div className="font-basicommercial font-bold text-[16px]">
                    {user?.firstName + " " + user?.lastName}
                  </div>
                  <input
                    type="checkbox"
                    value={isaddressSelected}
                    checked={addressId === address.addressId}
                    onChange={(e) => handleCheckboxChange(e, address.addressId)}
                  />
                 
                </div>

                {address.street ||
                address.landmark ||
                address.city ||
                address.state ? (
                  <p key={index} className="text-[#6C6C6C] text-[15px] font-basicommercial px-3 mt-2">
                    {`${address.street} ${address.landmark} ${address.city} ${address.state} ${address.pincode}`}
                  </p>
                ) : null}

                <p className="font-helvetica text-[#6C6C6C] px-3 mt-2">
                  Mobile
                  <span className="font-bold font-basicommercial ml-2">{user?.mobileNumber}</span>
                </p>
                <div className="flex px-3 gap-2 mt-3">
                  <div  className="flex gap-4">
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <Button className="h-7 sm:h-9" variant="destructive">Remove</Button>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>
                            Are you absolutely sure?
                          </AlertDialogTitle>
                          <AlertDialogDescription>
                            This action cannot be undone. This will permanently
                            delete your Address and remove your data from our
                            servers.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction
                            onClick={() => {
                              handleDeleteAddress(address.addressId);
                            }} 
                          >
                            Continue
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>

                    <AddressModel
                      editTag={"Edit"}
                      openEdit={openEdit}
                      allAddressData={allAddressData}
                      addressId={address.addressId}
                      setAllAddressData={setAllAddressData}
                    />
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </div>
      </div>
      <div className="lg:mr-3 flex flex-1  flex-col pt-4 h-fit pb-3 rounded-xl  bg-white lg:mt-[75px]">
        <p className="text-[#03A685] text-[21px]  font-orator whitespace-nowrap px-5 sm:px-9">
          Payment Summary
        </p>
        <PaymentSummary gstPrice={gstPrice} sellingPrice={sellingPrice} calculateTotalPrice={calculateTotalPrice} />
        <div className="mt-3 px-5 sm:px-9">
          {isaddressSelected ? (
            <button
              onClick={handleProceedToPayment}
              className={`bg-[#F9C88F] text-black font-prompt w-fit px-[9px] py-[6px] sm:py-[9px] text-[18px] rounded border border-[#4A4848]`}
            >
              {" "}
              Proceed To Payment
            </button>
          ) : (
            <button
              onClick={handleToastPayment}
              disabled={isButtonDisabled}
              className={`bg-[#F9C88F] text-black font-prompt w-fit px-[9px] py-[6px] sm:py-[9px] text-[18px] rounded `}
            >
              Proceed To Payment
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddressStep;

import React, { useState, useEffect } from "react";
import { TiTick } from "react-icons/ti";
import BagStep from "./BagStep";
import AddressStep from "./AddressStep";
import PaymentStep from "./PaymentStep";
import { createAddress, getAllAddress } from "../../services/operations/addressApi";
import { useSelector } from "react-redux";
import { getCartItemsByUserAndStatusAndProducts } from "../../services/operations/cartItemApi";
import "./Stepper.css"
import LoadingPage from "../../pages/LoadingPage";
import CartEmptyPage from "../Cart/CartEmptyPage";
import toast from "react-hot-toast";

const Stepper = () => {
  const steps = ["Cart", "Address", "Payment"];
  const { token, user } = useSelector((state) => state.auth);
  const [currentStep, setCurrentStep] = useState(1);
  const [complete, setComplete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState([]);
  const [cartDetails, setCartDetails] = useState([]);
  const [cartLoading, setCartLoading] = useState(false);
  const [allAddressData, setAllAddressData] = useState([]);
  const [addressId, setAddressId] = useState(null);
  const [isaddressSelected,setIsAddressSelected] = useState(false)
  const userId = user?.userId
  const [cartDisable,setCartDisable]=useState(false)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
   
    fetchData();
  }, [token]);
  const fetchData = async () => {
    try {
      setCartLoading(true);
      const response = await getCartItemsByUserAndStatusAndProducts(token);
      console.log(response,'varuka')
      setCartDetails(response);
      setCartLoading(false);
    } catch (error) {
      console.log(error);
      setCartLoading(false);
    }
  };

  console.log("cartDetails", cartDetails);

  // const calculateTotalPrice = () => {
  //   let totalPrice = 0;
  //   cartDetails?.forEach((cartItem) => {
  //     totalPrice += cartItem.productDTO.sellingPrice * cartItem.quantity;
  //   });
  //   return totalPrice;
  // };

  const [sellingPrice, setSellngPrice] = useState(null);
  const [gstPrice, setGstPrice] = useState(null);

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    let totalSellingPrice = 0;
    let totalGstAmount = 0;
    cartDetails?.forEach((cartItem) => {
    
      const itemTotal = (cartItem.productDTO.totalPrice) * cartItem.quantity;
      const sellingPriceAll = (Math.round(cartItem.productDTO.sellingPrice)) * cartItem.quantity;
      totalSellingPrice += sellingPriceAll;
      const allGst = (cartItem.productDTO.gstAmount) * cartItem.quantity;
      totalGstAmount += allGst;
      totalPrice += itemTotal;
    });
  
    setSellngPrice(totalSellingPrice);
    setGstPrice(totalGstAmount);
  
    return totalPrice;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getAllAddress(userId, token);
        setAllAddressData(response);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [token]); 

  const handleCheckboxChange = (event, id) => {
   
    if(addressId === id){
      setAddressId(null)
    }else{
      setAddressId(id)
    }
    setIsAddressSelected(event.target.checked)
  };


  const handleStep = (i)=>{
    setCurrentStep(i + 1)

    setTimeout(() => {
      setIsButtonDisabled(true);
      if (addressId == null && i + 1 == 3) {
        setCurrentStep(2);
      }
      setIsButtonDisabled(false);
    },0);
    if(i+1==2){
      setAddressId(null)
      setIsAddressSelected(false)

    }
  }
  // Function to render the current step component
  const renderStepComponent = (stepIndex) => {
    switch (stepIndex) {
      case 1:
        return (
          <BagStep
            token={token}
            setCurrentStep={setCurrentStep}
            cartDetails={cartDetails}
            setCartDetails={setCartDetails}
            cartLoading={cartLoading}
            setCartLoading={setCartLoading}
            calculateTotalPrice={calculateTotalPrice}
            fetchData={fetchData}
            gstPrice={gstPrice}
            sellingPrice={sellingPrice}
          />
        );
      case 2:
        return (
          <AddressStep
            token={token}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            cartDetails={cartDetails}
            setCartDetails={setCartDetails}
            cartLoading={cartLoading}
            setCartLoading={setCartLoading}
            calculateTotalPrice={calculateTotalPrice}
            gstPrice={gstPrice}
            sellingPrice={sellingPrice}
            handleCheckboxChange={handleCheckboxChange}
            addressId={addressId}
            isaddressSelected={isaddressSelected}
          />
        );
      case 3:
        return (
          <PaymentStep
            token={token}
            setCurrentStep={setCurrentStep}
            cartDetails={cartDetails}
            setCartDetails={setCartDetails}
            cartLoading={cartLoading}
            setCartLoading={setCartLoading}
            calculateTotalPrice={calculateTotalPrice}
            gstPrice={gstPrice}
            sellingPrice={sellingPrice}
            addressId={addressId}
          />
        );
      default:
        return null;
    }
  };
  
  return (
    <>
      {cartLoading ? (
        <div className="w-full h-screen mt-60 flex items-center justify-center">
          <LoadingPage />
        </div>
      ) : (
        (!cartDetails || cartDetails.length === 0  ? (
          <CartEmptyPage />
        ) : (
          <div className="max-w-[1920px] w-full mx-auto lg:px-[5rem] lg:py-2 bg-[#f6f1eb]">
            <div className="hidden lg:flex justify-between   mt-12">
              {steps?.map((step, i) => (
                <div
                  key={i}
                  className={`${step === "Cart"? "w-6": "step-item"} ${currentStep === i + 1 && "active"} ${
                    (i + 1 < currentStep || complete) && "complete"
                  } cursor-pointer`}
                >
                  <div className="flex items-center">
                    <button
                    disabled={isButtonDisabled}
                      onClick={() => handleStep(i)}
                      className={`${step == "Address"? "": ""} ${
                        currentStep > i + 1 || currentStep == i + 1
                          ? "text-[#03A685] font-helvetica "
                          : ""
                      } text-[15px]` }
                    >
                      {step}
                    </button>
                    {/* {i < steps.length - 1 && (
                  <div
                    style={{ bg: "black", borderRight: "1px solid red" }}
                  ></div>
                )} */}
                  </div>
                </div>
              ))}
            </div>
            <hr color="#EEEEEE" className="mt-5 mb-5 hidden lg:flex" />
           
            {renderStepComponent(currentStep)}
        
          </div>
        ))
      )
       }
    </>
  );
};

export default Stepper;

import React, { useCallback, useEffect, useState } from "react";
import shoes from "../../images/shoes.png";
import { Swiper, SwiperSlide } from "swiper/react";
import './customCss.css'

import { FaHeart } from 'react-icons/fa';
import MobileSmilarProduct from "../Home/Products/MobileSmilarProduct";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import SingleProductForNewArraivals from "../../components/Home/Products/singleProductForNewArraivals";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { flasSale } from "../../data/categories";
import StarRating from "../StarRating/StarRating";
import { MdCurrencyRupee } from "react-icons/md";
import { CiHeart } from "react-icons/ci";
import { IoBag } from "react-icons/io5";
import Tab from "../Tab/Tab";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip"
import {
  getAllProducts,
  getProductByCategoryId,
  getProductByProductName,
  getProductByProductId,
} from "../../services/operations/productApi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import SingleProduct from "../Home/Products/SingleProduct";
import {
  addItemToCart,
  decrementCartItem,
  getCartItemsByUserAndStatus,
  getCartItemsByUserAndStatusAndProducts,
  incrementCartItem,
} from "../../services/operations/cartItemApi";
import {
  addProductToWishlist,
  getAllWishlistsByUser,
} from "../../services/operations/wishlistApi";
import toast from "react-hot-toast";
import { Button } from "../ui/button";
import LoadingPage from "../../pages/LoadingPage";

import {
  setWishlisData,
  setWishlistCount,
} from "../../redux/slices/wishlistSlice";
import { setCartCount, setCartData } from "../../redux/slices/cartSlice";
import SimilarProducts from "../Home/Products/SimilarProducts";
import Svgdelivery from "../../images/SPV Marketing.png";

const Product = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [imageId, setImageId] = useState(0);
  const [sizeId, setSizeId] = useState(null);
  const [colorId, setColorId] = useState(null);
  const { productName } = useParams();
  // const formattedProductName = productName.replace(/-/g, " ");
  const formattedProductName = productName;
  const [cartLoading, setCartLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [wishlistData1, setWishlistData1] = useState([]);
  const [wishlistLoading, setWishlistLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const { wishlistData } = useSelector((state) => state.wishlist);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const [showPlus, setShowPlus] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [noToken, setNoToken] = useState(false)
  const [cartDisable,setCartDisable]=useState(false)
  const [selectedColor,setSelectedColor]=useState('')
  let inc=0
  const getAllWishlists = async () => {
    try {
      const response = await getAllWishlistsByUser(token);
     
      setWishlistData1(response);
      dispatch(setWishlisData(response));
      dispatch(setWishlistCount(response?.length));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllWishlists();
  }, []);
  const toggleZoom = () => {
    setIsZoomed(prevState => !prevState);
  };
  // const handleMouseMove = (e) => {
  //   const { left, top } = e.currentTarget.getBoundingClientRect();
  //   const x = e.clientX - left;
  //   const y = e.clientY - top;
  //   setPosition({ x, y });
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getProductByProductId(formattedProductName);
        console.log(response,'okio')
        if(!response?.inStock){
           setIsButtonDisabled(true)
        }
        if(response?.colors?.length==1){
          setColorId(response?.colors[0].colorId)
          setSelectedColor(response?.colors[0].colorName)
        }
        if(response?.sizes?.length==1){
          setSizeId(response?.sizes[0].sizeId)
        }
        if(response?.categoryDTO?.categoryName=='Bags' || response?.productId==104 || response?.productId==107 || response?.productId==114 || response?.subSubCategoryName=='Wallet' || response?.subSubCategoryName=='Waist Belts' || response?.subSubCategoryName=='Card Holder'){
        setSizeId(response?.sizes[0].sizeId)
        }


        
     
        setProduct(response);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, [formattedProductName]);

  useEffect(() => {
    setProductsLoading(true);
    const fetchData = async () => {
      try {
        const response = await getAllProducts();
        setProductsData(response?.slice(0, 5));
      } catch (error) {
        console.log(error);
      }
    };
    setProductsLoading(false);
    fetchData();
  }, []);

  const handleImageClick = (index) => {
    setImageId(index);
  };

  const addToWislist = async (productId) => {
    try {
      if (!token) {
        toast.error("Please Login First");
        navigate("/login");
        window.scrollTo(0, 0);
        return;
      }
      const response = await addProductToWishlist(token, productId);
   
      const response1 = await getAllWishlistsByUser(token);
      dispatch(setWishlisData(response1));
      dispatch(setWishlistCount(response1?.length));
    } catch (error) {
      console.log(error);
    }
  };

  const addToCart = async (productId, sizeId, colorId,product) => {
    console.log(sizeId,'ovdi')
    
    if(!sizeId){
      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
      toast.error("Please Select Size ");
      return;
    }
    if(!colorId){
      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
      toast.error("Please Select Color");
      return;
    }
    
    if (!sizeId || !colorId) {
      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
      toast.error("Please Select Size and color");
      return;
    }
    if (!token) {
      setNoToken(true)

      localStorage.setItem('selectedSize', sizeId);
      localStorage.setItem('selectedProduct', productId);
      localStorage.setItem('selectedColor', colorId);
      document.cookie = "cart_visited=true; path=/";
      toast.error("Please Login First");
      navigate("/login");
      window.scrollTo(0, 0);
      return;
    }




    try {
      setCartLoading(true);
      const response = await addItemToCart(productId, sizeId, colorId, token);
      const response1 = await getCartItemsByUserAndStatusAndProducts(token);
     
      dispatch(setCartData(response1));
      dispatch(setCartCount(response1?.length));
      setCartItems((prevItems) => {
        if (!Array.isArray(prevItems)) {
          return [response];
        }
        const existingItem = prevItems.find(
          (item) => item?.productId === productId
        );
        if (existingItem) {
          return prevItems.map((item) =>
            item?.productId === productId ? response : item
          );
        } else {
          return [...prevItems, response];
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  const increaseQuantity = async (cartItemId) => {
    try {
      setCartDisable(true)
      const response = await incrementCartItem(cartItemId, token);
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item.cartItemId === cartItemId ? response : item
        )
      );
      setCartLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
      setTimeout(()=>{
        setCartDisable(false)
      },3000)
    
    }
  };
  const colorMap = {
    "Dark Brown": "#5C4033",
    "Dark Blue": "#00008B",
    "Dark Green": "#354346 ",
    "White": "#FFFFFF",
    "Tan Brown": "#A52A2A",
    "Maroon": "#a3324e"

  };

  const decreaseQuantity = async (cartItemId) => {
    try {
      setCartDisable(true)
     
      const response = await decrementCartItem(cartItemId, token);
      
      if (response.quantity === 1) {

       
          window.location.reload()
        } else {

        setCartItems((prevItems) =>
          prevItems.map((item) =>
            item.cartItemId === cartItemId ? response : item
          )
        );
      }
       setTimeout(()=>{
        setCartDisable(false)
      },3000)
      setCartLoading(true);
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCartLoading(true);
        const response = await getCartItemsByUserAndStatus(token);
        setCartItems(response);
      } catch (error) {
        console.log(error);
      } finally {
        setCartLoading(false);
      }
    };
    fetchData();
  }, [token]);

  const isProductInWishlist = (productId) => {
    return wishlistData?.some((item) =>
      item.products?.some((product) => product?.productId === productId)
    );
  };



  const [categoryData, setCategoryData] = useState([]);
  const getProductsByCategoriyId = async () => {
    try {
      const response = await getProductByCategoryId(product?.categoryId);
     
      setCategoryData(response.products);
    } catch (error) {
      console.log(error);
    }
  };


  const handleMouseMove = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setPosition({ x, y });
  };
  useEffect(() => {
    getProductsByCategoriyId();
  }, [product]);
  const [activeTab, setActiveTab] = useState(null);

  const toggleContent = (tab) => {
    setActiveTab(activeTab === tab ? null : tab);
  };
  const handleToCart = ()=>{
    window.scrollTo({ top: 0, behavior: "smooth" });

    navigate("/cart");
  }
  return wishlistLoading || loading || cartLoading ? (
    <div className="flex inset-0 items-center justify-center mx-auto w-full h-[500px] overflow-x-hidden">
      <LoadingPage />
    </div>
  ) : (
    <div className="max-w-[1920px] 1921px:mx-auto px-[1rem] py-2 lg:px-[5rem] lg:py-2">
      <div className="grid  grid-cols-1  lg:grid-cols-2 lg:gap-4 mt-7 ">

        
        <div className="flex flex-col h-fit gap-6 lg:justify-start items-center lg:items-start">
          <div
            className="relative w-[300px] h-[350px] lg:w-[460px] 1441px:w-[650px] 1441px:h-[500px] lg:h-[420px] flex p-1 justify-center items-center rounded-md overflow-hidden"
            onMouseEnter={() => setShowPlus(true)}
            onMouseLeave={() => {
              setIsZoomed(false);
              setShowPlus(false);
            }}
            onClick={toggleZoom}
            onMouseMove={showPlus ? handleMouseMove : null}
            style={{
              cursor: 'none',
            }}
          >
            <img
              src={product?.productImage[imageId]?.imagePath}
              alt="shoes"
              className="w-[240px] lg:w-[500px] h-[240px] lg:h-[360px] object-cover"
            />

           
            {showPlus && !isZoomed && (
              <div
                className="absolute w-14 h-14 border-[#abb2b9]  border-2  text-[#abb2b9] rounded-full flex justify-center items-center text-3xl pointer-events-none"
                style={{
                  top: `${position.y}px`,
                  left: `${position.x}px`,
                  transform: 'translate(-50%, -50%)',
                }}
              >
              <span className="mb-1">+</span>
              </div>
            )}

           
            {isZoomed && (
              <div
                className="absolute w-20 h-20 border-1 bg rounded-full pointer-events-none"
                style={{
                  top: `${position.y - 40}px`,
                  left: `${position.x - 40}px`,
                  backgroundImage: `url(${product?.productImage[imageId]?.imagePath})`,
                  backgroundSize: '1000px 800px',
                  backgroundPosition: `-${position.x * 2}px -${position.y * 2}px`,
                  transform: 'scale(1.5)',
                  backgroundRepeat: 'no-repeat',
                }}
              ></div>
            )}

          </div>

          <div className="w-[330px] flex justify-center sm:w-[350px] md:w-[430px] mt-12">
            <Swiper
              modules={[Pagination, Navigation]}
              className="ml-0 mr-0"
              navigation={true}
              loopFillGroupWithBlank={true}
             
              slidesPerView={3} 
              spaceBetween={0}
              slidesPerGroup={1}
              
               breakpoints={{
        320: {
          slidesPerView: 3,
        },
        640: {
          slidesPerView: 3,
        },
      }}
            >
              {product?.productImage.map((item, index) => (
                <SwiperSlide key={index} className="ml-0 mr-0" >
                  <div
                    className={`lg:w-[90px] w-[81px] h-[100px] border flex items-center justify-center border-[#E5E5E5] rounded cursor-pointer ${imageId === index ? "border-black" : ""
                      }`}
                    onClick={() => handleImageClick(index)}
                  >
                    <img src={item.imagePath} alt="categoryImage" className="" />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        <div className="flex flex-col h-fit ">
          <p className="font-orator  text-[27px] text-[#303030]">
            {product?.productName}
          </p>
          <div className="flex justify-start items-center gap-3 mb-3">
            <StarRating rating={0} /> <span>(0/5)</span>
            <div className="w-1 h-full border-r border-[#636363] font-basicommercial"></div>
            <div>0 Reviews</div>
          </div>
          <div className="w-full  border-b border-[#636363]"></div>
          <div className="flex">
            <h1 className="flex items-center text-[20px] lg:text-[30px] font-helvetica font-medium  ">
              <MdCurrencyRupee /> {Math.round(product?.sellingPrice)}
            </h1>
            {product?.discount !== 0 && (
              <h3 className="text-red-500 flex items-center font-helvetica ml-1 lg:ml-10 text-[15px] lg:text-[20px] ">
                MRP{" "}
                <span className="flex items-center line-through font-basicommercial">
                  <MdCurrencyRupee />
                  {Math.round(product?.mrpPrice)}
                </span>
              </h3>
            )}
          </div>
          <div className="text-[#03A685] font-basicommercial font-semibold text-[16px]">
            Exclusive of all taxes
          </div>
          <p className="text-[#636363] max-h-fit text-[16px] font-basicommercial font-light">
            {product?.description}
          </p>
          <div className="flex flex-col gap-y-2">
            <div className="flex items-center justify-start gap-[49px] mt-5">
              <h1 className="font-basicommercial font-bold text-[16px] text-[#303030]">
                {" "}
                Stock{" "}
              </h1>
              <p className="text-[#000000] font-light font-prompt text-[20px]">
                {product?.inStock ? (
                  <p className="text-[#03A685] font-semibold font-helvetica">In Stock</p>
                ) : (
                  <p className="text-red-500 font-helvetica">Sold Out</p>
                )}
              </p>
            </div>
            <div className="flex flex-col gap-[10px]">
  <h1 className="font-basicommercial font-bold text-[16px] text-[#303030]">
    Color: {selectedColor}
  </h1>
  <TooltipProvider>
  <div className="flex gap-2 sm:gap-2">
  <Tooltip>
 
    {product?.colors.map((item, index) => (
       <TooltipTrigger>
      <div
        key={index}
        onClick={() => {
          setColorId(item.colorId);
          setSelectedColor(item.colorName);
        }}
      className={`w-8 h-8 p-1 rounded-md cursor-pointer shadow-md ${colorId === item.colorId || product?.colors.length==1 ? "border-2 border-gray-400" : "border   border-white"}`}      >
     <div
  style={{ background: colorMap[item?.colorName] || item?.colorName }}
  className="w-full h-full rounded-md"
></div>
      </div>
      <TooltipContent>{item?.colorName}</TooltipContent>
        
      </TooltipTrigger>
    ))}
  
        
         </Tooltip>
  </div>
  </TooltipProvider>

</div>

{product?.categoryDTO?.categoryName=='Bags' || product?.productId==104 || product?.productId==107 || product?.productId==114 || product?.subSubCategoryName=='Wallet' || product?.subSubCategoryName=='Waist Belts' || product?.subSubCategoryName=='Card Holder'  ? (
<>
</>

):(

  <div className="flex flex-col gap-[10px]">
  <h1 className="font-basicommercial font-bold text-[16px] text-[#303030]">
    {" "}
    Size{" "}
  </h1>
  <div className="flex gap-2 lg:gap-2  ml-[2px] ">
    {product?.sizes
      ?.slice()
      ?.sort((a, b) => a.sizeNumber - b.sizeNumber)
      ?.map((size, index) => (
        <p
          onClick={() => setSizeId(size.sizeId)}
          key={index}
        className={`w-8 h-8 flex outline-none box-border border border-gray-400 rounded-md py-2 shadow-md cursor-pointer items-center justify-center hover:border-gray-700 ${sizeId === size.sizeId || product.sizes.length ==1 ? "bg-black text-white" : "bg-white text-black"}`}
        >
          {size?.sizeNumber}
        </p>
      ))}
  </div>
</div>
)}
         
           
          </div>
          <div className="flex gap-7">
            <div className="flex items-center gap-x-3 mt-5 justify-between">
              {cartItems?.some(
                (item) => item?.productId === product?.productId
              ) ? (
                <div className="flex items-center justify-between  cursor-pointer w-[150px] gap-3 bg-[#F9C88F] text-white px-2 py-[2px] rounded">
                  <Button
                    className="bg-[#F9C88F] shadow-none h-7 sm:h-9 hover:bg-[#F9C88F] text-black"
                    onClick={() =>
                      decreaseQuantity(
                        cartItems?.find(
                          (item) => item?.productId === product?.productId
                        ).cartItemId
                      )
                    }
                     disabled={cartDisable}
                  >
                    -
                  </Button>
                  <span className="text-black">
                    {cartItems?.find(
                      (item) => item?.productId === product?.productId
                    ).quantity === 0
                      ? ""
                      : cartItems?.find(
                        (item) => item?.productId === product?.productId
                      ).quantity}
                  </span>
                  <Button
                    className="bg-[#F9C88F] shadow-none h-7 sm:h-9 hover:bg-[#F9C88F] text-black"
                    onClick={() =>
                      increaseQuantity(
                        cartItems?.find(
                          (item) => item?.productId === product?.productId
                        ).cartItemId
                      )
                    }
                    disabled={cartDisable}
                  >
                    +
                  </Button>
                </div>
              ) : (
                <button
                  disabled={isButtonDisabled}
                  className={`flex h-7 items-center text-[18px] p-1 whitespace-nowrap lg:whitespace-wrap lg:text-[18px] gap-3 px-2 rounded 
                    ${isButtonDisabled ? 'bg-gray-300 cursor-not-allowed text-gray-500' : 'bg-[#F9C88F] text-black cursor-pointer'} 
                    button-hover`}
                  onClick={() => addToCart(product?.productId, sizeId, colorId,product)}
                >
                  <IoBag fontSize={12} /> <span className="font-helvetica text-[12px]">ADD TO CART</span>
                </button>
              )}
              

              {cartItems?.some(
                (item) => item?.productId === product?.productId
              ) && (
                  <div onClick={handleToCart}  className="inline-block ">
                    <button className="flex items-center gap-1 text-[14px] sm:text-base w-fit  bg-[#F9C88F] text-black p-1 sm:p-2 pt-[6px] sm:py-[8px] rounded-md font-helvetica">
                      View Cart
                    </button>
                  </div>
                )}

              {!cartItems?.some(
                (item) => item.productId === product?.productId
              ) && (
                  <button className="border border-black px-2 p-1 rounded-lg  text-black ">
                    {isProductInWishlist(product?.productId) ? (
                      <span
                        className="flex gap-1 items-center justify-center"
                        onClick={() => navigate("/wishlist")}
                      >
                        <span className="text-[12px] font-helvetica  text-black "> VIEW WISHLIST</span> <FaHeart fontSize={16} fill="#E61F7F" />
                      </span>
                    ) : (
                      <span
                        className="flex gap-2 items-center whitespace-nowrap lg:whitespace-wrap justify-center text-[15px] lg:text-[15px]"
                        onClick={() => addToWislist(product?.productId)}
                      >
                        <span className="font-normal text-[12px] font-helvetica "> ADD TO WISHLIST </span>
                        <FaHeart fontSize={14}  />
                      </span>
                    )}
                  </button>
                )}
            </div>
          </div>
          <div className="lg:w-[100%] flex flex-col items-start mt-3">
  <div className="w-full border-t border-gray-300"></div>

  
  <div
    className="flex justify-between w-full items-center p-1 cursor-pointer"
    onClick={() => toggleContent("description")}
  >
    <span className="text-black font-helvetica text-sm uppercase">
      Description
    </span>
    <button className="text-xl text-end font-bold focus:outline-none">
      {activeTab === "description" ? "-" : "+"}
    </button>
  </div>
  <div
    className={`w-full overflow-hidden  ${
      activeTab === "description" ? "p-4 bg-white" : "max-h-0"
    }`}
    style={{
      maxHeight: activeTab === "description" ? "1000px" : "0", // Use maxHeight to ensure transition happens smoothly
    }}
  >
    {activeTab === "description" && (
      <div className="w-full bg-white">
        <div className="text-base flex flex-wrap gap-1">
          {product?.inStock && (
            <div className="flex flex-row p-1">
              <p className="font-helvetica text-sm">In Stock</p>
              <p className="text-sm font-basicommercial">: {product?.inStock ? "Yes" : "No"}</p>
            </div>
          )}
          {product?.skuCode && (
            <div className="flex flex-row p-1">
              <p className="font-helvetica text-sm">SKU Code</p>
              <p className="text-sm font-basicommercial">: {product?.skuCode}</p>
            </div>
          )}
          {product?.hsnCode && (
            <div className="flex flex-row p-1">
              <p className="font-helvetica text-sm">HSN Code</p>
              <p className="text-sm font-basicommercial">: {product?.hsnCode}</p>
            </div>
          )}
        </div>
      </div>
    )}
  </div>

  <div className="w-full border-t border-gray-300"></div>

  {/* Specifications and Features Section */}
  <div
    className="flex w-full justify-between items-center p-1 cursor-pointer"
    onClick={() => toggleContent("additionalinformation")}
  >
    <span className="text-black font-helvetica text-sm uppercase">
      Specifications and Features
    </span>
    <button className="text-xl font-bold focus:outline-none">
      {activeTab === "additionalinformation" ? "-" : "+"}
    </button>
  </div>
  <div
    className={`w-full overflow-hidden  ${
      activeTab === "additionalinformation" ? "p-4 bg-white" : "max-h-0"
    }`}
    style={{
      maxHeight: activeTab === "additionalinformation" ? "1000px" : "0", // Use maxHeight to ensure transition happens smoothly
    }}
  >
    {activeTab === "additionalinformation" && (
      <div className="text-base flex flex-wrap gap-2">
        {product?.types && (
          <div className="flex flex-row p-1">
            <p className="font-helvetica text-sm">Type</p>
            <p className="text-sm font-basicommercial">: {product?.types[0].typeName}</p>
          </div>
        )}
        {product?.lining && (
          <div className="flex flex-row p-1">
            <p className="font-helvetica text-sm">Lining</p>
            <p className="text-sm font-basicommercial">: {product?.lining}</p>
          </div>
        )}
      </div>
    )}
  </div>

  <div className="w-full border-t border-gray-300"></div>

  {/* Dimensions Section */}
  <div
    className="flex justify-between w-full items-center p-1 rounded-md cursor-pointer"
    onClick={() => toggleContent("dimensions")}
  >
    <span className="text-black font-helvetica text-sm uppercase">
      Dimensions
    </span>
    <button className="text-xl font-bold focus:outline-none">
      {activeTab === "dimensions" ? "-" : "+"}
    </button>
  </div>
  <div
    className={`w-full overflow-hidden ${
      activeTab === "dimensions" ? "p-4 bg-white" : "max-h-0"
    }`}
    style={{
      maxHeight: activeTab === "dimensions" ? "1000px" : "0", // Use maxHeight to ensure transition happens smoothly
    }}
  >
    {activeTab === "dimensions" && (
      <div className="text-base flex flex-wrap gap-2">
        {product?.length && (
          <div className="flex flex-row p-1">
            <p className="font-helvetica text-sm">Length</p>
            <p className="text-sm font-basicommercial">: {product?.length} cm</p>
             <p className="text-sm font-basicommercial ml-2 "> ({(product?.length * 0.39).toFixed(2)} Inches)</p>
          </div>
        )}
        {product?.width && (
          <div className="flex flex-row p-1">
            <p className="font-helvetica text-sm">Width</p>
            <p className="text-sm font-basicommercial">: {product?.width} cm</p>
                         <p className="text-sm font-basicommercial ml-2 ">({(product?.width * 0.39).toFixed(2)} Inches)</p>
          </div>
        )}
        {product?.thickness && (
          <div className="flex flex-row p-1">
            <p className="font-helvetica text-sm">Thickness</p>
            <p className="text-sm font-basicommercial">: {product?.thickness} cm</p>
            <p className="text-sm font-basicommercial">: {product?.thickness * 0.39} Inches</p>
          </div>
        )}
      </div>
    )}
  </div>

  {/* Other components below that should not overlap */}
  <div className="hidden xl:w-full xl:flex justify-between flex-col mt-[1rem] ml-[0.5%]">
    <div className="block h-24 sm:w-[70%]">
      <img className="h-[100px]" src={Svgdelivery} alt="" />
    </div>
  </div>
</div>



        </div>
      </div>
      <div className="w-full">
        <Tab product={product} />
      </div>


      <div>
        <h1 className="text-[#303030] text-[30px] text-center lg:text-start lg:text-[40px] mt-5 lg:mt-24 font-orator ">Similar Products</h1>
        <div className="hidden lg:flex flex-col lg:flex-row lg:flex-wrap lg:flex-nowrap justify-start items-center w-1920 1441px:justify-between lg:h-50 gap-9 mt-6">
          {categoryData?.slice(0, 5).map((ele, index) => (
            <SimilarProducts key={index} ele={ele} buttonVisible={false} />
          ))}
        </div>
        <div className="lg:hidden flex flex-col flex-row justify-start items-center  lg:h-50 gap-9 mt-6">
          {categoryData?.slice(0, 5).map((ele, index) => (
            <MobileSmilarProduct key={index} ele={ele} buttonVisible={false} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Product;

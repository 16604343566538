import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";

const ShippingAndReturnTab = () => {
  return (
    <>
      <div className="p-1 transition-all text-sm duration-1000 ease-in-out">
        <ul className="list-disc pl-5 space-y-1">
          <li>
            Goods are shipped within 5-7 business days (Domestic) and 10-12 business days (International) on placement of the order, except for orders placed on Sundays and national holidays, which are processed on the next working day.
          </li>
          <li>
            Duties & Taxes need to be paid by the customer to the shipping company at the time of delivery.
          </li>
          <li>
            <span className="font-semibold">Important Note:</span> Goods once sold will not be taken back or exchanged
          </li>
          <li>
            For more information, please visit our <a href="/terms-conditions" className="text-blue-500 hover:underline">Terms & Conditions</a> page.
          </li>
        </ul>
      </div>
    </>
  );
};

export default ShippingAndReturnTab;

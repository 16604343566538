import CountdownTimer from "../../../helpers/CountdownTimer";
import React, { useEffect, useState } from "react";
import { IoIosFlash } from "react-icons/io";
import { SkeletonCard } from "../../Skeletoncard";
import { getAllProducts } from "../../../services/operations/productApi";
import SingleProduct from "../Products/SingleProduct";
import { getCartItemsByUserAndStatus } from "../../../services/operations/cartItemApi";
import { useSelector } from "react-redux";
import SingleProductForNewArraivals from "../Products/singleProductForNewArraivals"
const NewArrival = () => {
  const isBtn = true;
  const [loading, setLoading] = useState(true);
  const [productsData, setProductsData] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };
 useEffect(()=>{

 
  // Add event listener for resizing the window
  window.addEventListener("resize", handleResize);

  // Cleanup on unmount
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

  // Determine the number of items to show based on screen size
  let sliceAmount = 4; // Default for 'xl' and larger
  if (screenWidth > 873 && screenWidth < 1280) {
    sliceAmount = 3; // For 'lg' screens (less than 1024px)
  }else if(screenWidth < 600){
    sliceAmount = 4
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getAllProducts();
        const data = [...response].sort(
          (a, b) => new Date(b.productCreatedAt) - new Date(a.productCreatedAt)
        );
        setProductsData(data.slice(41, 45));
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const skeletonCount = 5;

  return (
    <div className="max-w-[1920px] mx-auto px-4 xl:px-[4.5rem] sm:px-20 py-3">
  <div className="flex  items-center justify-center mt-5 p-1">

    <h1 className="font-orator text-[#6C3516] items-center text-4xl 1441px:mt-3 mb-3 leading-60 flex justify-center">
    <p className="font-orator text-[40px] mb-1 ">N</p>
    <p className="font-orator space-x-0 tracking-tighter text-[33px] mr-2 ">EW ARRIVALS</p>
  </h1>
    {/* <div className="ml-3">
      <CountdownTimer />
    </div> */}
  </div>
  <div className="flex w-full  gap-3 flex-wrap lg:flex-wrap justify-center sm:justify-between  xl:justify-between xl:gap-1" >
    {loading || productsData.length < 1
      ? Array.from({ length: skeletonCount }).map((_, index) => (
          <div key={index} className="w-[calc(20%-1rem)]">
            <SkeletonCard
              circle={true}
              isAllProducts={false}
              height="h-[150px]"
              width="w-full"
              className="rounded-sm bg-[#ebc4a3]"
            />
          </div>
        ))
      : 
      (
      
      productsData?.slice(0,sliceAmount)?.map((ele, index) => (
           
            <SingleProductForNewArraivals ele={ele} buttonVisible={true} />
           
         
        )))}
      
  </div>
</div>

  );
};

export default NewArrival;


import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../ui/card";
import { Button } from "../../ui/button";
import { forgotPassword } from "../../../services/operations/authApi";

const ForgotPassword = () => {
  const [emailId, setEmailId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showValidEmail, setShowValidEmail] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);

  const emailRegex = /^[\w-]+(?:\.[\w-]+)*@(?:[a-zA-Z0-9]+\.)+(?:com|in)$/;

  const handleSendVerifyEmail = async () => {
    setShowEmailError(false);
    setShowValidEmail(false);
    setEmailFailed(false); // Reset the email failed state

    if (!emailId.trim()) {
      setShowEmailError(true);
      setIsButtonDisabled(true);
      setTimeout(() => setIsButtonDisabled(false), 3000);
      return;
    }

    if (!emailRegex.test(emailId)) {
      setShowValidEmail(true);
      setIsButtonDisabled(true);
      setTimeout(() => setIsButtonDisabled(false), 3000);
      return;
    }

    try {
      setLoading(true);
      const result = await forgotPassword(emailId);

      if (result) {
        setEmailSent(true);
        setTimeout(() => setEmailSent(false), 3000);
        setEmailId(""); 
      }
    } catch (error) {
      if (error.message === "Request failed with status code 500") {
        setEmailFailed(true);
        setTimeout(() => setEmailFailed(false), 3000); 
      }
      console.error("Error sending email:", error);
    } finally {
      setLoading(false);
      setIsButtonDisabled(false);
    }
  };

  return (
    <div className="flex items-center justify-center mt-8 mb-2 relative">
      <Card className="w-full sm:w-[45%] lg:w-[35%] h-full">
        <CardHeader className="flex flex-col items-center justify-center">
          <CardTitle className="font-semibold text-center text-xl mb-1 text-black-700">
            Forgot Password?
          </CardTitle>
          <CardDescription className="font-Barlow text-center">
            Enter your email and we'll send you a link to reset your password.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <input
            className="w-full p-3 border rounded-md focus:ring-0 outline-none"
            onChange={(e) => setEmailId(e.target.value)}
            type="email"
            name="emailId"
            placeholder="Enter Your Email"
            value={emailId}
            disabled={loading}
          />
        </CardContent>
        {showEmailError && (
          <div className="text-red-500 text-start pl-6 -mt-6">
            Email should not be empty
          </div>
        )}
        {showValidEmail && (
          <div className="text-red-500 text-start pl-6 -mt-6">
            Email is Invalid, Please Enter Valid Email
          </div>
        )}
        {/* <CardFooter className="w-full"> */}
        <div className="flex justify-center items-center">

      
          <Button
            disabled={isButtonDisabled || loading}
            onClick={handleSendVerifyEmail}
            className="w-fit bg-[#3d1e17] text-center"
          >
            {loading ? "Sending..." : "Send Email"}
          </Button>
          </div>
        {/* </CardFooter> */}
      </Card>

      {emailSent && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-lg text-center">
            <p className="text-lg font-semibold">Email sent successfully!</p>
            <Button
              onClick={() => setEmailSent(false)}
              className="mt-4 bg-[#3d1e17] text-white"
            >
              Close
            </Button>
          </div>
        </div>
      )}
      {emailFailed && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-lg text-center">
            <p className="text-lg font-semibold">Email not present!</p>
            <Button
              onClick={() => setEmailFailed(false)}
              className="mt-4 bg-[#3d1e17] text-white"
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;

import React from "react";
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { HomeSlider } from "../../static/bannerItems";
import { bannerItems } from "../../static/bannerItems";

const Hero = () => {
  return (
    <div className="flex max-w-[1920px] mx-auto flex-col w-full">
     <div className="relative w-full sm:h-auto h-[180px] mx-auto">
  <Fade
    duration={1000}
    transitionDuration={1000}
    infinite
    autoplay
    pauseOnHover={false}
    arrows={false}
  >
    {HomeSlider.map((slide, index) => (
      <div className="each-fade" key={index}>
        <div className="image-container">
          <img
            src={slide.images}
            alt={`product-${slide.id}`}
            className="w-full h-[180px] sm:h-[510px]  object-center"
          />
        </div>
      </div>
    ))}
  </Fade>
</div>



      <div className="w-full hidden gap-[21px] mt-9 sm:hidden md:hidden lg:hidden xl:flex flex-col items-center justify-between mx-auto max-w-[1920px] px-[5rem] py-2">
        <div className="w-full flex items-center justify-between gap-[opx]">
          {bannerItems.map((item, index) => (
           
              <div className="flex items-center gap-8"  key={index}>
                <img className="w-10 h-10 object-contain -mr-4" src={item.logo} alt={item.id} />
               {item.title === "SECURE PAYMENT" ? (
    <div className="w-[90px]">
        <p className="text-[12px] font-medium">{item.title}</p>
    </div>
) : item.title === "AUTHENTICITY 100% Genuine Leather" ? (
    <div className="w-[121px] flex flex-col">
        <p className="text-[12px] font-medium">AUTHENTICITY</p>
            <p className="text-[12px] font-medium">100% Genuine Leather</p>
    </div>
) : (
    <div className="w-[114px]">
        <p className="text-[12px] font-medium">{item.title}</p>
    </div>
)}
               
              </div>
                    ))}
        </div>
        <div className="w-full max-w-[1190px] 1441px:max-w-[1395px] h-[1px]  mb-2 bg-[#303030]"></div>
      </div>
    </div>
  );
};

export default Hero;

import React from "react";
import wishlist from "../../images/wishlist.png";
import { Link } from "react-router-dom";
const WhistListEmpty = () => {
  return (
    <div className="w-1920 sm:px-[5rem] py-1 flex justify-center items-center flex-col gap-3">
      <p className="font-prompt text-[21px] font-semi-bold text-[black] ">
        YOUR WISHLIST IS EMPTY
      </p>
      <p className="text-[#514C4C] font-prompt text-[15px] w-[300px] sm:w-[470px] text-center">
        Add items that you like to your wishlist. Review them anytime and easily
        move them to the bag.
      </p>
      <img src={wishlist} alt="wishlist" className="w-[100px] h-[100px]" />
      <Link
        to="/"
        className="text-base border border-[#E61F7F] font-bold px-7 py-2"
      >
        CONTINUE SHOPPING
      </Link>
    </div>
  );
};

export default WhistListEmpty;

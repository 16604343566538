import React, { useEffect, useState } from "react";
import AllProductPage from "../components/AllProductPage/AllProductPage";
import AllFilteredProducts from "../components/AllProductPage/AllFilteredProducts";
import { getAllCategories } from "../services/operations/cartegoryApi";
import { getALLProductByCategoryId, getALLProductBySubCategoryId, getALLProductBySubSubCategoryId, getAllProducts, getMenAllProducts, getUniSexAllProducts, getWomenAllProducts } from "../services/operations/productApi";
import { getAllColors } from "../services/operations/colorApi";
import { useSelector } from "react-redux";
import { GET_ALL_CLUTCHES_AND_WALLETS,GETALLCHAPPALS } from "../services/operations/productApi";
import { useLocation } from "react-router-dom";
import { getProductByCategoryId } from "../services/operations/productApi";
import toast from "react-hot-toast";

const ProductPage = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const subCategoryId = location.state?.subCategoryId;
  const id = location.state?.id
  const clutch=location.state?.clutches
  const allChappals=location.state?.allChappals
  // const subCategoryId = queryParams.get("subCategoryId");
  const subSubCategoryId = location.state?.subSubCategoryId

  const menId = location.state?.menId
  const allId=location.state?.allId
  console.log(allId,'vgs')

  const womenId = location.state?.womenId
  const unisexId = location.state?.unisexId
  const specialPrice = location.state?.specialPriceId;




  const [categoriesData, setCategoriesData] = useState([]);
  const [productsData, setProductsData] = useState([]);
  const [colorsData, setColorsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [subCategoryLoading, setSubCategoryLoading] = useState(false);
  const [productLoading, setProductLoading] = useState(false);
  const [colorLoading, setColorLoading] = useState(false);
  const [subCategorieData, setSubCategorieData] = useState([])
  const [cid, setCid] = useState(id)
  const { token } = useSelector((state) => state.auth);




  useEffect(() => {
    const fetchData = async () => {
      try {
        setCategoryLoading(true);
        const response = await getAllCategories();

        setCategoriesData(response);
        setCategoryLoading(false);
      } catch (error) {
        console.log(error);
        setCategoryLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setCid(id)
      try {
        setLoading(true);
        if (id) {
          const categoryProducts = await getProductByCategoryId(id, token);

          setProductsData(categoryProducts?.products);
        } else if (subCategoryId) {

          const subCategoryProduct = await getALLProductBySubCategoryId(id, token);



          setProductsData(subCategoryProduct?.products)

        } else if (subSubCategoryId) {

          const subSubCategoryProduct = await getALLProductBySubSubCategoryId(id, token);


          setProductsData(subSubCategoryProduct?.products)

        } else if (menId) {

          const subSubCategoryProduct = await getMenAllProducts(token);


          setProductsData(subSubCategoryProduct?.products)

        } else if (womenId) {
          const subSubCategoryProduct = await getWomenAllProducts(token);

          setProductsData(subSubCategoryProduct?.products)
        } else if (unisexId) {
          const subSubCategoryProduct = await getUniSexAllProducts(token);


          setProductsData(subSubCategoryProduct?.products)
        } else if(allId){
          console.log('vgsking')
          const response = await getAllProducts();


          const excludeProductIds = [45, 47, 56, 54, 89, 99, 62];


          const filteredProducts = response.filter(product => !excludeProductIds.includes(product.productId));

          setProductsData(filteredProducts)
        }else if(clutch){
         const response=await GET_ALL_CLUTCHES_AND_WALLETS()
         setProductsData(response?.products)
        }else if(allChappals){
          const response =await GETALLCHAPPALS()
          setProductsData(response?.products)
        }
        else {
          const response = await getAllProducts();


          const excludeProductIds = [45, 47, 56, 54, 89, 99, 62];


          const filteredProducts = response.filter(product => !excludeProductIds.includes(product.productId));

          setProductsData(filteredProducts)
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, menId, womenId, unisexId,allId]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setColorLoading(true);
        const response = await getAllColors(token);
        setColorsData(response);
        setColorLoading(false);
      } catch (error) {
        console.log(error);
        setColorLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <AllFilteredProducts
        colorsData={colorsData}
        loading={loading}
        categoriesData={categoriesData}
        productsData={productsData}
        setProductsData={setProductsData}
        cid={cid}
        menId={menId}
        womenId={womenId}
        unisexId={unisexId}
        specialPrice={specialPrice}
        setLoading={setLoading}
        subCategoryId={subCategoryId}
        subSubCategoryId={subSubCategoryId}
        categoryLoading={categoryLoading}
        subCategoryLoading={subCategoryLoading}
        productLoading={productLoading}
        setCategoryLoading={setCategoryLoading}
        setSubCategoryLoading={setSubCategoryLoading}
        setProductLoading={setProductLoading}
        setColorLoading={setColorLoading}
        colorLoading={colorLoading}
        allId={allId}
        clutch={clutch}
        allChappals={allChappals}


      />
    </div>
  );
};

export default ProductPage;

import React from 'react'
import { FaJediOrder } from 'react-icons/fa'

const OrderEmptyPage = () => {
  return (
    <div>
      <FaJediOrder />
      No Orders Found
    </div>
  )
}

export default OrderEmptyPage
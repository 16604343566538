import { getAllSubCategories } from "../../../services/operations/cartegoryApi";
import React, { useEffect, useState, useRef } from "react";
import { Skeleton } from "../../ui/skeleton";
import { useNavigate } from "react-router-dom";

const MobileCategories = () => {
  const [loading, setLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const sliderRef = useRef(null); // Reference to the slider

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getAllSubCategories();
        const filteredItems = response.filter(
          (item) => item?.subCategoryImage?.length > 0
        );

        setCategoriesData(filteredItems);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  // Automatic scrolling with setInterval
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        (prevIndex + 1) % categoriesData.length
      );
      if (sliderRef.current) {
        sliderRef.current.scrollTo({
          left: ((currentIndex + 1) % categoriesData.length) * 160, // Adjust based on item width
          behavior: "smooth",
        });
      }
    }, 3000); // Change slide every 3 seconds

    return () => clearInterval(interval);
  }, [categoriesData, currentIndex]);

  // Touch events for manual scrolling
  const handleTouchStart = (e) => {
    const touchStartX = e.touches[0].clientX;
    sliderRef.current.startX = touchStartX;
  };

  const handleTouchMove = (e) => {
    if (!sliderRef.current.startX) return;

    const touchMoveX = e.touches[0].clientX;
    const moveDiff = sliderRef.current.startX - touchMoveX;
    sliderRef.current.scrollLeft += moveDiff;
  };

  const handleCategories = (id) => {
    navigate('/shop/products', { state: { subCategoryId: id } });

  };

  const skeletonCount = 5;

  return (
    <div className="flex  lg:hidden flex-col">

       <h1 className="font-orator text-[#6C3516] items-center text-xl sm:text-4xl py-3 flex justify-center">
    <p className="font-orator text-[30px] sm:text-[40px] mb-1 ">S</p>
    <p className="font-orator space-x-0 tracking-tighter text-[27px] sm:text-[33px] mr-2 ">HOP BY CATEGORIES</p>
  </h1>

    <div
      className="flex  lg:hidden px-0 w-full  overflow-hidden relative"
      ref={sliderRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
    >
     
      {/* Container with automatic and manual horizontal scrolling */}
      <ul className="flex items-start justify-start h-[250px] space-x-4">
        {loading || categoriesData?.length < 1
          ? Array.from({ length: skeletonCount }).map((_, index) => (
              <Skeleton
                key={index}
                className="w-[230px] h-[263px]   rounded-sm bg-[#ebc4a3]"
              />
            ))
          : categoriesData.map((data, index) => (
              <li
                key={index}
                onClick={() => {
                  handleCategories(data?.subCategoryId);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                className="w-[160px] h-[200px] flex flex-col items-center mx-3"
              >
                <img
                  src={data?.subCategoryImage[0]?.imagePath}
                  className="hover:scale-110 w-full h-full object-cover transition duration-300 cursor-pointer"
                  alt={data?.categoryName}
                />
                <p className="font-prompt font-medium text-base mt-2 whitespace-nowrap">
                  {data?.subCategoryName}
                </p>
              </li>
            ))}
      </ul>
    </div>
    </div>
  );
};

export default MobileCategories;

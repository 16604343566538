import React, { useEffect, useRef, useState } from "react";
import logo01 from "../images/logo.png";
import { useForm, SubmitHandler } from "react-hook-form";
import { CiHeart, CiShoppingCart } from "react-icons/ci";
import { IoMdHeartEmpty } from "react-icons/io";
import { RiAccountCircleLine } from "react-icons/ri";

import { FaRegHeart } from "react-icons/fa";

import LikarLogo from "../images/logowhitelidkar.png"
import { FaRegUser } from "react-icons/fa6";
import { IoBagOutline } from "react-icons/io5";
import { FiShoppingBag } from "react-icons/fi";
import { AiOutlineLogin } from "react-icons/ai";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { SlLocationPin } from "react-icons/sl";
import { MdOutlineAccountCircle } from "react-icons/md";
import { FaArrowRightFromBracket, FaCartShopping } from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";
import NavbarBag from "../images/Navabarbag.png";
import navbarChappels from "../images/navbarChappels.png"
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import { navItems } from "../static/navItems";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CircleUser, MapPin } from "lucide-react";
import TopBar from "../components/Home/TopBar";
import { useDispatch, useSelector } from "react-redux";
import SignupModel from "../components/models/SigninModel";
import { CiLogin } from "react-icons/ci";
import shoes from "../../src/images/shoes.png";
import { BsBoxArrowInUpRight, BsCart2 } from "react-icons/bs";
import {
  getProductBySubCategoryId,
  searchByProductName,
} from "../services/operations/productApi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Topbanner01 from "../../src/images/Topbanner-01.jpg";
import Topbanner02 from "../../src/images/Topbanner-02.jpg";
import { MdOutlineChevronRight } from "react-icons/md";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../components/ui/alert-dialog";
import { getUserById } from "../services/operations/authApi";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import {
  getCartItemsByUserAndStatus,
  getCartItemsByUserAndStatusAndProducts,
} from "../services/operations/cartItemApi";
import { getAllWishlistsByUser } from "../services/operations/wishlistApi";
import {
  setWishlisData,
  setWishlistCount,
} from "../redux/slices/wishlistSlice";
import { setCartData, setCartCount } from "../redux/slices/cartSlice";
import { IoMdContact } from "react-icons/io";
import accountIcon from "../../src/images/Lidkar Icons-04.png"
import heartIcon from "../../src/images/Lidkar Icons-03.png";
import SearchIcon from "../../src/images/Lidkar Icons-02.png"
import CartIcon from "../../src/images/cartnew-01.png"
import CartIconItem from "../../src/images/cartnew-02.png";
const Navbar = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const { wishlistCount } = useSelector((state) => state.wishlist);
  const { cartData, cartCount } = useSelector((state) => state.cart);
  console.log("cart",cartCount)
  const location = useLocation();
  const [activeItem, setActiveItem] = useState(0);
  const [active, setActive] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchProducts, setSearchProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState("");
  const [hoveredItem, setHoveredItem] = useState(null);
  const [userData, setUserData] = useState({});
  const [touchedSearchbar, setTouchedSearchbar] = useState(false);
  const [wishlistData, setWishlistData] = useState([]);
  const [cartDetails, setCartDetails] = useState([]);
  const [cartLoading, setCartLoading] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 70) {
      setActive(true);
    } else {
      setActive(false);
    }
  });

  const handleMouseEnter = () => {
    setShowPopup(true);
  };
  const handleMouseEnterForMObile = ()=>{
    setShowPopup(!showPopup);
      
  }
  const handleProfileMouseLeave = ()=>{
    setShowPopup(false);
     
  }

  const handleCategoryClick = (category) => {
    if (category === "About Us") {
      handleNavigation('/about-us');
    }
    else if (category === "History") {
      handleNavigation('/about-us#history');
    } else if (category === "Mission") {
      handleNavigation('/about-us#mission');
    }else if(category === "Brand LIDKAR"){
      handleNavigation("/lidkarBrand")
    }
  };
  const handleMouseLeave = () => {
    setShowDropdown(false);
    setShowPopup(false);
  };
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    localStorage.removeItem("selectedSize")
    localStorage.removeItem("selectedProduct")
    localStorage.removeItem("selectedColor")

    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/login");
    window.location.reload();
  };
  const handleFocus = () => {
    setIsDropdownOpen(true);
    setTouchedSearchbar(true);
  };
  const handleSearchView = () => setIsSearchVisible(!isSearchVisible);
  const handleBlur = () => {
    setIsDropdownOpen(false);
    setTouchedSearchbar(false);
  };

  const handleSearchByProductName = async () => {
    try {
      setLoading(true);
      const response = await searchByProductName(product, token);
      console.log("searchProduct", response)
      setSearchProducts(response.slice(0, 4));
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const clearSearchProducts = () => setSearchProducts([]);
    clearSearchProducts();
    if (product) {
      setIsDropdownOpen(true);
      handleSearchByProductName();
    } else {
      setProduct("");
      const clearSearchProducts = () => setSearchProducts([]);
      clearSearchProducts();
    }
  }, [product]);

  const navigateToSingleProductPage = (product, subCategoryId) => {
    console.log(subCategoryId, 'oop')

    setIsDropdownOpen(false);
    const clearSearchProducts = () => setSearchProducts([]);
    clearSearchProducts();
    setProduct("");
    navigate(`/shop/products/${product}` );
   
    
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleProducts = (id) => {
    console.log("id", id);
    setHoveredItem(null)
    
    navigate('/shop/products', { state: {id: id } });
  };

  const handleSubCategoryProducts = (id,sub) => {
    console.log("vikasSubsubsubsubsubsubsubsub", id ,sub);
    if(sub === "subCategorie"){

     
      navigate('/shop/products', { state: { subCategoryId: id } });
      return
    }else if(sub === "subSubCategorie"){
  
    navigate('/shop/products', { state: { subSubCategoryId: id } });
    return

    }
  };
  const handleSubCategory = (id)=>{
   
    navigate('/shop/products', { state: { subCategoryId: id } });

  }
  const handleSubSubCategory = (id)=>{
   
    navigate('/shop/products', { state: { subSubCategoryId: id } });

  }
  const handleCategory = (id)=>{
   
    navigate('/shop/products', { state: { id: id } });

  }
  const  handleAllMenProduct = () => {
      setHoveredItem(null)
    // navigate(`/shop/products?allM=${100}`);
    navigate('/shop/products', { state: { menId:100 } });
  };
  const handleAllWomenProduct = ()=>{
    setHoveredItem(null)
    navigate('/shop/products', { state: { womenId:200 } });
  
     
  }
  const handleAllUnisexProduct = ()=>{
    setHoveredItem(null)

    navigate('/shop/products', { state: {unisexId:300 } });
      
  }
  const handleAllCategorie = ()=>{
      setHoveredItem(null)
      navigate(`/shop/products`,{state:{allId:'all'}});
  }
  
  // const handleCategories = (id)=>{
  //   navigate(`/shop/category/getAllCategories=${id}`);
  // }

  useEffect(() => {
    fetchUser();
  }, [token, user]);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  const sidebarRef = useRef(null);
  const closeButtonRef = useRef(null);

  const fetchUser = async () => {
    const response = await getUserById(user?.userId);
    setUserData(response);
  };

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef);

  function useOutsideClick(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsDropdownOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const handleSearchClose=()=>{
    setIsSearchVisible(false)
    setProduct('')
  }
  // need to verify
  const getAllCartItems = async () => {
    try {
      if (token) {
        setCartLoading(true);
        const response = await getCartItemsByUserAndStatusAndProducts(token);
        setCartDetails(response);
        dispatch(setCartData(response));
        dispatch(setCartCount(response.length));
        setCartLoading(false);
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      setCartLoading(false);
    }
  };
const navigateToHome=()=>{
  window.location.reload(
    
  )
navigate('/')
}
const handleClutchesAndWallets=()=>{
  navigate('/shop/products',{state:{clutches:'clutch'}})
   }

  const getAllWishlists = async () => {
    try {
      if (token) {
        const response = await getAllWishlistsByUser(token);
        console.log("Wishlist", response);
        setWishlistData(response);
        dispatch(setWishlisData(response));
        dispatch(setWishlistCount(response?.length));
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllWishlists();
    getAllCartItems();
  }, [token, dispatch]);

  const getProductDetailsBySubCategoryId = async (subCategoryId) => {
    try {
      const response = await getProductBySubCategoryId(subCategoryId);
      console.log("response", response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductDetailsBySubCategoryId(1);
  }, []);

  const leatherGoods = {
    150: {
      "Light Weight Bags": [
         { id: 6, name: "View All" },
        { id: 6, name: "Handbags" },
        { id: 14, name: "Clutch Purse" },
        { id: 3, name: "Passport Holder" },
        { id: 22, name: "Wallets" },
        { id: 34, name: "Waist Belt" },
        { id: 6, name: "Spectacle Case" },
        { id: 28, name: "Card Holders" },
        { id: 18, name: "Coin Purse" },
        { id: 33, name: "Key Chain" },
        { id: 21, name: "Pen Stand" },
      ],
      "Heavy Weight Bags": [
           { id: 8, name: "View All" },
        { id: 8, name: "Trolley Bags" },
        { id: 3, name: "Briefcase" },
        { id: 2, name: "Travel Bags" },
        { id: 1, name: "Laptop Bags" },
      ],
    },
  };

  const accessories = {
    151: {
      // "Shoe Accessories": [
      //   { id: 1, name: "View All" },
      //   { id: 1, name: "Socks" },
      //   { id: 2, name: "Polish" },
      //   { id: 3, name: "Brush" },
      //   { id: 4, name: "In Socks" },
      //   { id: 5, name: "Shoe Horns" },
      //   { id: 5, name: "Dog Shoe" },
      // ],
      "Men's Accessories": [
         { id:9, name: "View All" ,sub:"subCategorie"},
        { id: 18, name: "Wallets", sub:"subSubCategorie" },
        {id:  19, name: "Card Holders", sub:"subSubCategorie"},
        {id:  20, name: "Waist Belts", sub:"subSubCategorie"}
      ],
      "Women's Accessories": [
        { id:14, name: "View All" ,sub:"subCategorie"},
       { id: 27, name: "Clutch Purses", sub:"subSubCategorie" },
    
     ],
      "General Accessories": [
         { id:10, name: "View All" ,sub:"subCategorie"},
        { id: 22, name: "Pen Stand", sub:"subSubCategorie" },
        { id: 21, name: "Key Chain", sub:"subSubCategorie" },
        { id: 23, name: "Big Coin Purse", sub:"subSubCategorie" },
      ],
    },
  };

  const leatherFootware = {
    152: {
      "Men's Footwear": [
         {id: 1, name:"View All", sub:"subCategorie"},
        { id: 1, name: "Shoes", sub:"subSubCategorie" },
        { id: 2, name: "Chappals", sub:"subSubCategorie" },
        { id: 3, name: "Sandals",sub:"subSubCategorie" },
        { id: 4, name: "Kolhapuri (GI tag)", sub:"subSubCategorie" },
      ],
      "Women's Footwear": [
         {id: 3, name:"View All", sub:"subCategorie"},
        { id: 6, name: "Shoes", sub:"subSubCategorie" },
        { id: 7, name: "Chappals", sub:"subSubCategorie" },
        { id: 8, name: "Sandals", sub:"subSubCategorie" },
        { id: 9, name: "Kolhapuri (GI tag)", sub:"subSubCategorie" },
      ],
      "Kid's Footwear": [
        {id:4, name:"View All", sub:"subCategorie"},
        { id: 10, name: "Shoes" ,sub:"subSubCategorie"},
        // { id: 10, name: "Chappals", sub:"subSubCategorie" },
      ],
    },
  };
  const leatherBags = {
    155: {
      "Men's Bag": [
        { id: 5, name: "View All", sub: "subCategorie" },
        { id: 11, name: "Shoulder Bags", sub: "subSubCategorie" },
        { id: 12, name: "Gents Pouch", sub: "subSubCategorie" },
       
      ],
      "Women's Bag": [
        { id: 6, name: "View All", sub: "subCategorie" },
        { id: 13, name: "Handbags", sub: "subSubCategorie" },
        { id: 14, name: "Sling Bags", sub: "subSubCategorie" },
        // { id: 27, name: "Clutch Purses", sub: "subSubCategorie" },
        // { id: 26, name: "Shantiniketan Bags", sub: "subSubCategorie" },
      ],
      
    },
  };
  
  const HOME = {
    154: {
      "About Us": [],
      "History": [],
      "Mission": [],
      "Brand LIDKAR": [],
    },
  };
   
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
   const [mobileActiveItem, setMobileActiveItem] = useState(0);
   const [mobileHoveredCategory, setMobileHoveredCategory] = useState(null);
   const [showMobileDropdown,setShowMobileDropDown] = useState(false);
   const inputRef = useRef(null);
  const [dropdownWidth, setDropdownWidth] = useState('100%');

  useEffect(() => {
    const updateDropdownWidth = () => {
      if (inputRef.current) {
        setDropdownWidth(`${inputRef.current.offsetWidth}px`);
      }
    };

    updateDropdownWidth();

    window.addEventListener('resize', updateDropdownWidth);

    return () => {
      window.removeEventListener('resize', updateDropdownWidth);
    };
  }, []);
  const handleMouseEnter1 = (index,category) => {
    setHoveredCategory(index);
    
     switch (category) {
      case "Kid's Footwear":
        setDropdownPosition({ top: 60, left: 199 }); // Adjust these values as needed

        setShowDropdown(true);
        break;
      case "Men's Footwear":
        setDropdownPosition({ top: -9, left: 199 }); // Adjust these values as needed

        setShowDropdown(true);
        break;
      case "Women's Footwear":
        setDropdownPosition({ top: 27, left: 199 }); // Adjust these values as needed

        setShowDropdown(true);
        break;
      case "Men's Bag":
        setDropdownPosition({ top: -9, left: 150 }); // Adjust these values as needed

        setShowDropdown(true);
        break;
      case "Women's Bag":
        setDropdownPosition({ top: 27, left: 150 }); // Adjust these values as needed

        setShowDropdown(true);
        break;
        case "Shoe Accessories":
        setDropdownPosition({ top: -9, left: 209 }); // Adjust these values as needed
        setShowDropdown(true);
           break;
        case "Men's Accessories":
setDropdownPosition({ top: -9, left: 209 }); // Adjust these values as needed
        setShowDropdown(true);
           break;
           case "Women's Accessories":
            setDropdownPosition({ top: 27, left: 209 }); // Adjust these values as needed
                    setShowDropdown(true);
                       break;
        case "General Accessories":
setDropdownPosition({ top: 65, left: 209 }); // Adjust these values as needed
        setShowDropdown(true);
           break;
        case "Light Weight Bags":
        setDropdownPosition({ top: -9, left: 179 }); // Adjust these values as needed
        setShowDropdown(true);
           break;
        case "Heavy Weight Bags":
        setDropdownPosition({ top: 25, left: 179 }); // Adjust these values as needed
        setShowDropdown(true);
           break;
      default:
        setDropdownPosition({});
    }
  };
  const handleMobileClick = (index,category)=>{
    console.log("subCatg",index)
    if(mobileHoveredCategory === index){
         setMobileHoveredCategory(null)

    }else{
          setMobileHoveredCategory(index)
    }
    
    
       
   
      setShowMobileDropDown(true); 
    
  }
   const handleItemClick = (index) => {
    console.log("categorie",index)
  
    if (mobileActiveItem === index) {
      setMobileActiveItem(null);
    } else {
      setMobileActiveItem(index); 
    }
  };
  const [dropdownPosition, setDropdownPosition] = useState({ top: 28, left: 120 });

  const handleMouseLeave1 = () => {
    setHoveredCategory(null);
    setShowDropdown(false);
  };
 
 
  
  const handleNavigation = (path) => {
    window.scrollTo(0, 0);
    navigate(path);
  };
  const handleProfile=()=>{
    navigate('/accountDetails/1')
    setShowPopup(false)
  }
 

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target) && !closeButtonRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);


  return (
    <div className="max-w-[1920px] 1921px:mx-auto">
      <div className="flex flex-col bg-[#F7EFEF]">
      <div className="sm:flex sm:flex lg:flex xl:hidden flex gap-5 justify-between items-center text-white px-[1rem] py-3 relative ">
  {isOpen && (
   <div
   className="fixed inset-0 bg-black/60 z-20"
   onClick={toggleDrawer}
 ></div>
 
  )}
  <GiHamburgerMenu
    className="text-yellow-950 text-[21px] mt-3 cursor-pointer z-30"
    onClick={toggleDrawer}
  />
  <Link to={"/"}>
    <img src={logo01} alt="logo" className="h-12 md:16 sm:ml-[50px] z-30" />
  </Link>

  {isOpen && (
    <div ref={sidebarRef}>
     
      <div
        className={`fixed top-0 left-0 h-full w-[75%] md:w-[50%] lg:w-[36%] bg-white shadow-lg z-30 transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out overflow-y-auto`}
      >               
              <div className="h-[90px] bg-yellow-950 relative">
                <div className="flex  items-center ">
                <div className="">
                    <img className="w-[100px] h-[60px] m-3" src={LikarLogo} />
                  </div>
                  <div
        className="absolute top-6 right-6 bg-white w-[18px] h-[18px] rounded-full text-black flex justify-center items-center  text-gray-500 z-50 focus:outline-none"
        onClick={toggleDrawer}
        ref={closeButtonRef}
      >
        <IoMdClose />
      </div>
                  {/* <div className="flex gap-5 mt-6 p-3 justify-center items-center">

                    <p>sign in</p>
                    <FaRegUser />
                  </div> */}
                </div>

              </div>
              <div className="flex flex-col justify-start items-start px-[1rem] py-2 gap-6">
                {navItems.map((item, index) => (
            <li
              key={index}
              className={`relative   flex-col cursor-pointer active flex justify-start items-start `}
               onClick={() => handleItemClick(index)}
                 
            >
              <Link
                className={`flex justify-center text-[14px] items-center  hover:text-[#6c3516]   text-[#414141] font-helvetica
                  
            `}
                to={item.path}
              >
              
                {item.icon != null ?

                 (<div className={`flex items-center  gap-3 justify-center ${item.title === 'HOME' ? 'mb-1' : ''}`}>
                 <div>  {item.title} </div>
                 <div> {mobileActiveItem === index ? <MdKeyboardArrowUp /> : item.icon}</div>
                </div>):(
                  <div>{item?.title}</div>
                )

                }


              </Link>

             
              {index === 0 && mobileActiveItem === index && (
            <div className="mt-2  bg-white ">
              <ul className="flex flex-col">
                {Object.keys(HOME[154]).map((category, categoryIndex) => (
                  <li
                    key={categoryIndex}
                    className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141] cursor-pointer"
                    onClick={() => {
                      handleCategoryClick(category);
                      toggleDrawer(); 
                    }}

                  >
                    <p className="flex items-start">{category}</p>
                  </li>
                ))}
              </ul>
            </div>
          )}
                 {index === 1 && mobileActiveItem === index && (
                <div
                  className=" mt-2 white border"
                  onMouseLeave={() => setHoveredItem(null)}
                  onMouseEnter={() => setHoveredItem(index)}
                >
                <div className="flex gap-[0px] font-helvetica ">
                <div className="py-3">

                 <h1 className="text-[16px] px-1 ml-1 text-[#7D502D] ">Categories</h1>

                  <ul className="mt-4" onClick={()=> toggleDrawer()}>

                    <li
                      onClick={() =>  handleAllCategorie()}
                      className="px-2 text-[14px] py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                     All
                    </li>
                    <li
                      onClick={() => handleProducts(1)}
                      className="px-2 text-[14px] py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                       Footware
                    </li>
                    <li
                      onClick={() => handleProducts(2)}
                      className="px-2 text-[14px] py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                       Bags
                    </li>
                    <li
                      onClick={() => handleProducts(7)}
                      className="px-2 text-[14px] py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                       Jackets
                    </li>
                    <li
                      onClick={() => handleProducts(4)}
                      className="px-2 text-[14px] py-[5px] whitespace-nowrap hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Unique Collections
                    </li>
                    <li
                      onClick={() => handleProducts(3)}
                      className="px-2 py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Accessories
                    </li>
                    
                    <li
                      // onClick={() => handleSubCategoryProducts(50)}
                      className="px-2 text-[14px] py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Furniture
                    </li>
                    <li
                      // onClick={() => handleSubCategoryProducts(50)}
                      className="px-2 text-[14px] py-[5px] whitespace-nowrap hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Musical Instruments
                    </li>
                                     </ul>
                                     </div>
                                     <div className=" py-3">

<h1 className="text-[16px] px-3 text-[#7D502D] ml-[5px]">Gender</h1>

 <ul className="mt-3">

   <li
     onClick={() => handleAllMenProduct()}
     className="px-4 text-[14px] py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
   >
   Men
   </li>
   <li
     onClick={() => handleAllWomenProduct()}
     className="px-4 text-[14px] py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
   >
      Women
   </li>
   <li
     onClick={() => handleAllUnisexProduct()}
     className="px-4 text-[14px] py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
   >
   Unisex
   </li>
 
                    </ul>
                    </div>
                 
                   

                </div>
                </div>
              )}
                   {index === 2 && mobileActiveItem === index && (
                <div
                  className=" mt-2 "
                 
                >
                  <ul className="flex flex-col " >
                    {Object.keys(leatherFootware[152]).map((category, index01) => (
                      <li
                        key={index}
                        className="px-4 py-2 w-[160px]  font-helvetica text-[#414141] cursor-pointer"
                       
                        onClick={(event)=>{
                         event.stopPropagation();
                         handleMobileClick(index01,category);
                       }}
                        
                      >
                        <p className="flex items-center justify-between w-[180px]">
                          {" "}
                          <span>{category} </span> {mobileHoveredCategory === index01 ? <MdOutlineKeyboardArrowDown className="ml-2" /> : <MdOutlineChevronRight className="ml-2" />}
                          {/*mobileHoveredCategory === index ? <MdKeyboardArrowUp /> : item.icon*/}
                        </p>
                        <hr className="text-black" />


  {mobileHoveredCategory === index01 &&(
                    <div
                      className=" mt-2 w-[160px]"
                            
                    >
                      <ul className="flex flex-wrap flex-col">
                        {Object.keys(leatherFootware[152]).map(
                          (category, index03) =>
                            index03 === mobileHoveredCategory &&
                            leatherFootware[152][category].map(
                              (subcategory, subIndex) => (
                                <li
                                  key={subIndex}
                                  onClick={() =>{
                                    handleSubCategoryProducts(subcategory.id, subcategory.sub);
                                    toggleDrawer()
                                  }
                                  }
                                  className="px-4 py-2 text-nowrap flex gap-3 hover:bg-[#FFF5EC] font-helvetica text-[#414141]  cursor-pointer border-l-2 border-[#414141]"
                                >
                                  {subcategory.name}
                                </li>
                              )
                            )
                        )}
                      </ul>
                    </div>
                  )}
                        </li>
                    ))}
                  </ul>

                </div>

              )}
                    {index === 3 && mobileActiveItem === index && (
                <div
                  className=" mt-2 w-48"
                  // onMouseLeave={() => setHoveredItem(null)}
                  // onMouseEnter={() => setHoveredItem(index)}
                  
                >
                   <ul className="flex flex-col">
                    {Object.keys(leatherBags[155]).map((category, index01) => (
                      <li
                        key={index}
                        className="px-4 py-2 font-helvetica text-[#414141] cursor-pointer"
                        onClick={(event)=>{
                          event.stopPropagation();
                          handleMobileClick(index01,category);
                        }}
                      >
                        <p className="flex items-center justify-between">
                          {" "}
                          <span>{category} </span> {mobileHoveredCategory === index01 ? <MdOutlineKeyboardArrowDown className="ml-2" /> : <MdOutlineChevronRight className="ml-2" />}
                        </p>
                        <hr className="text-black"/>
                        {mobileHoveredCategory === index01 && (
                           <div
                           className="mt-2 w-[175px]"
                          
                         >
                           <ul className="flex flex-wrap flex-col">
                             {/* <li
                              onClick={() =>
                                         handleSubCategoryProducts(4)
                                       } 
                                       className="px-4 py-2 flex gap-3 hover:bg-[#FFF5EC] font-helvetica text-[#414141]  cursor-pointer">View All</li> */}
                             {Object.keys(leatherBags[155]).map(
                               (category, index03) =>
                                 index03 === mobileHoveredCategory &&
                               leatherBags[155][category].map(
                                   (subcategory, subIndex) => (
                                     <li
                                       key={subIndex}
                                       onClick={() =>{
                                         handleSubCategoryProducts(subcategory.id, subcategory.sub);
                                         toggleDrawer()
                                       }
                                       }
                                       className="px-4 py-2 flex gap-3 font-helvetica text-[#414141]  cursor-pointer"
                                     >
                                       {subcategory.name}
                                     </li>
                                   )
                                 )
                             )}
                           </ul>
                         </div>
                        )

                        }
                      </li>
                    ))}
                     <p className="flex items-center justify-between px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141] cursor-pointer" 
                      onClick={() =>{ handleSubCategory(7)

                        toggleDrawer()
                      }
                      }
                     
                     >
                          {" "}
                          <span >Laptop Bags</span> 
                        </p>
                     <p className="flex items-center justify-between px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141] cursor-pointer"
                      onClick={() => 
                      {
                        handleSubCategory(8)
                        toggleDrawer()
                      }}
                     
                     >
                          {" "}
                          <span>Travel Bags</span> 
                        </p>
                  </ul>
                </div>
              )}

                    {index === 4 && mobileActiveItem === 4 && (
                <div
                  className="mt-2 w-[210px] bg-white "
                // onMouseLeave={() => setHoveredItem(null)}
                // onMouseEnter={() => setHoveredItem(index)}
                >
                  <ul className="flex flex-col">
                    {Object.keys(accessories[151]).map((category, index01) => (
                      <li
                        key={index}
                        className="px-4 py-2 font-helvetica  text-[#414141] cursor-pointer"
                        onClick={(event)=>{
                          event.stopPropagation();
                          handleMobileClick(index01,category);
                        }}
                      >
                        <p className="flex items-center justify-between">
                          {/* {" "} */}
                          <span className="whitespace-nowrap">{category}</span> {mobileHoveredCategory === index01 ? <MdOutlineKeyboardArrowDown className="ml-2" /> : <MdOutlineChevronRight className="ml-2" />}
                        </p>
                    

                  {mobileHoveredCategory === index01 && (
                    <div
                      className= "w-[180px] mt-2 "
                      // onMouseEnter={() => setShowDropdown(true)}
                      // onMouseLeave={handleMouseLeave1}
                         // onClick={()=> handleMobileClick(index01,category)}
                    >

                      <ul className="flex flex-wrap flex-col">

                        {Object.keys(accessories[151]).map(
                          (category, index03) =>
                            index03 === mobileHoveredCategory &&
                            accessories[151][category].map(
                              (subcategory, subIndex) => (
                                <li
                                  key={subIndex}
                                  onClick={() =>{
                                    handleSubCategoryProducts(subcategory.id, subcategory.sub);
                                    toggleDrawer()
                                  }
                                  }
                                  className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]  cursor-pointer"
                                >
                                  {subcategory.name}
                                </li>
                              )
                            )
                        )}
                      </ul>
                    </div>
                  )}
                    </li>
                    ))}
                  </ul>
                </div>
              )}

                       {index === 5 && mobileActiveItem === 5 && (
                <div
                  className=" mt-2 w-48 bg-white "
                  onMouseLeave={() => setHoveredItem(null)}
                  onMouseEnter={() => setHoveredItem(index)}
                >
<ul>
<li
                      onClick={() => {handleCategory(4);
                        toggleDrawer()

                      }}
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                    View All
                    </li>
                    <li
                      onClick={() => {handleSubSubCategory(26);
                        toggleDrawer()

                      }}
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Shantiniketan Bags
                    </li>

                 {/*   <li
                      onClick={() => handleSubCategoryProducts(29)}
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Pupptes-wall Hanging/Lightings
                    </li>
*/}
                    <li
                      onClick={() => {handleSubSubCategory(25);
                        toggleDrawer()
                      }

                      }
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141] whitespace-nowrap"
                    >
                      Kolhapuri Chappals (GI tag)
                    </li>
                  </ul>

                </div>
              )}

             {/* {index === 6 && mobileActiveItem === 6 && (
                <div
                  className="mt-2 w-48 bg-white"
                  onMouseLeave={() => setHoveredItem(null)}
                  onMouseEnter={() => setHoveredItem(index)}
                >
                  <ul>
                    <li
                      onClick={() => handleSubCategoryProducts(31)}
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Men
                    </li>
                    <li
                      onClick={() => handleSubCategoryProducts(31)}
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Women
                    </li>
                    <li
                      onClick={() => handleSubCategoryProducts(31)}
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Kids
                    </li>
                  </ul>
                </div>
              )} */}


              </li>
              ))}
              </div>

            </div>
            </div>

          )}

          <div className="flex gap-[15px] items-center sm:gap-6">
            {token ? (
              <>
                <div className="relative cursor-pointer">
                  <Link
                    to="/wishlist"
                    className="text-black flex justify-center items-center gap-1"
                  >
                  <img src={heartIcon} className="w-[1.4rem] h-[1.4rem] ml-1"/>

                    {wishlistCount !== 0 && (
                      <span className="absolute bg-yellow-950 w-4 h-4 flex items-center justify-center text-[10px] rounded-full left-4 top-3 text-white">
                        {wishlistCount}
                      </span>
                    )}
                    
                  </Link>
                </div>
                <Link to="/cart">
                  <div className="flex relative justify-center items-center gap-2">
                  {cartCount == 0 && (

<img src={CartIcon} className="w-[1.4rem] h-[1.4rem]"/>)
}

{cartCount !== 0 && (
  <div className="relative" >
    
  <img src={CartIconItem} className="w-[1.4rem] h-[1.4rem]"/>
  <span className="absolute bg-yellow-950 w-4 h-4 flex items-center justify-center text-[10px] rounded-full left-4 top-3 text-white">
    {cartCount}
  </span>
  </div>
)}
                      
                  </div>
                </Link>
              
                  <div
                    className="flex justify-center items-center gap-2"
                    onClick={handleMouseEnterForMObile}
                  >
 <img src={accountIcon} className="w-[1.5rem] h-[1.5rem]"/>
                    
                    {/*<p className="text-black">{user?.firstName}</p>*/}
                  </div>
               
                {showPopup && (
                  <div
                    className="absolute top-[81px] right-[33px] rounded shadow-lg bg-white  w-fit  h-fit z-50 cursor-pointer"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleProfileMouseLeave}
                  >
                    {/* <h1 className="text-black font-semibold px-6 mt-3">
                    {userData?.firstName} {userData?.lastName}
                  </h1>
                  <p className="text-[#414141] font-semibold mb-3 px-6">
                    {userData?.mobileNumber}
                  </p> */}
                    {/* <hr color="black" /> */}
                    {/* <Link to={"/my-account"}>
                    <p className="text-black px-3 py-3 text-[15px] font-helvetica flex justify-center gap-6 items-center mb-1">
                      Account <MdOutlineAccountCircle fontSize={30} />
                    </p>
                  </Link> */}
                    {/* <hr color="black" /> */}
                    <div className="hover:bg-[#f5f7fa]">
                      <Link
                        to={`/accountDetails/1`}
                        onClick={()=>setShowPopup(false)}
                        className="text-black px-6 py-3 text-[15px] font-helvetica flex justify-start gap-x-4 items-center mb-1"
                      >
                        <p>My Profile</p>
                        <img src={accountIcon} className="w-[1.4rem] h-[1.4rem] mt-1"/>
                      </Link>
                      {/* <hr color="black" /> */}
                    </div>
                    <div className="hover:bg-[#f5f7fa]">
                      <Link
                        to={`/accountDetails/2`}
                        onClick={()=>setShowPopup(false)}
                        className="text-black px-6 py-3 text-[15px] font-helvetica gap-x-5 flex justify-start items-center mb-1"
                      >
                        <p>Address</p>
                        <SlLocationPin fontSize={21} className="ml-2" />
                      </Link>
                      {/* <hr color="black" /> */}
                    </div>
                    <div className="hover:bg-[#f5f7fa]">
                      <Link
                        to={`/accountDetails/3`}
                        onClick={()=>setShowPopup(false)}
                        className="text-black px-6 py-3 text-[15px] font-helvetica flex justify-start gap-x-4 items-center mb-1"
                      >
                        <p>My Orders</p>
                        <BsCart2 fontSize={21} className="" />
                      </Link>
                      {/* <hr color="black" /> */}
                    </div>

                   

                   

                    <div className="hover:bg-[#f5f7fa]">
                      <AlertDialog>
                        <AlertDialogTrigger asChild>
                          <p className="text-black px-6 py-3 text-[15px] gap-x-[30px] font-helvetica flex justify-start items-center mb-1 cursor-pointer">
                            Logout
                            <CiLogin fontSize={21} className="ml-2" />
                          </p>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                          <AlertDialogHeader>
                            <AlertDialogTitle>
                              Are you absolutely sure?
                            </AlertDialogTitle>
                            <AlertDialogDescription>
                              This action will log you out from your account.
                            </AlertDialogDescription>
                          </AlertDialogHeader>
                          <AlertDialogFooter>
                            <AlertDialogCancel>Cancel</AlertDialogCancel>
                            <AlertDialogAction
                              className="bg-white border border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                              onClick={logout}
                            >
                              Logout
                            </AlertDialogAction>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      </AlertDialog>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div
                className={`flex justify-center items-center gap-x-2 ${location.pathname === "/register" ||
                  location.pathname === "/login"
                  ? "hidden"
                  : ""
                  }`}
                 >
                {/* <div className="flex items-center justify-center mr-4">
                  <CiShoppingCart className="text-black text-[20px] mr-1" />
                  <Link
                    to={`${token ? "/cart" : "/login"}`}
                    className="text-black"
                  >
                    Cart
                  </Link>
                </div> */}
                <div className="flex items-center justify-center right-0 mt-3">
                 
                  <Link to="/login" className="flex items-center text-[21px] text-[#414141] gap-2">
                  <RiAccountCircleLine/>
                  Account
                    {/* <Link to="/login">Login</Link> */}
                  </Link>
                </div>
                <div className="text-black">
                  <SignupModel />
                </div>
              </div>
            )}
          </div>
        </div>
        {location.pathname !== "/login" && location.pathname !== "/register" && (
<div className="relative sm:hidden px-[1rem] pb-3 flex items-center justify-center cursor-pointer">
      <CiSearch className="absolute top-[12px] sm:top-[27px] left-[43px] sm:mt-1 text-black text-lg z-20" />
      <input
        ref={inputRef}
        value={product}
        onChange={(e) => setProduct(e.target.value)}
        placeholder={product || touchedSearchbar ? "" : "Search For Products"}
        className="border border-[#F3CDA5] w-full shadow-md items-center text-black px-3 py-2 sm:py-3 placeholder-gray-500 rounded text-[16px] sm:text-[19px]"
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{ paddingLeft: "3rem" }}
      />

      <div
        className={`absolute top-[41px] z-40 bg-white cursor-pointer ${!product ? "hidden" : "block"}`}
        style={{ width: dropdownWidth }}
      >
        {searchProducts?.map((ele, index) => (
          <div key={index}>
            <button
              onClick={() => {
                navigateToSingleProductPage(ele?.productId, ele?.subCategoryId);
                setIsDropdownOpen(false);
              }}
              className="flex w-full justify-between items-center px-3 gap-3"
            >
              <div className="w-[60%]">
                <p className="text-black flex items-center justify-start">
                  {ele.productName.length > 24
                    ? ele.productName.substring(0, 24) + "..."
                    : ele.productName}
                </p>
              </div>
              <div className="w-[20%] h-[3rem] bg-[white] flex justify-center items-center">
                <img
                  src={ele?.productImage[0]?.imagePath}
                  className="w-[3rem] h-[3rem] bg-[white]"
                />
              </div>
            </button>
            <hr className="bg-[black]" />
          </div>
        ))}
      </div>
    </div>
)}
      </div>
    
      <div
        className="h-20 text-white hidden  sm:hidden md:hidden lg:hidden xl:flex justify-between px-[5rem] py-3 items-center"
        onMouseLeave={handleMouseLeave}
     
      >
      <Link to="/findStore" className="text-yellow-950 cursor-pointer text-base font-helvetica ">
      Find Our Nearest <span className="underline">Store</span>
      </Link>
      { location.pathname === "/login" || location.pathname === "/register" ?
          
            <div onClick={() => {handleNavigation("/")}} className="mr-10">
          <img src={logo01} alt="logo" className="h-16 mr-[96px]" />
          </div>:""


     
        }
        {location.pathname != "/login" && location.pathname != "/register" && 


        <div onClick={() => {handleNavigation("/")}} className="hidden sm:flex ml-[50px]">
          <img src={logo01} alt="logo" className="h-16 ml-[91px]" />


        
        </div>
          }
        

        {/* <CiSearch
      className="absolute top-6 left-[59rem] text-black text-3xl"
      onClick={handleSearchView} 
      
    />    */}
    
    {/* {token &&

   
    <div  onClick={handleSearchView} >

    <img src={SearchIcon} className="w-[1.5rem] h-[1.5rem] absolute cursor-pointer top-[27px] left-[70.5%] text-black text-3xl"/>
    </div>
    } */}
    {isSearchVisible && (
          <div className="fixed inset-0 h-36 z-50 flex
           items-start justify-center bg-[#FFF5EC]">

            <button
              onClick={handleSearchClose}
              className="absolute top-5 mt-[25px] right-[12%] text-[#916869] text-2xl z-50"
            >
              &#x2715;
            </button>

            <div className="relative w-80 mt-7 sm:w-auto max-w-lg p-4">
              <input
                value={product}
                onChange={(e) => setProduct(e.target.value)}
                placeholder={
                  product || touchedSearchbar ? "" : "Search For Products"
                }
                className="border border-transparent outline-none bg-[#E9E4D8] font-helvetica w-[36rem] text-black px-3 py-2 rounded focus:ring-2 focus:ring-[#F4B574] focus:border-none"
                onFocus={handleFocus}
                onBlur={handleBlur}
                ref={wrapperRef}
                autoFocus
              />


              <CiSearch className="absolute top-1/2 transform -translate-y-1/2 left-[35.2rem] text-[#916869] text-2xl z-20" />

              <div
                className={`absolute top-[55px] w-[570px] bg-white shadow-lg rounded-b-lg rounded-t-none  mt-1 ${!product ? "hidden" : "block"}`}
              >
                {searchProducts?.map((ele, index) => (
                 <>
                    <div
                      onClick={() => {
                        navigateToSingleProductPage(ele.productId, ele?.subCategoryId);
                        setIsSearchVisible(false);
                      }}
                      className="flex w-full top-8 justify-between items-center px-3 gap-3 py-6"
                    >
                      <div className="w-[70%]">
                        <p className="text-black flex items-center justify-start">
                          {ele.productName.length > 24
                            ? ele.productName.substring(0, 24) + "..."
                            : ele.productName}
                        </p>
                      </div>
                      <div className="w-[20%] h-[3rem] flex justify-center items-center">
                        <img
                          src={ele?.productImage[0]?.imagePath}
                          className="w-[3rem] h-[3rem] rounded-md"
                        />
                      </div>
                    </div>
                    <hr className="bg-black" />
                  
              </>
                ))}
              </div>
            </div>


          </div>
        )}
    
         {/* <div className="relative hidden sm:flex flex items-center justify-center cursor-pointer">
          <input
            value={product}
            onChange={(e) => setProduct(e.target.value)}
            placeholder={
              product || touchedSearchbar ? "" : "Search For Products"
            }
            className="border border-[#F3CDA5] w-[401px]  items-center text-black px-3 py-2 placeholder-gray-500 rounded"
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={wrapperRef}
          />
          <CiSearch className="absolute top-3 right-3 text-black text-xl z-20" />
          <div
            className={`absolute top-[41px]  z-40 w-[399px] h-fit bg-white cursor-pointer ${!product ? "hidden" : "block"
              }`}
          >
            {searchProducts?.map((ele, index) => (
              <div key={index}>
                <button
                  onClick={() => {
                    navigateToSingleProductPage(ele.productId, ele?.subCategoryId);
                    setIsDropdownOpen(false);
                  }}
                  className="flex w-full justify-between items-center px-3 gap-3"
                >
                  <div className="w-[60%]">
                    <p className="text-black flex items-center justify-start">
                      {ele.productName.length > 24
                        ? ele.productName.substring(0, 24) + "..."
                        : ele.productName}
                    </p>
                  </div>
                  <div className="w-[20%] h-[3rem] bg-[white] flex justify-center items-center">
                    <img
                      src={ele?.productImage[0]?.imagePath}
                      className="w-[3rem] h-[3rem] bg-[white]"
                    />
                  </div>
                </button>
                <hr className="bg-[black]" />
              </div>
            ))}
          </div>
        </div> */}
        <div className="sm:flex hidden gap-5 items-center justify-center"  >
          {token ? (
            <>
             
    <div  onClick={handleSearchView} >

    <img src={SearchIcon} className="w-[1.5rem] h-[1.5rem] cursor-pointer top-[27px] left-[70.5%] text-black text-3xl"/>
    </div>
              <div className="relative cursor-pointer">
                <Link
                  to="/wishlist"
                  className="text-black flex justify-center items-center gap-2"
                >
                  {/* <IoMdHeartEmpty
                    size={27}
                    className="text-black text-[30px]"
                  /> */}
                  <img src={heartIcon} className="w-[1.4rem] h-[1.4rem] ml-1"
                  />
                  {wishlistCount !== 0 && (
                    <span className="absolute bg-yellow-950 w-4 h-4 flex items-center justify-center text-[10px] rounded-full left-4 top-3 text-white">
                      {wishlistCount}
                    </span>
                  )}
                  Wishlist
                </Link>
              </div>
              <Link to="/cart">
                <div className="flex relative justify-center items-center gap-2">
                  {/* <BsCart2 size={27} className="text-black text-[30px]" /> */}
                  {cartCount == 0 && (

                  <img src={CartIcon} className="w-[1.4rem] h-[1.4rem]"/>)
                  }

                  {cartCount !== 0 && (
                    <div className="relative" >
                      
                    <img src={CartIconItem} className="w-[1.4rem] h-[1.4rem]"/>
                    <span className="absolute bg-yellow-950 w-4 h-4 flex items-center justify-center text-[10px] rounded-full left-4 top-3 text-white">
                      {cartCount}
                    </span>
                    </div>
                  )}
                  <p className="text-black">Cart</p>
                </div>
              </Link>
            
                <div
                  className="flex justify-center items-center gap-2"
                  onMouseEnter={handleMouseEnter}
                 
                >
                  {/* <HiOutlineUserCircle
                    size={30}
                    className="text-black text-[30px]"
                  /> */}
                  <img src={accountIcon} className="w-[1.4rem] h-[1.4rem] mt-1"/>
                  <p className="text-black capitalize flex items-center justify-center gap-1">
                    {user?.firstName}{" "}
                    <p>
                      <MdKeyboardArrowDown />{" "}
                    </p>{" "}
                  </p>
                </div>
            
              {showPopup && (
                <div
                  className="absolute top-[81px] right-[33px] rounded shadow-lg bg-white  w-fit  h-fit z-50 cursor-pointer"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleProfileMouseLeave}
                >
                  {/* <h1 className="text-black font-semibold px-6 mt-3">
                    {userData?.firstName} {userData?.lastName}
                  </h1>
                  <p className="text-[#414141] font-semibold mb-3 px-6">
                    {userData?.mobileNumber}
                  </p> */}
                  {/* <hr color="black" /> */}
                  {/* <Link to={"/my-account"}>
                    <p className="text-black px-3 py-3 text-[15px] font-helvetica flex justify-center gap-6 items-center mb-1">
                      Account <MdOutlineAccountCircle fontSize={30} />
                    </p>
                  </Link> */}
                  {/* <hr color="black" /> */}
                  <div className="hover:bg-[#f5f7fa]">
                    <Link
                      to={`/accountDetails/1`}
                      onClick={()=>setShowPopup(false)}
                      className="text-black px-6 py-3 text-[15px] font-helvetica flex justify-start gap-x-4 items-center mb-1"
                    >
                      <p>My Profile</p>
                      <img src={accountIcon} className="w-[1.4rem] h-[1.4rem] mt-1"/>

                    </Link>
                    {/* <hr color="black" /> */}
                  </div>
                  <div className="hover:bg-[#f5f7fa]">
                    <Link
                      to={`accountDetails/2`}
                      onClick={()=>setShowPopup(false)}
                      className="text-black px-6 py-3 text-[15px] font-helvetica gap-x-5 flex justify-start items-center mb-1"
                    >
                      <p>Address</p>
                      <SlLocationPin fontSize={21} className="ml-2" />
                    </Link>
                    {/* <hr color="black" /> */}
                  </div>
                  <div className="hover:bg-[#f5f7fa]">
                    <Link
                      to={`/accountDetails/3`}
                      onClick={()=>setShowPopup(false)}
                      className="text-black px-6 py-3 text-[15px] font-helvetica flex justify-start gap-x-4 items-center mb-1"
                    >
                      <p>My Orders</p>
                      <BsCart2 fontSize={21} className="" />
                    </Link>
                    {/* <hr color="black" /> */}
                  </div>

                

               

                  <div className="hover:bg-[#f5f7fa]">
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <p className="text-black px-6 py-3 text-[15px] gap-x-[22px] font-helvetica flex justify-start items-center mb-1 cursor-pointer">
                          Logout
                          <CiLogin fontSize={30} className="ml-2" />
                        </p>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>
                            Are you absolutely sure?
                          </AlertDialogTitle>
                          <AlertDialogDescription>
                            This action will log you out from your account.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction
                            className="bg-white border border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                            onClick={logout}
                          >
                            Logout
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div
              className={`flex justify-center items-center  gap-x-2 ${location.pathname === "/register" ||
                location.pathname === "/login"
                ? "hidden"
                : ""
                }`}
            >
            <div className="pr-[10rem]"></div>
              {/* <div className="flex items-center justify-center mr-4">
                <CiShoppingCart className="text-black text-[20px] mr-1" />
                <Link
                  to={`${token ? "/cart" : "/login"}`}
                  className="text-black"
                >
                  Cart
                </Link>
              </div> */}
              <div className="flex items-center font-helvetica text-black  gap-3 text-[16px]  right-0">
                {/* <AiOutlineLogin className="text-black text-[20px] mr-1" /> */}
                {/* <button className="px-6 py-2 bg-[#6C3516] rounded-lg text-white "> */}
                <div  onClick={handleSearchView} >

<img src={SearchIcon} className="w-[1.5rem] cursor-pointer h-[1.5rem]  text-black text-3xl"/>
</div>

                  <Link to="/login">
<div className="flex gap-3 items-center">


                     <img src={accountIcon} className="h-[1.4rem] w-[1.4rem]"/>
                  {/* <MdOutlineAccountCircle color="black" className="w-[1.5rem] h-[1.5rem]  text-[#6C3516] "/> */}
                     Account
                     </div>

                  </Link>
                {/* </button> */}
              </div>
              <div className="text-black">
                <SignupModel />
              </div>
            </div>
          )}
        </div>
      </div>
      <ul
        className={`${active === true ? "shadow-sm fixed max-w-[1920px]  top-0 left-0 z-30" : null
          } hidden sm:hidden md:hidden lg:hidden xl:flex justify-between items-center transition h-[50px]  w-full   bg-[#FBD3A4] text-black xl:gap-[30px] 2xl:gap-[40px] px-[5rem] py-3 text-[14px] `}
        onMouseLeave={() => setHoveredItem(null)}
      >

       
          {navItems.map((item, index) => (
            <li
              key={index}
              className={`relative    cursor-pointer active ${item.className} `}
              onMouseEnter={() => setHoveredItem(index)}
              onMouseLeave={handleMouseLeave1}

            >
              <Link
                className={`flex justify-center font-medium text-[14px] items-center  hover:text-[#6c3516]  ${index === activeItem ? "font-medium " : ""
                  }
            `}
                to={item.path}
              >
              
                {item.icon != null ?

                 (<div className={`flex items-center  gap-3 justify-center ${item.title === 'HOME' ? 'mb-1 font-medium' : ''}`}>
                 <div>  {item.title} </div>
                 <div> {hoveredItem === index ? <MdKeyboardArrowUp /> : item.icon}</div>
                </div>):(
                  <p className="">{item.title}</p>
                )

                }


              </Link>
              {index === 4 && hoveredItem === 4 && (
                <div
                  className="absolute top-[28px] z-30 left-[0px] mt-2 w-[210px] bg-white border border-gray-200 shadow-lg"
                // onMouseLeave={() => setHoveredItem(null)}
                // onMouseEnter={() => setHoveredItem(index)}
                >
                  <ul className="flex flex-col">
                    {Object.keys(accessories[151]).map((category, index) => (
                      <li
                        key={index}
                        className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica  text-[#414141] cursor-pointer"
                        onMouseEnter={() => handleMouseEnter1(index,category)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <p className="flex items-center justify-between">
                          {/* {" "} */}
                          <span>{category}</span> <MdOutlineChevronRight className="ml-2" />
                        </p>
                      </li>
                    ))}
                     {/* <li className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica  text-[#414141] cursor-pointer"
                         onClick={() => handleSubCategory(14)}
                        >Women's Accessories</li> */}
                  </ul>

                  {showDropdown && (
                    <div
                      className="absolute  w-[160px] mt-2  bg-white border border-gray-200 shadow-lg"
                      onMouseEnter={() => setShowDropdown(true)}
                      onMouseLeave={handleMouseLeave1}
                      style={{
            top: `${dropdownPosition.top}px`,
            left: `${dropdownPosition.left}px`,
          }}
                    >

                      <ul className="flex flex-wrap flex-col">

                        {Object.keys(accessories[151]).map(
                          (category, index) =>
                            index === hoveredCategory &&
                            accessories[151][category].map(
                              (subcategory, subIndex) => (
                                <li
                                  key={subIndex}
                                  onClick={() =>
                                    handleSubCategoryProducts(subcategory.id, subcategory.sub)
                                  }
                                  className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]  cursor-pointer"
                                >
                                  {subcategory.name}
                                </li>
                              )
                            )
                        )}
                       
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {index === 2 && hoveredItem === 2 && (
                <div
                  className="absolute top-[28px] z-30 left-[0px] mt-2 w-[200px] bg-white border border-gray-200 shadow-lg"
                  onMouseLeave={() => setHoveredItem(null)}
                  onMouseEnter={() => setHoveredItem(index)}
                >
                  <ul className="flex flex-col">
                    {Object.keys(leatherFootware[152]).map((category, index) => (
                      <li
                        key={index}
                        className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141] cursor-pointer"
                        onMouseEnter={() => handleMouseEnter1(index,category)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <p className="flex items-center justify-between">
                          {" "}
                          <span>{category} </span> <MdOutlineChevronRight className="ml-2" />
                        </p>
                      </li>
                    ))}
                  </ul>

                  {showDropdown && (
                    <div
                      className="absolute  mt-2 w-[160px] bg-white border border-gray-200 shadow-lg"
                      onMouseEnter={() => setShowDropdown(true)}
                      onMouseLeave={handleMouseLeave1}
                      style={{
            top: `${dropdownPosition.top}px`,
            left: `${dropdownPosition.left}px`,
          }}
                    >
                      <ul className="flex flex-wrap flex-col">
                        {/* <li
                         onClick={() =>
                                    handleSubCategoryProducts(4)
                                  } 
                                  className="px-4 py-2 flex gap-3 hover:bg-[#FFF5EC] font-helvetica text-[#414141]  cursor-pointer">View All</li> */}
                        {Object.keys(leatherFootware[152]).map(
                          (category, index) =>
                            index === hoveredCategory &&
                            leatherFootware[152][category].map(
                              (subcategory, subIndex) => (
                                <li
                                  key={subIndex}
                                  onClick={() =>
                                    handleSubCategoryProducts(subcategory.id, subcategory.sub)
                                  }
                                  className="px-4 py-2 flex gap-3 hover:bg-[#FFF5EC] font-helvetica text-[#414141]  cursor-pointer"
                                >
                                  {subcategory.name}
                                </li>
                              )
                            )
                        )}
                      </ul>
                    </div>
                  )}
                </div>

              )}
{/*              
              {index === 8 && hoveredItem === 8 && (
                <div
                  className="absolute top-[28px] z-30 left-[0px] mt-2 w-[180px] bg-white border border-gray-200 shadow-lg"
                  onMouseLeave={() => setHoveredItem(null)}
                  onMouseEnter={() => setHoveredItem(index)}
                >
                  <ul className="flex flex-col">
                    {Object.keys(leatherGoods[150]).map((category, index) => (
                      <li
                        key={index}
                        className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141] cursor-pointer"
                        onMouseEnter={() => handleMouseEnter1(index,category)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <p className="flex items-center justify-between">
                          {" "}
                          <span>{category} </span><MdOutlineChevronRight className="ml-2" />
                        </p>
                      </li>
                    ))}
                  </ul>

                  {showDropdown && (
                    <div
                      className="absolute mt-2 w-[160px] bg-white border border-gray-200 shadow-lg"
                      onMouseEnter={() => setShowDropdown(true)}
                      onMouseLeave={handleMouseLeave1}
                      style={{
            top: `${dropdownPosition.top}px`,
            left: `${dropdownPosition.left}px`,
          }}
                    >
                      <ul className="flex  flex-col">
                        {Object.keys(leatherGoods[150]).map(
                          (category, index) =>
                            index === hoveredCategory &&
                            leatherGoods[150][category].map(
                              (subcategory, subIndex) => (
                                <li
                                  key={subIndex}
                                  onClick={() =>
                                    handleSubCategoryProducts(subcategory.id)
                                  }
                                  className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141] cursor-pointer"
                                >
                                  {subcategory.name}
                                </li>
                              )
                            )
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              )} */}
           
              {index === 0 && hoveredItem === 0 && (
                <div
                  className="absolute top-[25px] z-30 left-[0px] mt-2 w-[140px] bg-white border border-gray-200 shadow-lg"
                  onMouseLeave={() => setHoveredItem(null)}
                  onMouseEnter={() => setHoveredItem(index)}
                >
                  <ul className="flex flex-col">
                    {Object.keys(HOME[154]).map((category, categoryIndex) => (
                      <li
                        key={categoryIndex}
                        className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141] cursor-pointer"
                        onMouseEnter={() => handleMouseEnter1(categoryIndex)}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => handleCategoryClick(category)}
                      >
                        <p className="flex items-start">
                          {category} 
                        </p>
                      </li>
                    ))}
                  </ul>

                  {showDropdown && (
                    <div
                      className="absolute top-[-9px] right-[-162px] mt-2 w-[130px] bg-white border border-gray-200 shadow-lg"
                      onMouseEnter={() => setShowDropdown(true)}
                      onMouseLeave={handleMouseLeave1}
                    >
                      <ul className="flex flex-col">
                        {Object.entries(HOME[154]).map(([category, subcategories], categoryIndex) =>
                          categoryIndex === hoveredCategory &&
                          subcategories.map((subcategory, subIndex) => (
                            <li
                              key={subIndex}
                              onClick={() => handleSubCategoryProducts(subcategory.id)}
                              className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141] cursor-pointer"
                            >
                              {subcategory.name}
                            </li>
                          ))
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              )}



              {index === 6 && hoveredItem === 6 && (
                <div
                  className="absolute top-[28px] z-30 left-[0px] mt-2 w-48 shadow-lg"
                // onMouseLeave={() => setHoveredItem(null)}
                // onMouseEnter={() => setHoveredItem(index)}
                >
                  {/* <ul>
                  <li
                    onClick={() => handleProducts(3)}
                    className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                  >
                    Foot Care
                  </li>

                  <li
                    onClick={() => handleSubCategoryProducts(27)}
                    className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                  >
                    Musical Instruments
                  </li>

                  <li
                    onClick={() => handleSubCategoryProducts(28)}
                    className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                  >
                    Furnitures
                  </li>

                  <li
                    onClick={() => handleSubCategoryProducts(30)}
                    className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                  >
                    Trolly & Briefcase
                  </li>
                </ul> */}
                </div>
              )}
              {index === 5 && hoveredItem === 5 && (
                <div
                  className="absolute top-[28px] z-30 left-[0px] w-[229px] mt-2 w-48 bg-white border border-gray-200 shadow-lg"
                  onMouseLeave={() => setHoveredItem(null)}
                  onMouseEnter={() => setHoveredItem(index)}
                >
                  <ul>
                  <li
                      onClick={() => {handleCategory(4);
                        toggleDrawer()

                      }}
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      View All
                    </li>
                    <li
                      onClick={() => handleSubSubCategory(26)}
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Shantiniketan Bags
                    </li>

                 {/*   <li
                      onClick={() => handleSubCategoryProducts(29)}
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Pupptes-wall Hanging/Lightings
                    </li>
*/}
                    <li
                      onClick={() => handleSubSubCategory(25)}
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Kolhapuri Chappals (GI tag)
                    </li>
                  </ul>
                </div>
              )}
              {index === 1 && hoveredItem === 1 && (
                <div
                  className="absolute top-[28px] h-[330px] z-30 left-[-230px] mt-2 w-[1400px] 2xl:w-[1900px] bg-white border border-gray-200 shadow-lg"
                  onMouseLeave={() => setHoveredItem(null)}
                  onMouseEnter={() => setHoveredItem(index)}
                >
                <div className="flex gap-[1px] font-helvetica ">
                <div className="px-[52px] py-3 ml-[50px] w-[300px]">

                 <h1 className="text-[14px] font-helvetica font-medium px-3 ml-1 text-[#7D502D] ">Categories</h1>

                  <ul className="mt-4">

                    <li
                      onClick={() => handleAllCategorie()}
                      className="px-4 text-[12px] py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                     All
                    </li>
                    <li
                      onClick={() => handleProducts(1)}
                      className="px-4 text-[12px] py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                       Footwear
                    </li>
                    <li
                      onClick={() => handleProducts(2)}
                      className=" text-[12px] px-4 py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                       Bags
                    </li>
                    <li
                      onClick={() => handleProducts(7)}
                      className="text-[12px] px-4 py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                       Jackets
                    </li>
                     

                        <li
                      onClick={() => handleProducts(4)}
                      className="text-[12px] px-4 py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Unique Collections
                    </li>
                    <li
                      onClick={() => handleProducts(3)}
                      className="text-[12px] px-4 py-[5px]  hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Accessories
                    </li>
                     <li
                        //  onClick={() => handleProducts(8)}
                      className="text-[12px]  px-4  py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Musical Instruments
                    </li>
                                      
                   <li
                        //  onClick={() => handleProducts(9)}

  to="/customized"
  className="text-[12px] px-4 py-[5px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
>
  Furniture
</li>


                   
                                     </ul>
                                     </div>
                                     <div className="py-3 mr-5">

<h1 className="text-[14px] font-helvetica font-medium text-[#7D502D] sm:mr-[105px]">Gender</h1>

 <ul className="mt-3">

   <li
     onClick={() => handleAllMenProduct()}
     className=" py-[5px] text-[12px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
   >
   Men
   </li>
   <li
     onClick={() => handleAllWomenProduct()}
     className=" py-[5px] text-[12px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
   >
      Women
   </li>
   <li
     onClick={() => handleAllUnisexProduct()}
     className=" py-[5px] text-[12px] hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
   >
   Unisex
   </li>
 
                    </ul>
                    </div>
                     <div className="px-[5rem] py-[1rem] ml-[137px]">

                    <div className="flex gap-12" >
                    <div  className="flex flex-col">

                      <img src={Topbanner01}  className="w-[19rem] h-[16rem]"/>
                      <p className="px-4 py-2 self-center hover:bg-[#FFF5EC] font-helvetica text-[#414141]">New Arrivals</p>
                    </div>
                    <div className="flex flex-col">

                    <img src={ Topbanner02} onClick={()=>handleClutchesAndWallets()} className="w-[19rem] h-[16rem]"/>
                    <p className="px-4 py-2  self-center hover:bg-[#FFF5EC] font-helvetica text-[#414141]">Clutches and Wallets</p>
                    </div>

                    </div>

                     </div>
                   

                </div>
                </div>
              )}
              {/* {index === 3 && hoveredItem === 3 && (
                <div
                  className="absolute top-[28px] z-30 left-[0px] mt-2 w-48 bg-white border border-gray-200 shadow-lg"
                  onMouseLeave={() => setHoveredItem(null)}
                  onMouseEnter={() => setHoveredItem(index)}
                >
                  <ul>
                    <li
                      onClick={() => handleSubCategory(5)}
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Men's Bag
                    </li>
                    <li
                      onClick={() => handleSubCategory(6)}
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Women's Bag
                    </li>
                  </ul>
                </div>
              )} */}
                {index === 3 && hoveredItem === 3 && (
                <div
                  className="absolute top-[28px] z-30 left-[0px] mt-2 w-[150px] bg-white border border-gray-200 shadow-lg"
                  onMouseLeave={() => setHoveredItem(null)}
                  onMouseEnter={() => setHoveredItem(index)}
                >
                  <ul className="flex flex-col">
                    {Object.keys(leatherBags[155]).map((category, index) => (
                      <li
                        key={index}
                        className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141] cursor-pointer"
                        onMouseEnter={() => handleMouseEnter1(index,category)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <p className="flex items-center justify-between">
                          {" "}
                          <span>{category} </span> <MdOutlineChevronRight className="ml-2" />
                        </p>
                      </li>
                    ))}
                     <p className="flex items-center justify-between px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141] cursor-pointer" 
                      onClick={() => handleSubCategory(7)}
                     
                     >
                          {" "}
                          <span >Laptop Bags</span> 
                        </p>
                     <p className="flex items-center justify-between px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141] cursor-pointer"
                      onClick={() => handleSubCategory(8)}
                     
                     >
                          {" "}
                          <span>Travel Bags</span> 
                        </p>
                  </ul>

                  {showDropdown && (
                    <div
                      className="absolute  mt-2 w-[175px] bg-white border border-gray-200 shadow-lg"
                      onMouseEnter={() => setShowDropdown(true)}
                      onMouseLeave={handleMouseLeave1}
                      style={{
            top: `${dropdownPosition.top}px`,
            left: `${dropdownPosition.left}px`,
          }}
                    >
                      <ul className="flex flex-wrap flex-col">
                        {/* <li
                         onClick={() =>
                                    handleSubCategoryProducts(4)
                                  } 
                                  className="px-4 py-2 flex gap-3 hover:bg-[#FFF5EC] font-helvetica text-[#414141]  cursor-pointer">View All</li> */}
                        {Object.keys(leatherBags[155]).map(
                          (category, index) =>
                            index === hoveredCategory &&
                          leatherBags[155][category].map(
                              (subcategory, subIndex) => (
                                <li
                                  key={subIndex}
                                  onClick={() =>
                                    handleSubCategoryProducts(subcategory.id, subcategory.sub)
                                  }
                                  className="px-4 py-2 flex gap-3 hover:bg-[#FFF5EC] font-helvetica text-[#414141]  cursor-pointer"
                                >
                                  {subcategory.name}
                                </li>
                              )
                            )
                        )}
                      </ul>
                    </div>
                  )}
                </div>

              )}
             {/* {index === 6 && hoveredItem === 6 && (
                <div
                  className="absolute top-[28px] z-30 left-[0px] mt-2 w-48 bg-white border border-gray-200 shadow-lg"
                  onMouseLeave={() => setHoveredItem(null)}
                  onMouseEnter={() => setHoveredItem(index)}
                >
                  <ul>
                    <li
                      onClick={() => handleSubCategoryProducts(31)}
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Men
                    </li>
                    <li
                      onClick={() => handleSubCategoryProducts(31)}
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Women
                    </li>
                    <li
                      onClick={() => handleSubCategoryProducts(31)}
                      className="px-4 py-2 hover:bg-[#FFF5EC] font-helvetica text-[#414141]"
                    >
                      Kids
                    </li>
                  </ul>
                </div>
              )}
*/}            </li>
          ))}
      
      </ul>
       </div>
  );
};

export default Navbar;

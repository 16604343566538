import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import OrderTable from "./OrderTable";
import { TbSettingsSearch } from "react-icons/tb";

const OrderDetails = () => {
  const [search, setSearch] = useState("");

  return (
   <div className="h-[700px] py-3 w-full  p-3 sm:p-0 mt-2 ">
  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
    <p className="w-full px-6 font-bold">All orders</p>
    <div className="relative w-full sm:w-auto mr-7">
      <input
        type="text"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="border border-[#303030] p-1 rounded w-full sm:w-auto"
        placeholder="Search"
      />
      <CiSearch className="absolute top-[10px] right-3" />
    </div>
  </div>
    <div className="overflow-x-auto ">

    <OrderTable search={search} />
    </div>
</div>
  );
};

export default OrderDetails;

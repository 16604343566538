import React, { useEffect, useState } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { IoBag } from "react-icons/io5";
import { MdCurrencyRupee } from "react-icons/md";
import Rectangle from "../../../images/Rectangle.png";
import { Button } from "../../ui/button";
import { Card } from "../../ui/card";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import heartIcon from "../../../images/heart.png";
import  colorHeart from "../../../images/colorHeart.png"
import { useDispatch, useSelector } from "react-redux";
import {
  addProductToWishlist,
  getAllWishlistsByUser,
  removeSingleWishList,
} from "../../../services/operations/wishlistApi";
import {
  addItemToCart,
  decrementCartItem,
  getCartItemsByUserAndStatus,
  getCartItemsByUserAndStatusAndProducts,
  incrementCartItem,
} from "../../../services/operations/cartItemApi";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../ui/dialog";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../ui/alert-dialog";
import {
  setWishlisData,
  setWishlistCount,
} from "../../../redux/slices/wishlistSlice";
import { setCartData, setCartCount } from "../../../redux/slices/cartSlice";

const SingleProduct = ({ ele, buttonVisible, loading,onlyFilter }) => {
  console.log(ele,'oiu')
  const dispatch = useDispatch();
  const { wishlistData } = useSelector((state) => state.wishlist);
  const { cartData, cartCount } = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const [sizeId, setSizeId] = useState(null);
  const [noToken,setNoToken]=useState(false)
  const [colorId, setColorId] = useState(null);
  const [cartLoading, setCartLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [wishlistData1, setWishlistData1] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [toastCooldown, setToastCooldown] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setCartLoading(true);
        const response = await getCartItemsByUserAndStatus(token);
        setCartItems(response);
        dispatch(setCartData(response));
        dispatch(setCartCount(response?.length));
        setCartLoading(false);
      } catch (error) {
        console.log(error);
      }
      setCartLoading(false);
    };
    if (token) {
      fetchData();
    }
  }, [token]);

 

  const addToWislist = async (productId) => {
    try {
      if (!token) {
        toast.error("Please Login First");
        navigate("/login");
        window.scrollTo(0, 0);
        return;
      }
      const response = await addProductToWishlist(token, productId);
     
      const response1 = await getAllWishlistsByUser(token);
      dispatch(setWishlisData(response1));
      dispatch(setWishlistCount(response1?.length));
    } catch (error) {
      console.log(error);
    }

  };
  const handleCart=(productId, sizeId, colorId)=>{
  
    if(!token){
      setNoToken(true)
      
    localStorage.setItem('selectedSize', sizeId);
    localStorage.setItem('selectedProduct', productId);
    localStorage.setItem('selectedColor', colorId);
    document.cookie = "cart_visited=true; path=/";
   
    }else{
      addToCart(productId, sizeId, colorId)
    }
  
  }

  const handleRemoveSigleWishList = async (wishListId) => {
    try {
      await removeSingleWishList(token, wishListId);
      const response = await getAllWishlistsByUser(token);
      setWishlistData1(response);
      const response1 = await getAllWishlistsByUser(token);
      dispatch(setWishlisData(response1));
      dispatch(setWishlistCount(response1?.length));
    } catch (error) {
      console.log(error);
    }
  };

  const addToCart = async (productId, sizeId, colorId) => {
    if (!sizeId || !colorId) {
      toast.error("Please Select Size and color");
      return;
    }
    if (!sizeId) {
      toast.error("Please Select Size");
      return;
    }
    if (!colorId) {
      toast.error("Please Select Color");
      return;
    }
    try {
      setCartLoading(true);
      const response = await addItemToCart(productId, sizeId, colorId, token);
      const response1 = await getCartItemsByUserAndStatusAndProducts(token);
    
      dispatch(setCartData(response1));
      dispatch(setCartCount(response1?.length));
      setCartItems((prevItems) => {
        if (!Array.isArray(prevItems)) {
          return [response];
        }
        const existingItem = prevItems.find(
          (item) => item?.productId === productId
        );
        if (existingItem) {
          return prevItems.map((item) =>
            item?.productId === productId ? response : item
          );
        } else {
          return [...prevItems, response];
        }
      });
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  const increaseQuantity = async (cartItemId) => {
    try {
      if (!toastCooldown) {
        setCartLoading(true);
        const response = await incrementCartItem(cartItemId, token);
        setCartItems((prevItems) =>
          prevItems.map((item) =>
            item.cartItemId === cartItemId ? response : item
          )
        );
        setToastCooldown(true);
        setTimeout(() => {
          setToastCooldown(false);
        }, 2200);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };
  

  useEffect(() => {
    let interval;
    if (isHovered && ele.productImage.length > 1) {
      interval = setInterval(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === ele.productImage.length - 1 ? 0 : prevIndex + 1
        );
      }, 700); 
    }
    return () => {
      clearInterval(interval);
    };
  }, [isHovered, ele?.productImage?.length]);
  const decreaseQuantity = async (cartItemId) => {
    try {
      if (!toastCooldown) {
        setCartLoading(true);
        const response = await decrementCartItem(cartItemId, token);
        if (response.quantity === 1) {
          window.location.reload();
        } else {
          setCartItems((prevItems) =>
            prevItems.map((item) =>
              item.cartItemId === cartItemId ? response : item
            )
          );
        }
        setToastCooldown(true);
        setTimeout(() => {
          setToastCooldown(false);
        }, 2200);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  const fetchCartItemsData = async () => {
    try {
      setCartLoading(true);
      const response = await getCartItemsByUserAndStatus(token);
      setCartItems(response);
      dispatch(setCartData(response));
      dispatch(setCartCount(response.length));
    } catch (error) {
      console.log(error);
    } finally {
      setCartLoading(false);
    }
  };

  useEffect(() => {
    fetchCartItemsData();
  }, [token, dispatch]);

  const navigateToSingleProductPage = (productId) => {
    // const productName = product.replace(/ /g, "-");
    navigate(`/shop/products/${productId}`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const getAllWishlists = async () => {
    try {
      const response = await getAllWishlistsByUser(token);
     
      setWishlistData1(response);
      dispatch(setWishlisData(response));
      dispatch(setWishlistCount(response?.length));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllWishlists();
  }, []);

  const isProductInWishlist = (productId) => {
    return wishlistData?.some((item) =>
      item.products?.some((product) => product?.productId === productId)
    );
  };

  const isDisabled = !sizeId || !colorId;

  return (
    
       <div
      className={`w-[300px] sm:w-[350px] px-3 sm:pl-5  h-[30rem] relative flex-wrap sm:flex-nowrap ${
        buttonVisible
          ? "sm:h-[24.5rem] "
          : "sm:h-[21rem] "
      } flex flex-col  relative mt-4 cursor-pointer  overflow-hidden`}
       >
       <div
      className="min-h-[18.5rem]  max-h-[26rem] overflow-hidden"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        setCurrentImageIndex(0); 
      }}
    >
      <img
        onClick={() => navigateToSingleProductPage(ele?.productId)}
        src={ele?.productImage[currentImageIndex]?.imagePath}
        alt="shoes"
        className="w-full h-full bg-[#ffffff] object-fit"
      />
    </div>
        <p className="py-2 font-Nunito font-light text-center text-[14px] ">
          {ele?.categoryDTO?.categoryName}
        </p>
        <h1 className="font-orator font-normal text-base text-center  mb-1 min-h-[62px] sm:min-h-[21px]">
          {ele?.productName.length > 30
            ? ele?.productName.substring(0, 30) + "..."
            : ele?.productName}
        </h1>
        <div className="flex items-center sm:mt-2 justify-center  ">
            <MdCurrencyRupee className="text-[#303030] text-base " />
            <strong className="text-[#303030] text-base  min-h-[18px] font-bold">
              {Math.round(ele?.sellingPrice)}
            </strong>
        </div>
      
     
        <div className="flex items-center justify-between sm-gap-1">
          {/* {buttonVisible && (
            <>
              <div className="flex items-center gap-x-3 mt-2 justify-between">
                {cartItems?.some(
                  (item) => item?.productId === ele?.productId
                ) ? (
                  <div className="flex items-center justify-between  cursor-pointer mt-2 w-[132px]  gap-3 bg-[#6C3516] text-white px-2  ">
                    <Button
                      onClick={() =>
                        decreaseQuantity(
                          cartItems?.find(
                            (item) => item?.productId === ele?.productId
                          ).cartItemId
                        )
                      }
                      className="bg-[#6C3516]"
                    >
                      -
                    </Button>
                    <span>
                      {cartItems?.find(
                        (item) => item?.productId === ele?.productId
                      ).quantity === 0
                        ? ""
                        : cartItems?.find(
                            (item) => item?.productId === ele?.productId
                          ).quantity}
                    </span>
                    <Button
                      onClick={() =>
                        increaseQuantity(
                          cartItems.find(
                            (item) => item?.productId === ele?.productId
                          ).cartItemId
                        )
                      }
                      className="bg-[#6C3516]"
                    >
                      +
                    </Button>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full cursor-pointer w-full gap-4  
                   text-white px-1 py-1  sm:px-2 sm:py-2 rounded">
                    <Dialog>
              
                        <DialogContent>
                          <div className="flex w-full items-center">
                            <div
                              className="w-[30%] 
                            "
                            >
                              <img
                                src={ele?.productImage[0]?.imagePath}
                                alt="categoryImage"
                                className="w-[85%] h-[80%] oabject-fit mx-auto  rounded-lg"
                              />
                            </div>
                            <div className="flex flex-col ml-10">
                              <p className="text-[#303030] font-Barlow mt-2 font-bold">
                                {ele?.productName}
                              </p>
                              <div className="flex gap-6 items-center mt-3 justify-start">
                                <p className="text-[#636363] flex items-center gap-1">
                                  <MdCurrencyRupee /> {Math.round(ele?.sellingPrice)}
                                </p>
                                {ele.discount != 0 && (
                                  <p className="flex justify-start gap-3">
                                    <span>MRP </span>
                                    <p className="line-through text-red-500">
                                      {ele?.mrpPrice}
                                    </p>
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="flex gap-3 items-center justify-start">
                            <h1>Select Sizes</h1>
                            {ele?.sizes?.map((size, index) => (
                              <div
                                onClick={() => setSizeId(size.sizeId)}
                                className={`w-10 h-10 flex rounded-full shadow-md cursor-pointer items-center justify-center ${
                                  sizeId === size.sizeId
                                    ? "border border-black"
                                    : ""
                                }`}
                                key={index}
                              >
                                <div>
                                  <button>{size.sizeNumber}</button>
                                </div>
                              </div>
                            ))}
                          </div>
                          <hr />
                          <div className="flex gap-3 items-center justify-start">
                            <h1>Select Colors</h1>
                            {ele?.colors?.map((color, index) => (
                              <div
                                onClick={() => setColorId(color.colorId)}
                                className={`w-10 h-10 flex rounded-full cursor-pointer shadow-md items-center justify-center ${
                                  colorId === color.colorId
                                    ? "border-2 border-green-800"
                                    : ""
                                }`}
                                style={{ backgroundColor: color?.colorName }}
                                key={index}
                              ></div>
                            ))}
                          </div>
                          <DialogFooter>
                            <button
                              className={`flex items-center justify-center px-2 py-2 rounded-md gap-x-2 ${
                                isDisabled
                                  ? "bg-gray-400 text-gray-700 cursor-not-allowed"
                                  : "bg-[#6C3516] hover:bg-[#4a250f] text-white"
                              }`}
                              onClick={() =>handleCart(ele?.productId,sizeId,colorId)
                               
                              }
                              disabled={!sizeId && !colorId}
                            >
                              <IoBag /> Add To Cart
                            </button>
                          </DialogFooter>
                        </DialogContent>
                        {noToken && (
                                <DialogContent className="sm:max-w-[425px]">
                                <DialogHeader>
                                  <DialogTitle className="text-[#000000] font-Nunito bold text-[24px]">
                                    Sign in/Sign up
                                  </DialogTitle>
                                  <DialogDescription className="text-[#EC0606] text-[20px] font-outFit">
                                    Sign in/Signup Required to Add Items to Your Cart
                                  </DialogDescription>
                                  <DialogDescription className="text-[#050505]  text-[15px] mb-4 font-outFit">
                                    No worries! It only takes a minute to sign in and
                                    unlock a world of convenient shopping.
                                  </DialogDescription>
                                </DialogHeader>
                                <DialogFooter className={"flex justify-start"}>
                                  <Link
                                    to={"/login"}
                                    className="bg-[#56243B] py-3 text-white rounded-lg px-7"
                                  >
                                    Go to Sign in
                                  </Link>
                                </DialogFooter>
                              </DialogContent>
      
                        )}
                 
                   
                   
                    </Dialog>
                  </div>
                )}
              </div>
              <div className="flex mt-2 justify-center items-center  rounded-full p-1 sm:p-2 mr-0">
                {isProductInWishlist(ele?.productId) ? (
                  <>
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        
                        <div className="absolute top-[10px] " >

                        <img src={colorHeart} className="w-[1rem] h-[1rem]" />
                        </div>
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>
                            Are you absolutely sure To Remove from Wislist?
                          </AlertDialogTitle>
                          <AlertDialogDescription>
                            This action cannot be undone. This will permanently
                            delete your account and remove your data from our
                            servers.
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>Cancel</AlertDialogCancel>
                          <AlertDialogAction
                            className="bg-red-500 hover:bg-red-800 text-white"
                            onClick={() =>
                              handleRemoveSigleWishList(
                                wishlistData.find((item) =>
                                  item.products.some(
                                    (product) =>
                                      product.productId === ele.productId
                                  )
                                ).wishListId
                              )
                            }
                          >
                            Remove
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </>
                ) : (
                  <div className="absolute top-[10px]"  onClick={() => addToWislist(ele?.productId)}>

                  <img src={heartIcon} className="w-[1rem] h-[1rem]"/>

                  </div>

                 
                )}
              </div>
            </>
          )} */}
          {ele.offers && (
            <div className="absolute top-0 left-0">
              <img src={Rectangle} alt="Rectangle" className="w-14" />
              <p className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-prompt text-white text-sm">
                20% OFF
              </p>
            </div>
          )}
        </div>
    </div>
  
  );
};

export default SingleProduct;
